import {RefType, ValidationResult, Props, Result, StateResultRef, Validation} from "./interfaces";
import React, {ForwardedRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import PostContext, {flowComponents, flowRefs} from "../../storage/PostContext";
import ValidationError from "../../components/UI/ValidationError/ValidationError";
import validationError from "../../components/UI/ValidationError/ValidationError";
import AutoSaveApi from "../../api/auto-save.api";
import DynamicObject from "../../models/dynamic-object";
import UIContext from "../../storage/UIContext";
import {useValidation} from "../index";
import useRelated from "../use-related/use-related";
import FlowReferences from "../../flow-references";
import NotAutoSaveState from "../../not-auto-save-state";
import useSubmitContext from "../../storage/SubmitContext";


interface RefDataType<StateType> extends RefType<StateType> {
}

const delayedStateAlreadyChanged: DynamicObject = {}
let clearing = false
const useDataPayload = <StateType>(props: Props<StateType, RefDataType<any>>): Result<StateType> => {
    const {
        config,
        ref,
        stateConfig,
        autoSaveDelay = false,
        value: currentValue = undefined,
        defaultValue = null
    } = props
    const postCtx = useContext(PostContext)

    const initializeState = <StateType>(identifier: string): StateType => {
        if (currentValue !== undefined)
            return currentValue


        return FlowReferences.get(identifier)?.state?.get()
    }
    const defaultState: StateType = initializeState(config.identifier)

    const [state, setState] = useState<StateType | undefined>(defaultState)
    const {ValidationMethods, error} = useValidation(flowRefs[config.identifier])
    const {reRenderComponentHandler} = useRelated({state: state, defaultState: defaultState, config})
    const {setIsSubmitUpdated, setSubmitObj} = useSubmitContext()

    const saveData = (data) => {
        return new Promise<any>((resolve, reject) => {
            if (!postCtx.data.step.autoSave || NotAutoSaveState.get(config.identifier)) {
                resolve(true)
                return
            }
            
            AutoSaveApi({
                type: postCtx.data.flow_type === "delete" ? "delete" : "add-post",
                source: FlowReferences.getFlow(),
                stepIdentifier: postCtx.data.step.identifier,
                draftId: window.sessionStorage.getItem('draftId'),
                workflow_id: postCtx.data.workflow_id,
                identifier: config.identifier,
                data
            }).then(response => {
                if(Object.keys(response?.data?.result?.data?.submit).includes("active")) {
                    setIsSubmitUpdated(true);
                    setSubmitObj(response?.data?.result?.data?.submit)
                }
                for (const id in response.data.result.data.reRender) {
                    const config = response.data.result.data.reRender[id]
                    reRenderComponentHandler(config.identifier, config)
                    resolve(response)
                }
            }).catch(error => {
                for (const key in error.response.data.result.errors) {
                    const errorObject = error.response.data.result.errors[key] as DynamicObject
                    const ref = FlowReferences.get(errorObject.field)
                    console.log(ref)
                    if (!ref?.validation?.set)
                        continue

                    if (key == "0") {
                        let element = document.getElementById(errorObject.field)
                        element?.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                    }
                    ref.validation.set(errorObject?.message)
                }
            })
        })


    }

    const setData = useCallback((data: StateType) => {
        setState(data)

        if (autoSaveDelay)
            delayedStateAlreadyChanged[config.identifier] = true


        if (autoSaveDelay === false)
            return saveData({
                [`${props.config.identifier}`]: data
            })

        return new Promise(resolve => {
            resolve(true)
        })

    }, [props.config])

    const setConfig = useCallback((config) => {
        props.setConfig(config)
    }, [props.setConfig])

    const setStateHandler = (data: StateType, callback = () => {
    }) => {
        setData(data).then((data) => {
            callback()
        })
    }

    useImperativeHandle<RefDataType<StateType>, RefDataType<StateType>>(ref, () => {
        return {
            state: {
                set: setStateHandler,
                get: (() => state),
            },
            validation: {...ValidationMethods},
            config: {
                set: setConfig,
                get: () => config
            }
        }
    })

    FlowReferences.load(config.identifier, {
        state: {
            set: (data: StateType, callback = () => {
            }) => {
                setData(data).then((data) => {
                    callback()
                })
            },
            get: (() => state),
            clear: () => setData(props.defaultValue)
        },
        validation: {...ValidationMethods},
        config: {
            set: props.setConfig,
            get: () => config
        }
    })


    const validation = {
        ...ValidationMethods,
        error,
    }

    React.createElement(ValidationError, {
        validation,
    })


    useEffect(() => {
        if (autoSaveDelay === false)
            return
        const delayFunction = setTimeout(() => {
            if (delayedStateAlreadyChanged[config.identifier] === true) {
                saveData({
                    [`${props.config.identifier}`]: state,
                })
                delayedStateAlreadyChanged[config.identifier] = false
            }

        }, autoSaveDelay || 0)

        return () => {
            clearTimeout(delayFunction)
        }
    }, [state])

    return {
        state: {
            set: setStateHandler,
            defaultState,
            value: state
        },
        validation: {
            ...validation
        }
    }
}

export {
    useDataPayload as default,
    type RefDataType
}