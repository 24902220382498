import React, { useState } from "react";
import { Props } from "./interface";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {
  RefDataType,
} from "../../../../hooks/use-data-payload/use-data-payload";
import { getImgPath } from "../../../../helpers/functions";
import { useValidation } from "../Section/context/ValidationContext";
import { getDataStorage } from "../../../../helpers/storage.helper";
const RateSelector = React.forwardRef<RefDataType<string>, Props>(
  (props, ref) => {
    const { state, validation } = useDataPayload<string>({
      ref,
      config: props.config,
    });
    const storage = getDataStorage();
    const {clearError,error:errorRef}=useValidation()

    if (errorRef.current === null) {
      errorRef.current = localStorage.getItem('error')||null;
      localStorage.removeItem('error');
  
  }
    const [rating, setRating] = useState<number>(
      state.value ? Number(state.value) : 0
    );
    const [hover, setHover] = useState<number | null>(null);

    const handleRate = (rate: number) => {
      state.set(rate);
      setRating(rate);
      validation.clear();
    };
    return (
      <>
        <div style={{ display: "flex", gap: storage.source==="mobile"?"16px":"6px", alignItems: "center" }}>
          {props.config.values.map((star, index) => {
            const ratingValue = index + 1;
            return (
              <label key={index} style={{ cursor: "pointer" }}>
                <input
                  type="radio"
                  name="rating"
                  value={ratingValue}
                  onClick={() => {handleRate(ratingValue);      clearError();
                  }}
                  style={{ display: "none" }}
                />
                <img
                  src={
                    ratingValue <= (hover ?? rating)
                      ? getImgPath(star.selected)
                      : getImgPath(star.unselected)
                  }
                  alt={`${
                    ratingValue <= (hover ?? rating) ? "Selected" : "Unselected"
                  } star`}
                  onMouseEnter={() => setHover(ratingValue)}
                  onMouseLeave={() => setHover(null)}
                  style={{ width: "32px", height: "32px" }}
                />
              </label>
            );
          })}
        </div>
      </>
    );
  }
);

export default RateSelector;
