import React, { useContext, useEffect, useState } from "react";
import { Props, RefDataType } from "./interfaces";
import styles from "./PaymentWidget.module.css";
import PackageTypes from "../PackageTypes/PackageTypes";
import { fetchFirstProduct, fetchPackageConfig } from "./functions";
import LanguageContext from "../../../../storage/LanguageContext";
import { useTranslation } from "react-i18next";
// import 'opensooq-services/src/assets/css/CreditPackageCol.css';
// import 'opensooq-services/src/assets/css/CustomButton.css';
// import 'opensooq-services/src/assets/css/DefaultPackageCol.css';
// import 'opensooq-services/src/assets/css/PackageBenefits.css';
// import 'opensooq-services/src/assets/css/PackageTypes.css';
// import 'opensooq-services/src/assets/css/PackageButton.css';
// import 'opensooq-services/src/assets/css/PackageGridItem.css';
// import 'opensooq-services/src/assets/css/PackageList.css';
// import 'opensooq-services/src/assets/css/PaymentWidget.css';
// import 'react-loading-skeleton/dist/skeleton.css';
// import 'opensooq-services/dist/esm/assets/css/Main.css'

import {PaymentWidget as PaymentWidgetBundle} from "opensooq-services";
import {
  generateAuthToken,
} from "../../../../helpers/auth.helper";
import PostContext from "../../../../storage/PostContext";
import { getDataStorage } from "../../../../helpers/storage.helper";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {checkOnBehalfLogInFlow, getUrlBasedOnCountry} from "../../../../helpers/functions";
import { ActionDimensions } from "../../../../logging/dimensions/ActionDimensions";
import { PointOfSales } from "../../../../helpers/PointOfSales";
// import "opensooq-services/dist/esm/Bootstrap.css";

const PaymentWidget = React.forwardRef<RefDataType, Props>((props, ref) => {
  const langCtx = useContext(LanguageContext);
  const packages = props.config?.data?.packages;
  const urlParams = new URLSearchParams(window.location.search);
  const pathname = window.location.pathname;
  const [pos, setPos] = useState<string>(undefined);

  let products = null;
  if (packages) {
    products = Object.keys(packages);
  }
  const { t } = useTranslation();
  const [token, setToken] = useState<string>("");
  const postCtx = useContext(PostContext);
  const storage = getDataStorage();
  
  const getToken = async () => {
    if (await checkOnBehalfLogInFlow()) {
        return Cookies.get('EmployeeToken');
    } else {
        return generateAuthToken()
    }
}
  const navigate = useNavigate();

  useEffect(() => {
    let flow = urlParams.get("flow");
    if (flow) {
      sessionStorage.setItem("flowType", flow);
    } else {
      flow = sessionStorage.getItem("flowType");
      sessionStorage.removeItem("flowType");
    }
    let pos : string = "";
    const isPending = pathname.includes("pending-payment");
    if (flow && flow === "edit") {
      pos = isPending
        ? PointOfSales.EDIT_LISTING_POS_PENDING
        : PointOfSales.EDIT_LISTING_POS_SUCCESS;
    } else if (flow && flow === "activate") {
      if (postCtx.data.isOverLimit)
        pos = PointOfSales.MYLISTINGVIEW_PENDINGPAYMENT_ACTIVATELISTING;
      else pos = PointOfSales.MYLISTINGVIEW_DELETED_LISTING_ACTIVATELISTING;
    } else {
      pos = isPending
        ? PointOfSales.ADD_LISTING_POS_PENDING
        : PointOfSales.ADD_LISTING_POS_SUCCESS;
    }

    setPos(pos);
    getToken().then((token) => {
      setToken(token ?? "");
      if (postCtx?.data?.post_id && postCtx?.data?.step?.deeplink)
        navigate(
          `?flow=promoteFlow&post_id=${postCtx?.data?.post_id}&pos=${pos}`,
          { replace: true }
        );
    });
  }, [postCtx?.data?.post_id]);

  return (
    token && (
      <div
        className={`w-100 ${
          !storage.isMobile && "d-flex flex-row justify-content-center"
        } `}
      >
        <div style={{width:"100%"}}><PaymentWidgetBundle
            service={props?.config?.is_combo && "MemberCombo"} isCombo={props?.config?.is_combo}
            referral_url={`${Cookies.get('addPostRef') ?? getUrlBasedOnCountry(storage.country)}`}
            products={products}
            domain={process.env.REACT_APP_DOMAIN_PAYMENT_API}
            api={process.env.REACT_APP_PAYMENT_API} token={token}
            source={storage.source} countryAbbreviation={storage.country}
            language={langCtx.language} modelId={postCtx.data.post_id}
            loggingConfig={{
                version: `${process.env.REACT_APP_PUBLIC_VERSION}`,
                loggingEnv:
                {   
                    url: process.env.REACT_APP_NEXT_PUBLIC_LOGGING_URL_CLIENT,
                    token: ''
                },
                sessionFunnelSource: ActionDimensions.getInstance().currScreen,
                sessionFunnelPointOfEntry: pos
            }}
            pos_source={pos ?? ''}
            />
        </div>

      </div>
    )
  );
});
export default PaymentWidget;
