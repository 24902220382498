import React, {useContext, useEffect, useImperativeHandle, useState} from "react";
import {Props, RefDataType} from './interfaces';
import styles from "./MediaSection.module.css";
import Media from "../../../UI/MediaUpload/Media";
import {useTranslation} from "react-i18next";
import PostContext from "../../../../storage/PostContext";
import UIContext from "../../../../storage/UIContext";
import Step from "../../Steps/Step";
import {FaRegCheckCircle} from "react-icons/fa";
import NextButton from "../../NextButton/NextButton";
import {StateDataType} from "../../../UI/MediaUpload/MediaUpload.interfaces";
import {getSizes} from "../../../../helpers/functions";
import DynamicObject from "../../../../models/dynamic-object";

const MediaSection = React.forwardRef<RefDataType, Props>((props, ref) => {
    const {config} = props


    /************************************
     *            CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)

    /************************************
     *            STATE
     * *********************************/
    const [mediaState, setMediaState] = useState<StateDataType|null>(null)
    const [error, setError] = useState('')
    const uiCtx = useContext(UIContext)


    /*-------------------------------------
     *            FUNCTIONS
     * ----------------------------------*/

    /************************************
     *         STATE HANDLER
     * *********************************/
    const onChangeMedia = (state: StateDataType) => {
        // setMediaState(state)
    }

    useImperativeHandle<any, any>(ref, () => {
        return {
            validation: {
                set: (err) => {
                    setError(err)
                }
            }
        }
    })


    /*---------------------------------------------------------------*/

    useEffect(function (){


        if (mediaState){
            const media = {...postCtx.data.media}
            media[config.identifier] = {
                ...mediaState
            }

            // // @ts-ignore
            // const data = props.loadData()
            // postCtx.updateData({
            //     media: media,
            //     form: {
            //         ...postCtx.data.form,
            //         ...data
            //     }
            // })
            // setMediaState(null)



            const errors = [
                ...uiCtx.errors.filter((error: DynamicObject) => error.field !== props.config.identifier)
            ]

            if (errors.length !== uiCtx.errors.length)
                uiCtx.setErrors(errors.filter((error: DynamicObject) => error.field !== props.config.identifier))

        }

    }, [mediaState])

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>{config.title?.locale}</h1>
                <ul>
                    <li><span style={{marginTop:"5px",fontWeight:config.subTitle?.style?.bold?"bold":"normal",fontSize:getSizes(config.subTitle?.style?.size),color:config.subTitle?.color}}>{config.subTitle?.locale}</span></li>
                </ul>
            </div>
            <div className={styles.content}>
                <Media identifier={config.identifier} onChangeMedia={onChangeMedia} error={error} config={config}/>
            </div>

        </div>
    )
})
export default MediaSection
