import { getDataStorage } from "../../helpers/storage.helper";
import { DEFAULT_VALUE } from "../types/LoggingTypes";

export class SessionDimensions {
    private static instance : SessionDimensions;
    
    private _sessionId : string = DEFAULT_VALUE;
    private _sessionSearchId: string = DEFAULT_VALUE;
    private _sessionLife : string = DEFAULT_VALUE;
    
    public static getInstance(){
        if(!SessionDimensions.instance)
        {
            SessionDimensions.instance = new SessionDimensions();
        }
        return SessionDimensions.instance;
    }

    get sessionId():string {
        return this._sessionId;
    }

    set sessionId(_sessionId:string)
    {
        this._sessionId = _sessionId;
    }

    get sessionSearchId():string {
        return this._sessionSearchId;
    }

    set sessionSearchId(_sessionSearchId:string)
    {
        this._sessionSearchId = _sessionSearchId;
    }
    
    get sessionLife():string {
        const cookies = getDataStorage();
        if(cookies.tgtTimestamp)
        {
            const sessionLifeTime = Math.floor( ( (Date.now()/1000) - (+cookies.tgtTimestamp) ) );
            if(sessionLifeTime < 0)
                return "1";
            return sessionLifeTime.toString();
        }
        return DEFAULT_VALUE;
    }
    
    get sessionMedium():string {
        const query = new URLSearchParams(window.location.search);
        const temp = query.get("utm_medium");
        if(query && temp !== null)
        {   
            return temp;
        }
        return DEFAULT_VALUE;  
    }
    
    get sessionSource():string {
        const query = new URLSearchParams(window.location.search);
        const temp = query.get("utm_source");
        if(query && temp !== null)
        {   
            return temp;
        }
        return DEFAULT_VALUE;      
    }
    
    get sessionCampaign():string {
        const query = new URLSearchParams(window.location.search);
        const temp = query.get("utm_campaign");
        if(query && temp !== null)
        {   
            return temp;
        }
        return DEFAULT_VALUE;      
    }
}