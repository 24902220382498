import { create } from "zustand";

type State = {
  isNafath: boolean;
  isNafathMobile: boolean;
  isNafathRandomStepMobile: boolean;
  isNafathRandomStepDesktop: boolean;
};

type Actions = {
  setIsNafath: (isNafath: boolean) => void;
  setIsNafathMobile: (isNafathMobile: boolean) => void;
  setIsNafathRandomStepMobile: (isNafathRandomStepMobile: boolean) => void;
  setIsNafathRandomStepDesktop: (isNafathRandomStepDesktop: boolean) => void;
};

const useNafathStore = create<State & Actions>((set) => ({
  isNafath: false,
  isNafathMobile: false,
  isNafathRandomStepMobile: false,
  isNafathRandomStepDesktop: false,
  setIsNafath: (isNafath) => set({ isNafath }),
  setIsNafathMobile: (isNafathMobile) => set({ isNafathMobile }),
  setIsNafathRandomStepMobile: (isNafathRandomStepMobile) =>
    set({ isNafathRandomStepMobile }),
  setIsNafathRandomStepDesktop: (isNafathRandomStepDesktop) =>
    set({ isNafathRandomStepDesktop }),
}));

export default useNafathStore;
