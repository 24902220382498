import {AxiosError, AxiosResponse} from "axios";

interface Result {
    success: (response: AxiosResponse) => any,
    error: (error: AxiosError) => AxiosError,
}

export default (): Result => {
    const myLink = process.env.REACT_APP_OPENSOOQ_BASE_DOMAIN_SSO.replace("jo", "my")

    return {
        success: (response: AxiosResponse) => {


            if (response.status==200||response.status==201)
            {
                response.data = {
                    ...response.data.result.data
                }
                return response

            }
            if (response.status == 403) {
              window.location.href = `${myLink}/listings/my-listings?limit=10&sort=relevant`;
            }

            return Promise.reject(response)

        },
        error: (error: AxiosError) => {
            console.log("in if interceptor error", error)
            return error
        }
    }
}