import { useTranslation } from "react-i18next";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import { getDataStorage } from "../../../../helpers/storage.helper";
import styles from "../Nafath.module.css";
import { useContext } from "react";
import LanguageContext from "../../../../storage/LanguageContext";

export default function FirstStep({
  national_id,
  setNational_id,
  error,
  onClickHandler,
}) {
  const storage = getDataStorage();
  const { t } = useTranslation();
  const languageContext = useContext(LanguageContext);

  const ArtoEn = function (number) {
    return number.replace(/[\u0660-\u0669]/g, (d) =>
      (d.charCodeAt(0) - 1632).toString()
    );
  };

  const handleChange = (e) => {
    error = null;
    let value = e.target.value;
    if (!/^[0-9٠-٩]*$/g.test(value)) {
      return;
    }
    value = ArtoEn(value);
    setNational_id(value);
    sessionStorage.setItem("national_id", value);
  };

  return (
    <div className={`main ${storage.isMobile ? "" : "col-8"}`}>
      <div
        className={`${styles.headerPending} p-2 d-flex align-items-center mb-4`}
      >
        <img
          src="https://opensooqui-new.s3.amazonaws.com/api/apiV/ios/2x/add_post_success/upselling_paid_listing/new/warning_icon.png"
          alt="warning_icon"
          height="32"
          width="32"
        />
        <span>{t("pendingVerification")}</span>
      </div>
      <div className={`${styles.bigCard}`}>
        <div className="p-3">
          <h2>{t("verifyVia")}</h2>
          <p className={styles.grayNote}>{t("publishListingNafath")}</p>
        </div>
        {!storage.isMobile ? <hr /> : null}
        <div className="p-3 mt-2">
          <label className="d-block mb-3" htmlFor="national_id">
            {t("nationalID")}
          </label>
          <input
            placeholder={t("enterNationalId")}
            onChange={(e) => {
              handleChange(e);
            }}
            type="text"
            name="national_id"
            id="national_id"
            value={
              sessionStorage.getItem("national_id")
                ? Number(sessionStorage.getItem("national_id"))
                : ""
            }
            className="ps-3"
          />
          {error && <ValidationError validation={{ error: error }} />}
        </div>
      </div>
      <div className={storage.isMobile ? styles.verifyBtnCont : ""}>
        <button
          className={`${
            storage.isMobile ? styles.verifyBtnMob : styles.verifyBtnDesk
          }  d-flex align-items-center mt-4 p-3 justify-content-center ${
            national_id.length < 1 ? styles.disabled : ""
          }`}
          disabled={national_id.length < 1}
          onClick={(e) => onClickHandler(e)}
        >
          <span className="pe-2">{t("verify")}</span>
          {!storage.isMobile ? (
            <img
              src={`https://opensooqui-new.s3.amazonaws.com/api/apiV/ios/2x/add_post_success/upselling_paid_listing/proceed_button_${
                languageContext.language
              }_${national_id.length > 0 ? "active" : "inactive"}.png`}
              alt="arrow"
              height="30"
              width="30"
            />
          ) : null}
        </button>
      </div>
    </div>
  );
}
