import React, {useContext, useEffect, useImperativeHandle, useState} from "react";
import {MediaStepProps} from "./interface";
import styles from './MediaStep.module.css'
import Media from "../../../UI/MediaUpload/Media";
import {getSizes} from "../../../../helpers/functions";
import PostContext from "../../../../storage/PostContext";
import {StateDataType} from '../../../UI/MediaUpload/MediaUpload.interfaces'
import UIContext from "../../../../storage/UIContext";
import DynamicObject from "../../../../models/dynamic-object";
import {useDataPayload, useValidation} from "../../../../hooks";
import FlowReferences from "../../../../flow-references";
import pqs_warning_icon from "../../../../assets/images/pqs_warning_icon.png"

const MediaSection = React.forwardRef<any, MediaStepProps>((props, ref) => {

    const {config} = props


    /************************************
     *            CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)

    /************************************
     *            STATE
     * *********************************/
    const [mediaState, setMediaState] = useState<StateDataType | null>(null)
    const uiCtx = useContext(UIContext)
    // useDataPayload({})
    const {ValidationMethods, error} = useValidation(ref as any)
    useImperativeHandle<any, any>(ref, () => {
        return {
            ...ValidationMethods
        }
    })

    FlowReferences.load(config.identifier, {
        validation: {
            ...ValidationMethods
        },
    })



    /*-------------------------------------
     *            FUNCTIONS
     * ----------------------------------*/

    /************************************
     *         STATE HANDLER
     * *********************************/
    const onChangeMedia = (state: StateDataType) => {
        // setMediaState(state)
    }



    /*---------------------------------------------------------------*/

    useEffect(function () {


        if (!mediaState)
            return

        const media = {...postCtx.data.media}
        media[config.identifier] = {
            ...mediaState
        }


        // // @ts-ignore
        // const data = props.loadData()
        //
        // postCtx.updateData({
        //     media: media,
        //     form: {
        //         ...postCtx.data.form,
        //         ...data
        //     }
        // })
        // setMediaState(null)


        // const errors = [
        //     ...uiCtx.errors.filter((error: DynamicObject) => error.field !== props.config.identifier)
        // ]
        //
        // if (errors.length !== uiCtx.errors.length)
        //     uiCtx.setErrors(errors.filter((error: DynamicObject) => error.field !== props.config.identifier))


    }, [mediaState])

    return (
        <div id={props.config.identifier} className={styles.container}>
            <div className={styles.header}>
                <h1>{config.title?.locale}</h1>
                <ul>
                    <li><span style={{
                        marginTop: "5px",
                        fontWeight: config.subTitle?.style?.bold ? "bold" : "normal",
                        fontSize: getSizes(config.subTitle?.style?.size),
                        color: config.subTitle?.color
                    }}>{config.subTitle?.locale}</span></li>
                </ul>
            </div>
            {config?.label?.type == 'pqs' && config?.label?.locale && (
        <div className={styles.label}>
              <img className={styles.img_icon} src={pqs_warning_icon}/>
          <p> {config?.label?.locale}</p>
        </div>
      )}

            <div className={styles.content}>
                <Media identifier={config.identifier} onChangeMedia={onChangeMedia} error={error} setError={ValidationMethods.set} config={config}/>
            </div>

        </div>
    )
});


export default MediaSection