import styles from "./NafathSteps.module.css";
import { getDataStorage } from "../../../../helpers/storage.helper";
import { ICreateNafathReq } from "../types/create-nafath.type";

export default function RandomStep({
  response,
}: {
  response: ICreateNafathReq;
}) {
  const storage = getDataStorage();

  const boldedLabel = (item) => {
    return item.label.replace(/\*(.*?)\*/g, (match, p1) => {
      return `<strong>${p1}</strong>`;
    });
  };
  const getCorrectDeepLink = () => {
    const ua = navigator?.userAgent?.toLowerCase();
    if (ua.includes("iphone") || ua.includes("ipad")) {
      return "ios";
    }
    return "android";
  };

  return (
    <div className={`main ${storage.isMobile ? styles.mainMob : "col-8"}`}>
      <div className={`${styles.bigCard}`}>
        <div className={storage.isMobile ? "mb-3 ps-3 pe-3" : "p-3"}>
          <div>
            <h2>{response.text.title.label} <img
              style={{
                verticalAlign: "middle"
              }}
              src={response.text.title.icon
                .replace("{platform}", "android")
                .replace("{size}", "xxh")}
              alt={response.text.title.label}
              height={30}
              width={30}
            /></h2>
          </div>
          <p className={styles.grayNote}>{response.text.subTitle.label}</p>
        </div>
        {!storage.isMobile ? <hr /> : null}
        <div className={storage.isMobile ? styles.containerMob : styles.container}>
          <h2 className={styles.heading}>
            {response.text.steps.step_label.label.replaceAll("*", "")}
          </h2>
          <ol className={storage.isMobile ? styles.stepsListMob : styles.stepsList}>
            {response.text.steps.steps.map((item, idx) => {
              return (
                <li
                  key={idx}
                  dangerouslySetInnerHTML={{
                    __html: `<span>${boldedLabel(item)}</span>`,
                  }}
                />
              );
            })}
          </ol>
          <div className={storage.isMobile ? styles.numberCircleMob : styles.numberCircle}>{response.random}</div>
        </div>
      </div>
      {storage.isMobile ? (
        <div className={storage.isMobile ? styles.verifyBtnCont : ""}>
          <a
            className={`${styles.verifyBtnMob}  d-flex align-items-center p-3 justify-content-center`}
            href={response?.mobile_site_deeplinks?.[getCorrectDeepLink()]}
            target="_blank"
            rel="noreferrer"
          >
            {response?.text?.button?.label}
          </a>
        </div>
      ) : null}
    </div>
  );
}
