import React, { createContext, useContext, useRef, useEffect, ReactNode, useState } from "react";

interface ValidationContextType {
  error: React.MutableRefObject<string | null>;
  hideError: boolean
  setError: (msg: string) => void;
  clearError: () => void;
}

const ValidationContext = createContext<ValidationContextType | undefined>(undefined);

export const ValidationProvider = ({ children }: { children: ReactNode }) => {
  const errorRef = useRef<string | null>(localStorage.getItem('error')||null);
  const [hideError, setHideError] = useState(false)
    if (errorRef.current === null) {
      errorRef.current = localStorage.getItem('error') || null;
      localStorage.removeItem('error');
    }

  useEffect(() => {
    const storedError = localStorage.getItem('error');
    if (storedError) {
      errorRef.current = storedError;
    }
  }, []);

  const setValidationError = (msg: string) => {
    localStorage.setItem('error', msg);
    setHideError(false)
    errorRef.current = msg;
    
  };

  const clearError = () => {    
    errorRef.current = null;
    setHideError(true)
    localStorage.removeItem('error');
  };

  return (
    <ValidationContext.Provider value={{ error: errorRef, setError: setValidationError, clearError, hideError: hideError }}>
      {children}
    </ValidationContext.Provider>
  );
};

export const useValidation = () => {
  const context = useContext(ValidationContext);
  if (!context) {
    throw new Error("useValidation must be used within a ValidationProvider");
  }
  return context;
};
