import React, {useContext} from "react";
import './VideoSection.css'
import {Props, RefDataType} from './interfaces';
import LanguageContext from "../../../../storage/LanguageContext";
import Video from "../Video/Video";

const VideoSection = React.forwardRef<RefDataType, Props>((props, ref) => {
    const {config} = props

    const langCtx = useContext(LanguageContext)

    const titleStyle = {

    } as React.CSSProperties

    return (<>
            {props.config.title && <div className={`mt-3 mb-3`} style={titleStyle}>{props.config.title?.locale} </div>
            }
            <Video config={config.config} identifier={config.identifier} maxLength={1}/>
        </>
    )
})
export default VideoSection