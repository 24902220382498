import React, {useContext, useEffect, useState} from "react";
import './PhoneField.module.css'
import {Props, RefDataType} from "./interface";
import {useValidation} from "../../../../hooks";
import {getSizes} from "../../../../helpers/functions";
import PostContext from "../../../../storage/PostContext";
import DynamicObject from "../../../../models/dynamic-object";
import LanguageContext from "../../../../storage/LanguageContext";
import {FormHelperText, SelectChangeEvent, Switch,} from "@mui/material";
import styles from "../../PreviewTools/PhoneField/PhoneField.module.css";
import PhoneFieldCode from "../../PreviewTools/PhoneField/PhoneFieldCode";


const PhoneField = React.forwardRef<RefDataType, Props>((props, ref) => {

        const {config} = props


        /*---------------------------------------------------------------
         *                           HOOKS
         * -------------------------------------------------------------*/

        /************************************
         *   SET & GET VALIDATION AND STATES
         * *********************************/
        const {error} = useValidation(ref)

        /*---------------------------------------------------------------*/


        /************************************
         *            CONTEXT
         * *********************************/
        const postCtx = useContext(PostContext)
        const langCtx = useContext(LanguageContext)

        /************************************
         *           CONSTANTS
         * *********************************/
        const alignCenter = (langCtx.language == 'en') ? 'left' : 'right'

        /************************************
         *            STATES
         * *********************************/
        const [value, setValue] = useState<DynamicObject>(postCtx.data.form[props.config.identifier])
        const [country, setCountry] = useState<string>(postCtx.data.form[config.identifier]?.country)

        /************************************
         *       PREVENT SCROLLING
         * *********************************/
        document.addEventListener("wheel", function (event) {
            document.getElementById("phone")?.blur()

        });


        /*---------------------------------------------------------------
         *                         FUNCTIONS
         * -------------------------------------------------------------*/
        /************************************
         *        ACTIONS HANDLER
         * *********************************/
        const changeHideFlagHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            value.hidePhone = event.target.checked
            postCtx.addToForm(config.identifier, value)
        }

        const phoneHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
            setValue((old) => {
                return {
                    ...old,
                    phone: e.target.value
                }
            })
        }

        const addCountryHandler = (e: SelectChangeEvent) => {
            setCountry(e.target.value)
            postCtx.addToForm(config.identifier, {...postCtx.data.form[config.identifier], country: e.target.value})
        }
        /*---------------------------------------------------------------*/

        /************************************
         *         INITIAL COUNTRY
         * *********************************/
        useEffect(function () {
            if (props.config?.country?.values.length > 0 && !country) {
               setCountry( config.country.values[0].abbreviation)
            }
        }, [props.config?.country.values])

        return (
            <div className={`${styles.parent_container}`}>
                <div className={`${styles.container}`}>

                    {/*FIRST ITEM COUNTRY ICON AND COUNTRY CODE */}
                    <div className={`${styles.country_container} ${styles.border_0}`}>
                        <PhoneFieldCode country={config.country} state={country}
                                        addCountryHandler={addCountryHandler}/>
                        <div className={`${styles.img_label}`}>
                            <div className={`${styles.item}`}>
                                <img className={`${styles.image}`} src={config.country.values[0].icon}/>
                            </div>
                            <span> {config.country.values[0].phone_code}</span></div>
                    </div>

                    {/*SECOND ITEM PHONE NUMBER */}
                    <input disabled={!config.editable}
                           name="phone"
                           id="phone"
                           type={"tel"}
                           dir={langCtx.language == "en" ? "ltr" : "rtl"}
                           value={value.phone}
                           // onBlur={onBlur}
                           onChange={phoneHandler}
                           className={`br-10 form-control ${styles.border_0}`}
                           style={{
                               color: config.text.color,
                               width: "100%",
                               fontSize: getSizes(config.placeholder.style?.size),
                               fontWeight: config.placeholder.style?.bold ? "bold" : "normal"
                           }}
                           placeholder={config.placeholder.locale} aria-describedby="basic-addon2"/>

                </div>
                {config.hideFlag &&
                    <div className={`row ${styles.flag}`}>
                        <div className={"col-md-6 ms-2"}>
                            <p>{config.hideFlag.locale}</p>
                        </div>
                        <div className={`col-md-6 ${styles.switch}`}>
                            <Switch onChange={changeHideFlagHandler} checked={value.hidePhone ?? false}/>
                        </div>

                    </div>
                }

                {error && <FormHelperText style={{
                    color: "red",
                    fontSize: "15px",
                    paddingTop: "2px",
                    textAlign: alignCenter

                }}>
                    {`*  ${error}`}
                </FormHelperText>

                }
            </div>
        )
    }
)

export default PhoneField