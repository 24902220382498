import React, {useContext, useEffect, useState} from "react";
import {useHttp} from "../../../hooks";
import DynamicObject from "../../../models/dynamic-object";
import PostContext from "../../../storage/PostContext";
import MapperFlow from "../../MapperFlow";
import {Args} from "../../../hooks/use-http/interfaces";
import LanguageContext from "../../../storage/LanguageContext";
import UIContext from "../../../storage/UIContext";
import {ComponentLoader} from "../../UI";
import NextApi from "../../../api/next.api";
import FlowReferences from "../../../flow-references";
import {configInitApi, configPreviousApi} from "../../../config/api.config";
import {AxiosResponse} from "axios";
import {getFinalPayload, removeSessionByKey, setSessionByKey} from "../../../helpers/functions";
import InitSurveyApi from "../../../api/init-survey.api";
import {useNavigate} from "react-router-dom";
import {MediaMapper} from "../../../helpers/media.functions";
import Previous from "../../UI/Previous/Previous";

const Survey = (): JSX.Element => {
    const postCtx = useContext(PostContext)
    const languageCtx = useContext(LanguageContext)
    const uiCtx = useContext(UIContext)
    const navigate = useNavigate()
    const urlParams = new URLSearchParams(window.location.search)

    const configInitSurveyApi = {
        callback: InitSurveyApi,
        initialData: {},
        withLoader: true
    }

    /************************************
     *         INIT REQUEST API
     * *********************************/
    const initApi = useHttp<DynamicObject>(configInitSurveyApi)
    const onSuccessInitApi = (response: AxiosResponse) => {
        postCtx.updateStep({
            workflow_id: response.data.workflow_id,
            draft_id: response.data.draft.id,
            // step: example.step,
            step: response.data.step,
            start_time: Date.now(),
            previous: response.data.previous,
            flow_type: "survey",
        })
        setSessionByKey('draftId', response.data.draft.id)
        setSessionByKey('flow-type', 'survey')
        navigate(`${response.data.step.uri}`)
    }

    initApi.request({
        config: {
            language: languageCtx.language,
            surveyType: urlParams.get('survey-type')
        },
        callbacks: {
            success: onSuccessInitApi
        },
    })
    /************************************
     *         Next REQUEST API
     * *********************************/
    const configNextApi = {
        callback: NextApi,
        initialData: {},
        withLoader: true
    }

    const nextApi = useHttp<DynamicObject>(configNextApi)
    const request2 = nextApi.request


    function initializeData() {
        const data: DynamicObject = {
            draftId: postCtx.data.draft_id,
            time_spent_ms: Date.now() - postCtx.data.start_time,
            workflowId: postCtx.data.workflow_id,
            currentStep: postCtx?.data?.step?.identifier,
        }


        for (const key in postCtx.data.currentStepData) {
            data[key as keyof typeof data] = postCtx.data.form[key as keyof typeof postCtx.data.form]
        }
        return data;
    }


    {/*Prepare Request next api*/
    }
    nextApi.request = function (args?: Args) {
        const data = initializeData();

        request2({
            ...args,
            config: {
                flow_type: postCtx.data.flow_type,
                ...args?.config,
                data: {
                    ...args?.config?.data,
                    ...data
                },
                language: languageCtx.language
            },
            callbacks: {
                ...args?.callbacks,
                before: () => {
                    uiCtx.setLoading(true)
                }
            }
        })
    }


    return (
        <div>
            {uiCtx.isLoading && <ComponentLoader/>}
            {
                postCtx.data?.step && !uiCtx.isLoading &&
                <MapperFlow config={postCtx.data.step}
                            nextApi={nextApi}
                />
            }

        </div>
    )
}


export default Survey