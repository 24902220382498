import React, {useContext, useState} from "react";
import {Props} from './interfaces';
import styles from "./Toggle.module.css";
import {getSizes} from "../../../../helpers/functions";
import {Switch} from "@mui/material";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import PostContext from "../../../../storage/PostContext";

const Toggle =  React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    const {validation, state} = useDataPayload<string>({ref, config: props.config, autoSaveDelay: 3000})
    const postCtx = useContext(PostContext)

    const DEFAULT_VALUE = +(postCtx.data.form[props.config.identifier] || state.value || props.config.default)

    const [value, setValue] = useState<number>(DEFAULT_VALUE)

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(+e.target.checked)
        state.set(+e.target.checked)
    }

    const containerStyle = {
        "--text-font-weight": props.config.text?.style?.bold ? "bold" : "normal",
        "--text-font-size": getSizes(props.config.text?.style?.size) + "px",
        "--text-font-color": props.config?.text?.color,
        "--text-font-align": props.config?.text?.align,
    } as React.CSSProperties

    return (
        <div className={`${styles.container}`} style={containerStyle}>
            <div className={`row`}>
                {props.config?.text && <div className={`col-9 ${styles.center} `}>{props.config?.text?.locale}</div>}
                <div className={`col-3 ${styles.center_toggle} `}>
                    <div className={`${styles.float_right}`}>
                        <Switch checked={!!value} size={"medium"} onChange={(e) => onChangeHandler(e)}></Switch>
                    </div>
                </div>
            </div>

        </div>)

})

export default Toggle
