import './PreviewStep.css'
import React, { useContext, useEffect, useState } from "react";
import stepModule from '../Step.module.css'
import MapperPreview from "../../../MapperPreview";
import PostContext, { flowLevels } from "../../../../storage/PostContext";
import DynamicObject from "../../../../models/dynamic-object";
import { PreviewStep as PreviewStepModel } from '../../../../models'
import { UseHttpResponse } from "../../../../hooks/use-http/interfaces";
import RefType from "../../../../models/ref-type";
import Step from "../Step";
import { useTranslation } from "react-i18next";
import { IoIosWarning } from "react-icons/io";
import CircularProgress from "@mui/material/CircularProgress";
import UIContext, { AFTER_LOADING_METHODS } from "../../../../storage/UIContext";
import { FUNNEL_EVENTS, trackEvent } from "../../../../helpers/Gtm";
import { getSizes, getUrlBasedOnCountry, isIFrame, parentCallBack } from "../../../../helpers/functions";
import { ComponentLoader } from "../../../UI";
import { getDataStorage } from "../../../../helpers/storage.helper";

import { useHttp, useQueryParams } from '../../../../hooks';

import PublishApi from "../../../../api/puplish.api";
import { Args } from '../../../../hooks/use-http/interfaces';
import { isNumeric } from '../../../../helpers/functions';
import { logEditListingSubmit, logLimitListingCategory, logLimitListingSubCategory, logLimitListingAccount, logApplyCvSuccess, logEditListingSuccess, logAddListingSuccess } from '../../../../logging/helpers/commonLoggingFunctions';
import { AxiosError, AxiosResponse } from "axios";
import useModalStore from '../../../../storage/ModalContext';
import PredictedPriceModal from '../../PreviewTools/PredictedPriceModal/PredictedPriceModal';
import { pushEventsToDataLayer } from '../../../../helpers/Gtm';
import { EnumActionNameLevel3, Post } from '../../../../logging/types/LoggingTypes';
import { defaultOptions, setCookie } from '../../../../logging/helpers/commonCookiesFunctions';
import { updateScreenHistory } from '../../../../logging/helpers/updateScreenHistory';
import { updatePostLocationApi } from '../../../../api/updatePostLocation.api';
import Cookies from "js-cookie";
import { REFRESH_PAGE } from '../../../../constants/Status';
import { toastify } from '../../../tostify/snackbarAlert';
import FlowReferences from '../../../../flow-references';
import LanguageContext from '../../../../storage/LanguageContext';
import { useNavigate } from 'react-router-dom';
import { isValidRateGroup } from '../../PreviewTools/Section/rateEnums';
import { useValidation } from '../../PreviewTools/Section/context/ValidationContext';
import PQSv4 from '../../PreviewTools/PQSv4/PQSv4';


interface Props {
    children?: React.ReactNode;
    nextApi?: UseHttpResponse<DynamicObject>;
    config: PreviewStepModel
}

interface RefDataType extends RefType<DynamicObject> {
}

const PreviewStep = React.forwardRef<RefDataType, Props>((props, ref): JSX.Element => {

    const navigate = useNavigate();

    const { setError: setErrorMessage } = useValidation();


    const { config } = props

    const [configData, setConfigData] = useState<DynamicObject>(config)
    const { t } = useTranslation();
    const postCtx = useContext(PostContext)
    const uiCtx = useContext(UIContext)
    const storage = getDataStorage()


    window.sessionStorage.setItem('draftId', postCtx.data.draft_id)

    useEffect(function () {
        if (sessionStorage.getItem('loggingFlow') && (sessionStorage.getItem('loggingFlow') === 'draft' || sessionStorage.getItem('loggingFlow') === 'republish')) {
            trackEvent(FUNNEL_EVENTS.INIT);
            trackEvent(FUNNEL_EVENTS.PREVIEW);
        } else if (postCtx.data.flow_type === 'add' || postCtx.data.flow_type === 'post') {
            trackEvent(FUNNEL_EVENTS.PREVIEW);
        }
        postCtx.updatePreviewStep(configData)
        // scoreHttp?.request(httpConfig)
    }, [])


    {/*CONTENT*/
    }

    {/* Here We init the Next Api and it's */ }

    const { updateModalData, updatePayLoad, showPredictedPriceModal, updateStopScroll } = useModalStore();
    const langCtx = useContext(LanguageContext)


    const configPublishApi = {
        callback: PublishApi,
        initialData: {},
        withLoader: true
    }
    const publishApi = useHttp<DynamicObject>(configPublishApi)
    const request = publishApi.request

    const logLeadingToService = (payload: any) => {
        if (!payload)
            return;

        const neighborhood = configData && configData.GA && configData.GA.cd && configData.GA.cd.Subcategory ? configData.GA.cd.Neighborhood : '';

        const cityField = configData && configData.GA && configData.GA.cd && configData.GA.cd.Subcategory ? configData.GA.cd.City : '';

        const categoriesSub = configData && configData.GA && configData.GA.cd && configData.GA.cd.Subcategory ? configData.GA.cd.Subcategory : '';

        const categoriesMain = configData && configData.GA && configData.GA.cd && configData.GA.cd.Category ? configData.GA.cd.Category : '';

        logAddListingSuccess({
            id: payload.post_id,
            neighborhoodName: neighborhood,
            city: cityField,
            category: { label: categoriesMain },
            subCategory: categoriesSub,
            service: '',
        }, 200);
    }


    const overLimitLog = (overLimitType: string, post: Post) => {
        switch (overLimitType) {
            case EnumActionNameLevel3.CATEGORY:
                logLimitListingCategory(post, 200);
                break;
            case EnumActionNameLevel3.SUB_CATEGORY:
                logLimitListingSubCategory(post, 200);
                break;
            case EnumActionNameLevel3.ACCOUNT:
                logLimitListingAccount(post, 200);
                break;
            default:
                break;
        }
    }

    publishApi.request = (args?: Args) => {
        const data = args?.config?.data;
        const isChecked = sessionStorage.getItem("checked") == "true" ? 1 : 0;
        data.hide_plate_number = isChecked ? 1 : 0;
        const urlParams = new URLSearchParams(window.location.search)
        const flow = urlParams.get('flow');

        const neighborhood = configData && configData.GA && configData.GA.cd && configData.GA.cd.Subcategory ? configData.GA.cd.Neighborhood : '';
        const cityField = configData && configData.GA && configData.GA.cd && configData.GA.cd.Subcategory ? configData.GA.cd.City : '';
        const categoriesSub = configData && configData.GA && configData.GA.cd && configData.GA.cd.Subcategory ? configData.GA.cd.Subcategory : '';
        const categoriesMain = configData && configData.GA && configData.GA.cd && configData.GA.cd.Category ? configData.GA.cd.Category : '';
        const postId = urlParams.get('post_id') ?? '';
        let post = {
            id: parseInt(postId),
            neighborhoodName: neighborhood,
            city: { name: cityField },
            category: { label: categoriesMain },
            subCategory: categoriesSub,
            service: '',
        }
        if (flow === 'edit' && isNumeric(postId)) {
            logEditListingSubmit(post)
        }
        const onSuccessPublishApi = (response: AxiosResponse) => {

            if (response.data.redirectToNafath) {
                navigate(`?flow=nafath&post_id=${response?.data?.post?.id}`);
            }
            if (response.data.warning && Object.keys(response.data.warning).length > 0) {
                // Handle the case where the price does not hit the 80% confidence level
                updateStopScroll(true)
                updateModalData(response.data);
                // return 

            }
            if (sessionStorage.getItem('loggingFlow') && (sessionStorage.getItem('loggingFlow') === 'draft' || sessionStorage.getItem('loggingFlow') === 'republish')) {
                trackEvent(FUNNEL_EVENTS.SUCCESS);
                pushEventsToDataLayer();
            } else if (postCtx.data.flow_type === 'add' || postCtx.data.flow_type === 'post') {
                trackEvent(FUNNEL_EVENTS.SUCCESS);
                pushEventsToDataLayer();
            }

            if (response && response.data && response.data.post && response.data.post.overLimitType) {
                overLimitLog(response.data.post.overLimitType, post);
            }
            if (sessionStorage.getItem('flow-type') === 'delete' && response && response.data && response.data.alert_message) {
                setCookie('alert_message', response.data.alert_message, defaultOptions)
                setCookie('show_success_delete', "1", defaultOptions)
                sessionStorage.clear();
                window.location.href = `${process.env.REACT_APP_JO_SOOQ_URL.replace("XX.", "").replace("xx.", "")}/site/dl?requestUri=${storage.country}/myposts`
            }
            if (flow === 'jobApply') {
                post = {
                    id: parseInt(postId),
                    neighborhoodName: '##MISSING##',
                    city: { name: '##MISSING##' },
                    category: { label: '##MISSING##' },
                    subCategory: '##MISSING##',
                    service: '',
                }
                updateScreenHistory('listing_view')
                logApplyCvSuccess(post)
                parentCallBack('job_applied')
                const baseUrl = getUrlBasedOnCountry(storage.country)
                const url = `${baseUrl}/${urlParams.get('lang')}/job-posters/${urlParams.get('post_id')}?applied=${true}`
                return window.top.location.href = url
            }
            if (urlParams.get('flow') === 'edit') {
                const postId = response?.data?.draft?.payload?.post_id
                if (isNumeric(postId)) {
                    logEditListingSuccess(post)
                }
            }
            // Update post location data
            setTimeout(() => {
                if (response) {
                    updatePostLocationApi(response?.data?.draft?.payload?.post_id);
                }
            }, 400);

            //logging
            if (response && response.data && response.data.draft && response.data.draft.payload && response.data.draft.payload.post_id) {
                try {
                    logLeadingToService(response.data.draft.payload);
                } catch (err) {
                    console.log(err);
                }
            }
            if (postCtx.data.flow_type === "survey") {
                const baseUrl = getUrlBasedOnCountry(storage.country)
                const url = `${baseUrl}`
                return window.top.location.href = url
            }
            if (!!Cookies.get('EmployeeToken') && isIFrame() && Cookies.get('audience') == 'infinity') {
                const baseUrl = process.env.REACT_APP_INFINITY_URL;
                //handle infinity case
                if (response.data?.draft?.payload?.post_id) {
                    const url = `${baseUrl}/post/${urlParams.get('flow') === "republish" ? 'clone' : 'add'}-listing-success?id=${response.data?.draft?.payload?.post_id}&memberId=${storage.userInfo.id}`
                    return window.top.location.href = url
                }

            }
            parentCallBack('without_confirm_dialog')
            parentCallBack(REFRESH_PAGE)
            uiCtx.setLoading(false)
            postCtx.updateStep({
                workflow_id: response.data.workflow_id,
                draft_id: response.data.draft.id,
                step: response.data.step,
                start_time: Date.now(),
                previous: response.data.previous,
                post_id: response.data?.draft?.payload?.post_id,
                overLimitType: response.data?.post?.overLimitType,
                isOverLimit: response.data?.post?.overLimitType,
                formType: response?.data?.review_identifier
            });
            sessionStorage.removeItem('draftId')
        }

        const onError = (error: AxiosError) => {
            uiCtx.setLoading(false)
            if (error.response?.status === 422) {
                AFTER_LOADING_METHODS.push(() => {
                    const data = error.response?.data as DynamicObject
                    const hasPropertyPost_id = data?.result?.errors.some(obj => obj.field === 'post_id')
                    if (urlParams.get('flow') == 'jobApply' && !hasPropertyPost_id) {
                        return
                    }
                    for (const key in data?.result?.errors) {
                        const errorObject = data?.result?.errors[key] as DynamicObject

                        if (isValidRateGroup(errorObject.field)) {
                            setErrorMessage(errorObject?.message);
                        }
                        if (!errorObject.field || errorObject.field == "post_id") {
                            toastify({ toastType: "error", description: errorObject?.message ?? 'Something Went Wrong!', locale: langCtx.language });
                            continue;
                        }
                        const ref = FlowReferences.get(errorObject.field)
                        if (!ref?.validation?.set)
                            continue

                        if (key == "0") {
                            let element = document.getElementById(errorObject.field)
                            element?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                        }
                        ref.validation.set(errorObject?.message)
                    }
                })
            }
        }


        let step = postCtx.previewStep as DynamicObject
        for (const key in FlowReferences.reRenderedFlow) {

            const updateConfigByLevel = (config, levels) => {
                if (!levels.length && FlowReferences.get(key) && FlowReferences.get(key)?.config) {
                    return FlowReferences.get(key).config.get()
                }

                const level = levels.shift()
                config.childs[level] = updateConfigByLevel({ ...config.childs[level] }, [...levels])
                return config
            }

            step = updateConfigByLevel({ ...step }, [...flowLevels[key] || []])
        }

        postCtx.updateData({
            form: {
                ...data,
            },
            step
        })

        postCtx.updatePreviewStep(step)

        //to check if it's on behalf log in
        const isBehalfLogIn = Cookies.get('EmployeeToken') && isIFrame() && Cookies.get('audience') == 'infinity';




        return request({
            ...args,
            config: {
                ...args?.config,
                flow_type: postCtx.data.flow_type,
                language: langCtx.language,
                workflowId: postCtx.data.workflow_id,
                data: {
                    ...args?.config?.data,
                    ...data,
                    draftId: postCtx.data.draft_id,
                    member_id: isBehalfLogIn ? storage?.userInfo?.id : '',
                    time_spent_ms: Date.now() - postCtx.data.start_time,
                }
            },
            callbacks: {
                ...args?.callbacks,
                success: onSuccessPublishApi,
                error: onError,
                before: () => {
                    uiCtx.setLoading(true)
                    //if popup not to erase the page, set to false 
                }
            }
        })


    }

    const params = useQueryParams();



    const handlePqsScrollByIdentitifer = () => {
        const element = document.getElementById(postCtx?.previewStep["PQSv4"]?.scrollToReason)
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
    }
    return (
        <Step>
            {uiCtx.isLoading && <CircularProgress />}
            {!storage.isMobile && config.title && <div style={{ padding: "0px 14px" }}>
                <p style={{
                    fontWeight: config.title.style?.bold ? "bold" : "normal",
                    fontSize: getSizes(config.title.style?.size),
                    color: config.title.color,
                }}>{config.title.locale}</p>
            </div>}

            <div style={{ marginBottom: storage.isMobile && props.config?.submit?.sticky && '50px' }} className={`${stepModule.content} preview_step`}>
                {postCtx.data.remaining_edit_counter && <div style={{
                    padding: "5px",
                    display: "flex",
                    gap: "5px",
                    marginTop: "10px",
                    backgroundColor: "#f9f9f9",
                    borderRadius: "10px"
                }}>
                    <IoIosWarning style={{ height: "20px", width: "20px" }}></IoIosWarning>
                    <div style={{
                        fontWeight: "bold",
                        fontSize: "15px"
                    }}>  {`${t('NumberOfPostEditLeft')} ${postCtx.data.remaining_edit_counter}`}
                    </div>
                </div>}


                {uiCtx.isLoading && <ComponentLoader />}
                {showPredictedPriceModal && <PredictedPriceModal api={publishApi} />}
                {configData && (
                    <>
                        {storage?.isMobile && postCtx?.previewStep['PQSv4'] &&
                            <PQSv4 handlePqsScrollByIdentitifer={handlePqsScrollByIdentitifer}  {...postCtx?.previewStep['PQSv4']} isMobile={storage?.isMobile} />}


                        <MapperPreview
                            config={configData}
                            setConfigData={setConfigData}
                            publishApi={publishApi}
                        />
                    </>
                )}

            </div >
        </Step >
    )
})

export default PreviewStep