import React, {useContext} from "react";
import {getSizes} from "../../../helpers/functions";
import PostContext from "../../../storage/PostContext";
import {FaAngleLeft, FaAngleRight} from "react-icons/fa";
import stepModule from "../../Flow/Steps/Step.module.css";
import LanguageContext from "../../../storage/LanguageContext";

interface Props {
    onClickPreviousHandler: () => void,
    withTitle?: boolean
}

const Previous = (props: Props) => {

    const langCtx = useContext(LanguageContext)
    const postCtx = useContext(PostContext)
    const titleStyle = {
        fontWeight: postCtx.data?.step?.title?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(postCtx.data?.step?.title?.style?.size),
        color: postCtx.data?.step?.title?.color,
        marginTop: "4px"
    } as React.CSSProperties
    return (
        <>
            {
                <div className={`${stepModule["previous"]} `} id="PreviousButton">
                    {(langCtx.language === 'en') ?
                        <FaAngleLeft className={`${stepModule.icon} ms-2 me-0`} onClick={props.onClickPreviousHandler} />  :
                        <FaAngleRight className={`${stepModule.icon} me-0 ms-2`} onClick={props.onClickPreviousHandler} />}
                    {props.withTitle && <div style={titleStyle}>{postCtx?.data?.step?.title?.locale}</div>
                    }
                </div>
            }
        </>
    )
}

export default Previous