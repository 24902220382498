import React, { useEffect, useState } from 'react';
import useCheckboxStore from '../../../storage/CheckboxContext';
import { useTranslation } from 'react-i18next';
import './HideCarPlateCheckbox.css';

interface HideCarPlateCheckboxProps {
    defaultChecked?: boolean; // Optional prop, can be `undefined` or `null`
}

const HideCarPlateCheckbox: React.FC<HideCarPlateCheckboxProps> = ({ defaultChecked = null }) => {
    const { labelText } = useCheckboxStore();
    const { t } = useTranslation();

    // Determine the initial checkbox state
    const [check, setChecked] = useState(defaultChecked);

    useEffect(() => {

        if(check == null || defaultChecked == null){
                    // Sync with sessionStorage when the component mounts
        const storedValue = sessionStorage.getItem("checked");
        if (storedValue === null) {
            sessionStorage.setItem("checked", "true"); // Default to true if not present
            setChecked(true)
        } else {
            setChecked(Boolean(storedValue == "true"));
        }

        // Add event listener to sync across instances
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === "checked") {
                setChecked(event.newValue == "true");
            }
        };
        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
        }

    }, [defaultChecked]);


    useEffect(() => {
        if(defaultChecked == true || defaultChecked == false) {
            sessionStorage.setItem("checked", `${defaultChecked}`); // Default to true if not present
            setChecked(defaultChecked)
        }
    }, [defaultChecked])


    const handleStorage = () => {
        const newValue = !check;
        setChecked(newValue);
        sessionStorage.setItem("checked", newValue.toString());
        // Trigger the `storage` event manually for other tabs or instances
        window.dispatchEvent(new StorageEvent("storage", { key: "checked", newValue: newValue.toString() }));
    };

    return (
        <div className="checkbox-container">
            <input
                type="checkbox"
                id="flexCheckChecked"
                className="custom-checkbox"
                checked={check}
                onChange={handleStorage}
            />
            <label className="form-check-label" htmlFor="flexCheckChecked">
                {labelText || t('hide plate number')}
            </label>
        </div>
    );
};

export default HideCarPlateCheckbox;
