// rateGroup.ts

export enum RateGroup {
    Tablet = 'tabletRateGroup',
    Mobile = 'mobileRateGroup',
    Car = 'carRateGroup'
  }
  
  export function isValidRateGroup(value) {
    return Object.values(RateGroup).includes(value);
  }