import {Loader, LoaderOptions,} from '@googlemaps/js-api-loader';
import {googleMapsConfig} from "../config/index";
import DynamicObject from "../models/dynamic-object";

const options = {
    apiKey: googleMapsConfig.key,
    libraries: [...googleMapsConfig.libraries]
};
const loader = new Loader(options);

// //
export class GoogleMap {
    private readonly mapElement?: HTMLElement
    private inputLat?: HTMLInputElement
    private inputLng?: HTMLInputElement
    private readonly lat?: number
    private readonly lng?: number
    private map?: google.maps.Map
    private marker?: google.maps.Marker

    constructor(mapClass: string) {
        this.mapElement = document.querySelector("." + mapClass) || undefined
        for (const index in this.mapElement?.parentNode?.children) {
            if (Number.isNaN(parseInt(index)))
                break;

            const child = this?.mapElement?.parentNode?.children[parseInt(index)] as HTMLInputElement

            if (child?.classList.contains("lat")) {
                this.inputLat = child
            }
            if (child?.classList.contains("lng")) {
                this.inputLng = child
            }
        }
        this.lat = parseFloat(<string>this?.inputLat?.value);
        this.lng = parseFloat(<string>this?.inputLng?.value);
        if (this.lat === 0 && this.lng === 0 || (isNaN(this.lat) && isNaN(this.lng))) {
            this.lat = 31.968385519564386;
            this.lng = 35.899050030990004;
        }
    }


    async loadMap(config : {zoom?: number, center?: any} = {zoom: 9, center: null}) {
        if (!this.mapElement)
            return

        const google = await loader.load()
        if (!config.center)
            config.center = {lat: this.lat || 0, lng: this.lng || 0};

        // const container = document.createElement("div")
        // container.classList.add("search-place__container")
        //
        // const input = document.createElement("input")
        // input.setAttribute("type", "text")
        // input.classList.add("search-place__input")
        //
        // container.appendChild(input)

        this.map = new google.maps.Map(this.mapElement, {
            zoom: config.zoom,
            center: config.center
        });
        // this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(container);

        // const autocomplete = new google.maps.places.Autocomplete(input);

        this.marker = new google.maps.Marker({
            position: config.center,
            map: this.map,
            draggable: true
        });

        this.map.addListener("center_changed", this.changeMarkerPositionFromCenter.bind(this));
        this.map.addListener("zoom_change", this.changeMarkerPositionFromCenter.bind(this));
        this.marker.addListener("dragend", this.setPositionToInputs.bind(this))
        // autocomplete.addListener('place_changed', this.changeMarkerPositionFromSearchPlace.bind(this, autocomplete));

    }

    async loadSearchBox() {

    }

    private changeMarkerPositionFromCenter() {
        let latLng = this?.map?.getCenter();
        this.changeMarkerPosition(latLng)
    }

    private changeMarkerPositionFromSearchPlace(autocomplete: { getPlace: () => any; }) {
        const place = autocomplete.getPlace();
        this?.map?.fitBounds(place.geometry.viewport);

        if (!place.geometry) {
            return false;
        }
        this.changeMarkerPosition(place.geometry.location)
    }


    private changeMarkerPosition(latLng?: google.maps.LatLng) {
        this?.marker?.setPosition(latLng);
        this.setPositionToInputs();
    }


    private setPositionToInputs() {

        if (this?.inputLng)
            this.inputLng.value = this?.marker?.getPosition()?.lng().toString() || ""

        if (this?.inputLat)
            this.inputLat.value = this?.marker?.getPosition()?.lat().toString() || ""

        const event = new Event('input', {bubbles: true});
        this.inputLat?.dispatchEvent(event);
        this.inputLng?.dispatchEvent(event);
    }
}