import {AxiosResponse} from "axios";
import ArachnaApi from './requests/arachna-api'
import DynamicObject from "../models/dynamic-object";
import {AddPostFilterResponse} from "../interceptors";
import Cookies from "js-cookie";
import {isIFrame} from "../helpers/functions";

export default (config?: DynamicObject): Promise<AxiosResponse> => {
    const isBehalfLogIn=Cookies.get('EmployeeToken') && isIFrame() && Cookies.get('audience') == 'infinity';
    const request = ArachnaApi.getInstance().request
    request.interceptors.response.use(AddPostFilterResponse().success, AddPostFilterResponse().error)

    return request.get(`${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/add-post/republish${isBehalfLogIn?'_on-behalf':''}/${config?.post_id}${isBehalfLogIn?`/${config?.member_id}`:''}`, {})
}