import axios from "axios";
import { ILoggingAPIBody, ILoggingAPIsuccessResposnse } from "../types/LoggingTypes";
import { getDataStorage } from "../../helpers/storage.helper";
import i18n from "../../i18n";
import { checkOnBehalfLogInFlow } from "../../helpers/functions";
import Auth from "opensooq-sso";
import Cookies from "js-cookie";
import { generateABBucket } from "../../helpers/generateAbbucket";

export const LoggingAPICall = async (
    body: ILoggingAPIBody
): Promise<ILoggingAPIsuccessResposnse> => {
    const storage = getDataStorage()
    const device_uuid = storage.device_uuid || (storage.userInfo?.id || '')
    const lang = storage.lang || "ar"
    let token = '';
    const userAbBucket = Cookies.get("userABBucket") || generateABBucket();

    if(await checkOnBehalfLogInFlow()){
        return;
    }else{
        token = await Auth.getInstance().getToken(true,true);
    }
    const config = {
        headers: {}
    }
    config.headers['Content-Type'] = 'application/json'
    config.headers['source'] = storage.source
    config.headers['country'] = storage.country
    config.headers['session-id'] = device_uuid
    config.headers['X-Tracking-UUID'] = device_uuid
    config.headers['accept-language'] = lang
    config.headers['enforce-language'] = lang
    config.headers['Authorization'] = "Bearer " + token
    config.headers['abBucket'] = userAbBucket
    return axios.post(`${process.env.REACT_APP_NEXT_PUBLIC_LOGGING_URL_CLIENT}?abBucket=${userAbBucket}` , body, config);
};
