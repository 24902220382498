export const MediaSizes = {
    Large: "large",
    Medium: "medium",
    Small: "small"
}

export const MEDIAS_PER_LINE_ON_SCREENS = {
    large: 4,
    medium: 3,
    small: 2,
}

export const Lines = {
    large: 2,
    medium: 3,
    small: 3,
}

export const MediaBoxSize = {
    large: 160,
    medium: 95,
    small: 150
}

export const BoxesGap = {
    large: 14,
    medium: 7,
    small: 5
}

export let DefaultSize = MediaSizes.Large

export const updateDefaultSize = (size) => DefaultSize = size


export const getMediaSize = (width) => {

    switch (true) {
        case width > 600:
            return MediaSizes.Large
        case width > 300:
            return MediaSizes.Medium
        case width < 320:
            return MediaSizes.Small
    }


}