import Cookies from "js-cookie";
import {generateABBucket} from "./generateAbbucket";

const abBucketValue = Cookies.get("userABBucket") || generateABBucket();

export const FUNNEL_EVENTS = {
    INIT: { eventAction: 'APF_Init', eventCategory: 'Sellers', eventLabel: 'AddPostScreen' , ABBucket : abBucketValue},
    PREVIEW: { eventAction: 'APF_Preview', eventCategory: 'Sellers', eventLabel: 'Preview_AddPostScreen', ABBucket : abBucketValue },
    SUBMIT: { eventAction: 'APF_Submit', eventCategory: 'Sellers', eventLabel: 'SubmitBtn_AddPostScreen', ABBucket : abBucketValue },
    SUCCESS: { eventAction: 'APF_Success', eventCategory: 'Sellers', eventLabel: 'API_AddPostScreen' , ABBucket : abBucketValue},
    DROP: { eventAction: 'APF_Drop', eventCategory: 'Sellers', eventLabel: 'CloseBtn_AddPostScreen' , ABBucket : abBucketValue},
    DELETE: { eventAction: 'InitDeletePost', eventCategory: 'Sellers', eventLabel: 'DeleteBtn_AddPostFormScreen',ABBucket : abBucketValue },
};

const getEventQueue = (): Array<{ eventAction: string; eventCategory: string; eventLabel: string; ABBucket:string }> => {
    const queue = sessionStorage.getItem('eventQueue');
    return queue ? JSON.parse(queue) : [];
};

const saveEventQueue = (queue: Array<{ eventAction: string; eventCategory: string; eventLabel: string; ABBucket:string }>) => {
    sessionStorage.setItem('eventQueue', JSON.stringify(queue));
};

export const trackEvent = (event: { eventAction: string; eventCategory: string; eventLabel: string; ABBucket:string }) => {
    let eventQueue = getEventQueue();
    if (!eventQueue.some((e) => e.eventAction === event.eventAction)) {
        eventQueue.push(event);
        saveEventQueue(eventQueue);
    }
};


export const pushEventsToDataLayer = () => {
    let eventQueue = getEventQueue();

    // Check if APF_Init and APF_Preview events exist in the queue
    const initEventExists = eventQueue.some((event) => event.eventAction === FUNNEL_EVENTS.INIT.eventAction);
    const previewEventExists = eventQueue.some((event) => event.eventAction === FUNNEL_EVENTS.PREVIEW.eventAction);

    // If APF_Init is missing, add it to the queue
    if (!initEventExists) {
        eventQueue.push(FUNNEL_EVENTS.INIT);
    }

    // If APF_Preview is missing, add it to the queue
    if (!previewEventExists) {
        eventQueue.push(FUNNEL_EVENTS.PREVIEW);
    }

    // Save the updated queue if any event was added
    if (!initEventExists || !previewEventExists) {
        saveEventQueue(eventQueue);
    }

    // Push each event to the dataLayer
    eventQueue.forEach((event) => {
        window.dataLayer.push({
            event: "UserInteraction",
            eventAction: event.eventAction,
            eventCategory: event.eventCategory,
            eventLabel: event.eventLabel,
            ABBucket: event.ABBucket
        });
    });

    // Clear the queue after pushing the events
    sessionStorage.removeItem('eventQueue');
};
