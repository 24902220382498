import React, {useContext, useEffect, useState} from "react";
import {Props, RefDataType} from './interfaces';
import styles from './PaymentWidget.module.css'
import PackageTypes from "../PackageTypes/PackageTypes";
import {fetchFirstProduct, fetchPackageConfig} from './functions';
import LanguageContext from "../../../../storage/LanguageContext";
import {useTranslation} from "react-i18next";
// import 'opensooq-services/src/assets/css/CreditPackageCol.css';
// import 'opensooq-services/src/assets/css/CustomButton.css';
// import 'opensooq-services/src/assets/css/DefaultPackageCol.css';
// import 'opensooq-services/src/assets/css/PackageBenefits.css';
// import 'opensooq-services/src/assets/css/PackageTypes.css';
// import 'opensooq-services/src/assets/css/PackageButton.css';
// import 'opensooq-services/src/assets/css/PackageGridItem.css';
// import 'opensooq-services/src/assets/css/PackageList.css';
// import 'opensooq-services/src/assets/css/PaymentWidget.css';
// import 'react-loading-skeleton/dist/skeleton.css';
// import 'opensooq-services/dist/esm/assets/css/Main.css'

// import {PaymentWidget as PaymentWidgetBundle} from "opensooq-services";
import {generateAuthToken} from "../../../../helpers/auth.helper";
import PostContext from "../../../../storage/PostContext";
import {getDataStorage} from "../../../../helpers/storage.helper";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import {getUrlBasedOnCountry} from "../../../../helpers/functions";
import { ActionDimensions } from "../../../../logging/dimensions/ActionDimensions";
import { PointOfSales } from "../../../../helpers/PointOfSales";


const PaymentWidget = React.forwardRef<RefDataType, Props>((props, ref) => {

    const langCtx = useContext(LanguageContext)
    const packages = props.config?.data?.packages
    const urlParams = new URLSearchParams(window.location.search)
    const pathname = window.location.pathname;
    const [pos, setPos] = useState<PointOfSales>(undefined);

    let products = null
    if (packages) {
        products = Object.keys(packages)
    }
    const {t} = useTranslation()
    const [token, setToken] = useState<string>('')
    const postCtx = useContext(PostContext)
    const storage = getDataStorage()
    const getToken = () => {
        return generateAuthToken()
    }

    useEffect(() => {
        let flow = urlParams.get('flow');
        if(flow){
            sessionStorage.setItem('flowType', flow);
        }else{
            flow = sessionStorage.getItem('flowType');
            sessionStorage.removeItem('flowType')
        }
        const isPending = pathname.includes('pending-payment');
        if(flow && flow === 'edit'){   
            setPos(isPending ? PointOfSales.EDIT_LISTING_POS_PENDING : PointOfSales.EDIT_LISTING_POS_SUCCESS);
        }else if(flow && flow === 'activate'){
            console.log(postCtx.data, "activate");
            if(postCtx.data.isOverLimit)
                setPos(PointOfSales.MYLISTINGVIEW_PENDINGPAYMENT_ACTIVATELISTING);
            else
                setPos(PointOfSales.MYLISTINGVIEW_DELETED_LISTING_ACTIVATELISTING);
        }else{
            setPos(isPending ? PointOfSales.ADD_LISTING_POS_PENDING : PointOfSales.ADD_LISTING_POS_SUCCESS);
        }
        getToken().then(token => {
            setToken(token ?? '');
        });
    }, [])


    return token && <div className={`w-100 ${!storage.isMobile && 'd-flex flex-row justify-content-center'} `}>
        {/*<div style={{width:"100%"}}><PaymentWidgetBundle*/}
        {/*    service={props?.config?.is_combo && "MemberCombo"} isCombo={props?.config?.is_combo}*/}
        {/*    referral_url={`${Cookies.get('addPostRef') ?? getUrlBasedOnCountry(storage.country)}`}*/}
        {/*    products={products}*/}
        {/*    domain={process.env.REACT_APP_DOMAIN_PAYMENT_API}*/}
        {/*    api={process.env.REACT_APP_PAYMENT_API} token={token}*/}
        {/*    source={storage.source} countryAbbreviation={storage.country}*/}
        {/*    language={langCtx.language} modelId={postCtx.data.post_id}*/}
        {/*    loggingConfig={{*/}
        {/*        version: `${process.env.REACT_APP_PUBLIC_VERSION}`,*/}
        {/*        loggingEnv:*/}
        {/*        {   */}
        {/*            url: process.env.REACT_APP_NEXT_PUBLIC_LOGGING_URL_CLIENT,*/}
        {/*            token: ''*/}
        {/*        },*/}
        {/*        sessionFunnelSource: ActionDimensions.getInstance().currScreen*/}
        {/*    }}*/}
        {/*    pos_source={pos ?? ''}*/}
        {/*    />*/}
        {/*</div>*/}
    </div>

    // return (
    //
    //     <div className={`${styles.box}`}>
    //         <div className={`row ${styles.row}`}>
    //             <div className={`col ${styles.col}`}>
    //                 {/*<h4>{label}</h4>*/}
    //                 <h4>{
    //                     isElas ? t('activateYourAdNow!') : t('doYouWantMoreViews?')
    //                 }</h4>
    //             </div>
    //         </div>
    //         <PackageTypes packageConfig={packageConfig} setLabel={setLabel} product={product}
    //                       bundles={props.config.data.bundle} packages={packages}/>
    //     </div>
    // )
})
export default PaymentWidget














