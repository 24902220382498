import React, {useContext, useEffect, useReducer, useState} from "react";
import {t} from "i18next";
import {
    DraftDataDataType,
    FileDataType,
    MediaAction,
    MediaActionKind, MediaList,
    StateDataType
} from "./Video.interfaces";
import DynamicObject from "../../../../models/dynamic-object";
import {generateFileHash} from "../../../../helpers/media.functions";
import MediasCacher from "../../../../models/medias-cacher";
import PostContext from "../../../../storage/PostContext";
import useValidationNew from "../../../../hooks/use-validation/use-validation-new";
import uploadMediaApi from "../../../../api/upload-video.api";
import uploadMediaApiDraft from "../../../../api/upload-media-draft.api";
import deleteMediaApi from "../../../../api/delete-media.api";
import VideoStyle from "./VideoStyle";
import VideoSection from "../../../../models/tools/video-section";
import {parentCallBack} from "../../../../helpers/functions";
import { toastify } from "../../../tostify/snackbarAlert";
import LanguageContext from "../../../../storage/LanguageContext";

interface VideoProps {
    children?: React.ReactNode;
    identifier: string,
    config: {
        numberOfReels: number,
        reelsMaximumDuration: number,
    }
    maxLength?: number,
    error?: string,
    setError?: (error) => void
    onChangeMedia?: (state: StateDataType) => void
}

const DEFAULT_STATE = {
    medias: []
}

let player: any
const Video = (props: VideoProps): JSX.Element => {
    const {maxLength} = props
    const identifier = props.identifier
    const {language} = useContext(LanguageContext);
    
    const reducer = (oldState: StateDataType, action: MediaAction): StateDataType => {
        const {type, payload} = action


        switch (type) {
            case MediaActionKind.ADD_MEDIA:
                const data: MediaList = {}
                for (const media of payload.medias) {
                    data[media.hash] = {
                        url: media.url,
                        uri: media.url,
                        progress: 0,
                        isLoaded: false
                    }
                }


                return {
                    ...oldState,
                    medias: {
                        ...oldState.medias,
                        ...data,
                    },
                }
            case MediaActionKind.UPDATE_MEDIA:

                return {
                    ...oldState,
                    medias: {
                        ...oldState.medias,
                        [`${payload.fileHash}`]: {
                            ...oldState.medias[`${payload.fileHash}`],
                            ...payload.data
                        }
                    }
                }
            case MediaActionKind.UPDATE_MEDIAS:
                const updatedMedias: MediaList = {}
                for (const hash in payload.medias) {
                    updatedMedias[hash] = {
                        ...oldState.medias[hash],
                        ...payload.medias[hash]
                    }
                }
                return {
                    ...oldState,
                    medias: {
                        ...oldState.medias,
                        ...updatedMedias
                    }
                }
            case MediaActionKind.CHANGE_MEDIA:

                delete oldState.medias[payload.oldFileHash]

                return {
                    ...oldState,
                    medias: {
                        ...oldState.medias,
                        [`${payload.new.fileHash}`]: {
                            url: payload.new.fileUrl,
                            progress: 0,
                            isLoaded: false,
                        },
                    }
                }
            case MediaActionKind.DELETE_MEDIA:
                const newState = {...oldState}

                delete newState.medias[payload.fileHash]


                if (props.onChangeMedia)
                    props.onChangeMedia(newState)

                return {
                    ...newState,
                }
            case MediaActionKind.UPDATE_PROGRESS:
                return {
                    ...oldState,
                    medias: {
                        ...oldState.medias,
                        [`${payload.fileHash}`]: {
                            ...oldState.medias[`${payload.fileHash}`],
                            progress: payload.progress
                        }
                    },
                }
            case MediaActionKind.UPDATE_IS_LOADED:
                return {
                    ...oldState,
                    medias: {
                        ...oldState.medias,
                        [`${payload.fileHash}`]: {
                            ...oldState.medias[`${payload.fileHash}`],
                            isLoaded: payload.isLoaded,
                        }
                    },
                }
            case MediaActionKind.DRAFTING:
                let newData: StateDataType = {
                    ...oldState,
                }

                if (payload.medias) {
                    for (const hash in payload.medias) {
                        newData.medias[hash] = {
                            ...oldState.medias[hash],
                            ...payload.medias[hash],
                            isLoaded: true,
                            progress: 100
                        }
                    }
                    delete payload.medias
                }


                newData = {
                    ...newData,
                    ...payload
                }


                if (props.onChangeMedia)
                    props.onChangeMedia(newData)

                return {
                    ...newData,
                }
            case MediaActionKind.FORCE_CHANGE:
                return {
                    ...payload
                }
            default:
                return {
                    ...oldState
                }
        }
    }


    const postCtx = useContext(PostContext)
    const [state, dispatch] = useReducer(reducer, MediasCacher.getInstance().get(identifier) || DEFAULT_STATE)
    const {setValidationError, clearValidationError, error} = useValidationNew()

    useEffect(() => {
        if (!props.error)
            return


        setValidationError(props.error)
    }, [props.error])

    const loadVideo = (file): Promise<DynamicObject> => {
        return new Promise((resolve, reject) => {
            var video = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = function () {
                window.URL.revokeObjectURL(video.src);
                let duration = video.duration;
                resolve({
                    duration
                })
            }

            video.src = URL.createObjectURL(file);
        })
    }

    const checkIsValid = (file: File): Promise<boolean> => {

        return new Promise((resolve, reject) => {
            if (!file.type.match("video.*")) {
                setValidationError(t("validation__file-isn't-not-video"))
                return false
            }
            loadVideo(file).then(function (data) {
                if (data.duration > props.config.reelsMaximumDuration) {
                    setValidationError(t("validation__more_than_max_duration_video").toString().format(props.config.reelsMaximumDuration))
                    resolve(false)
                    return
                }
                resolve(true)

            }).catch(function () {
                resolve(false)
            })
        })


    }


    const getHashById = (id: string): string => {
        for (const hash in state.medias) {
            const media = state.medias[hash]
            if (media.id == id) {
                return hash
            }
        }
        return ''
    }

    function saveToDraft(draftData: DraftDataDataType) {
        const {uris} = draftData

        const data: DynamicObject = {
            [`${identifier}`]: {
                uri: uris,
            },
            identifier: identifier,
            draftId: postCtx.data.draft_id,
            workflowId: postCtx.data.workflow_id,
        }


        return uploadMediaApiDraft({
            draftId: postCtx.data.draft_id,
            data,
            bucket: 8,
            extraParams: {
                isVideo: 1
            }
        })
    }

    const uploadFileRequest = (mediaFiles: FileDataType[]) => {
        const promises: Promise<any>[] = []

        for (const mediaFile of mediaFiles) {

            const promise = () => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsArrayBuffer(mediaFile.file)


                reader.onload = (e) =>
                    uploadMediaApi({
                        onUploadProgress: (progress: number) => {
                            dispatch({
                                type: MediaActionKind.UPDATE_PROGRESS,
                                payload: {
                                    fileHash: mediaFile.hash,
                                    progress
                                }
                            })
                        },
                        onDownloadProgress: (progress: number) => {
                            dispatch({
                                type: MediaActionKind.UPDATE_PROGRESS,
                                payload: {
                                    fileHash: mediaFile.hash,
                                    progress
                                }
                            })


                        },
                        data: e?.target?.result,
                        media: {
                            hash: mediaFile.hash,
                            identifier,
                        },
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }
                    })
                        .then(response => {
                            const data: DraftDataDataType = {
                                uris: [response.data.uri],
                            }
                            return saveToDraft(data)
                        })
                        .then((responseDraft) => {
                            const medias: DynamicObject = {}
                            const mediaCaches: DynamicObject = {}
                            for (const uri in responseDraft.data.result.data[identifier]) {
                                const postMedia = responseDraft.data.result.data[identifier][uri]
                                const hash = mediaFile.hash
                                medias[hash] = {
                                    id: postMedia.id,
                                    uri: uri,
                                    isLoaded: true,
                                    progress: 100
                                }

                                mediaCaches[hash] = {
                                    ...MediasCacher.getInstance().get(identifier)?.medias[hash],
                                    ...medias[hash]
                                }
                            }


                            dispatch({
                                type: MediaActionKind.DRAFTING,
                                payload: {
                                    medias
                                }
                            })

                            MediasCacher.getInstance().update(identifier, {
                                ...MediasCacher.getInstance().get(identifier),
                                medias: {
                                    ...MediasCacher.getInstance().get(identifier)?.medias,
                                    ...mediaCaches
                                }
                            })


                        }).catch((err) => console.log("error", err))

            })
            promises.push(promise as any)
        }


        return promises;

    }

    const addMedia = (files: FileList | null): Promise<boolean> => {
        return new Promise(async (resolve, reject) => {
            if (!files?.length)
                return

            const mediaNumber = Object.values(state.medias).length + files.length

            if (mediaNumber > maxLength) {
                setValidationError(t("validation__more_than_max_length_videos").toString().format(maxLength))
                return;
            }


            const mediaFiles: FileDataType[] = []
            const mediaCaches: DynamicObject = {}

            for (let index = 0; index < files.length; index++) {
                const file = files[index]
                if(file.type == 'video/quicktime'){
                    toastify({toastType: "error" ,description : t('invalidVideoFormat'), locale : language});
                    return;
                }
                const hash = generateFileHash(file)

                if (state.medias[hash])
                    continue

                if (!await checkIsValid(file))
                    return;

                const url = URL.createObjectURL(file)

                mediaCaches[hash] = {
                    url,
                    uri: url,
                    progress: 0,
                    isLoaded: false
                }

                mediaFiles.push({
                    file,
                    hash,
                    url: url
                })
            }


            MediasCacher.getInstance().update(identifier, {
                ...MediasCacher.getInstance().get(identifier),
                medias: {
                    ...mediaCaches,
                    ...MediasCacher.getInstance().get(identifier)?.medias,
                },
            })


            dispatch({
                type: MediaActionKind.ADD_MEDIA,
                payload: {
                    medias: mediaFiles,
                }
            })

            clearValidationError()

            const promises = uploadFileRequest(mediaFiles)

            Promise.all(promises.map(async promise => (await promise)()))
                .then(result => {
                })
        });

    }

    const deleteMedia = (mediaHash: string) => {
        const mediaObject = state.medias[mediaHash]
        return deleteMediaApi({
            mediaId: mediaObject.id
        })
    }

    const onDeleteMedia = (mediaId: string) => {
        const mediaHash = getHashById(mediaId)
        if (!mediaHash)
            return

        deleteMedia(mediaHash)
            .then(() => {
                dispatch({
                    type: MediaActionKind.DELETE_MEDIA,
                    payload: {
                        fileHash: mediaHash
                    }
                })

                const mediaObj = {
                    ...MediasCacher.getInstance().get(identifier)
                }

                delete mediaObj.medias[mediaHash]

                MediasCacher.getInstance().update(identifier, {
                    ...mediaObj
                })

            })
    }

    useEffect(() => {
        const eventHandler = (e: CustomEvent) => {
            const data = e.detail
            dispatch({
                type: MediaActionKind.FORCE_CHANGE,
                payload: data.medias[identifier]
            })
        }

        parentCallBack('with_confirm_dialog')

        window.addEventListener(`update-media-${identifier}`, eventHandler)
        MediasCacher.getInstance().initialMedia(identifier)
        return () => {
            window.removeEventListener(`update-media-${identifier}`, eventHandler)
        }
    }, [])

    return (
        <div>
            <div></div>
            <VideoStyle state={state}
                        addMedia={addMedia}
                        onDeleteMedia={onDeleteMedia} maxLength={1}
                        error={error}
            />
        </div>

    )
}

export default Video