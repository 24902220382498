import React, {useContext, useEffect, useState} from "react";
import {Props} from "./interface";
import {useHttp} from "../../../../hooks";
import Modal from "../../../UI/Modal/Modal";
import styles from './TextLabelWithAction.module.css';
import {AxiosError, AxiosResponse} from "axios/index";
import UIContext from "../../../../storage/UIContext";
import PostContext from "../../../../storage/PostContext";
import {Args} from "../../../../hooks/use-http/interfaces";
import DynamicObject from "../../../../models/dynamic-object";
import NextButtonModal from "../../NextButton/NextButtonModal";
import {getSizes, mustache} from "../../../../helpers/functions";
import languageContext from "../../../../storage/LanguageContext";
import MapperTextLabelWithAction from "../../../MapperTextLabelWithAction";
import {configSaveApi, configWidgetApi} from "../../../../config/api.config";
import MediasCacher from "../../../../models/medias-cacher";
import SectionCacher from "../../../../models/section-cacher";
import {getDataStorage} from "../../../../helpers/storage.helper";


const TextLabelWithAction = (props: Props) => {


    /************************************
     *           CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)
    const langCtx = useContext(languageContext)
    const storage = getDataStorage()


    /************************************
     *           STATES
     * *********************************/
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [currentConfigApi, setCurrentConfigApi] = useState<DynamicObject>({})
    const [btnLoader, setBtnLoader] = useState<boolean>(false)

    /************************************
     *           HOOKS
     * *********************************/
    const widgetApi = useHttp<DynamicObject>(configWidgetApi)


    /************************************
     *           CONSTANTS
     * *********************************/
    const request = widgetApi.request
    const imagePathData = {
        "platform": "ios",
        "size": "1x"
    };

    /************************************
     * PREPARE API REQUEST TO GET WIDGET
     * *********************************/
    widgetApi.request = (args?: Args) => {
        return request({
            ...args,
            config: {
                ...args?.config,
                language: langCtx.language,
                params: {
                    id: props.config.identifier,
                    type: 'cv',
                    workflowId: postCtx.data.workflow_id,
                    draftId: postCtx.data.draft_id,
                    stepId: postCtx.data.step?.identifier
                }
            }
        })
    }

    /************************************
     * PREPARE API REQUEST FOR SAVE DATA
     * *********************************/
    const saveDataHttp = useHttp<DynamicObject>(configSaveApi)

    const requestSave = saveDataHttp.request

    saveDataHttp.request = (args) => {
        // delete postCtx.data.form['experienceSection']
        const data = {
            ...postCtx.data.form,
            ...args?.config?.data,
        }
        requestSave({
            ...args,
            config: {
                ...args?.config,
                draftId: postCtx.data.draft_id,
                workflow_id: postCtx.data.workflow_id,
                time_spent_ms: Date.now() - postCtx.data.start_time,
                step: props.config.identifier,
                data: data
            },
            callbacks: {
                ...args?.callbacks,
            }
        })
    }

    /************************************
     *         INLINE STYLE
     * *********************************/
    const containerStyle = {
        "--background-color": props.config.text.locale ? "#fff" : "#f3f8fe",
        "--border-color": props.config.text.locale ? "#a7d1ff" : "#fff"
    } as React.CSSProperties


    /************************************
     *        INLINE TEXT STYLE
     * *********************************/
    const textStyle = {
        fontWeight: props.config.text?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.text?.style?.size),
        color: props.config.text.color,
        textAlign: props.config.text?.align,
    } as React.CSSProperties


    /*---------------------------------------------------------------
     *                           FUNCTIONS
     * --------------------------------------------------------------*/
    const onSuccessWidgetApi = (response: AxiosResponse) => {
        setCurrentConfigApi(response.data)
        setIsOpen(true)
        setBtnLoader(false)
    }

    const onErrorWidgetApi = (response: AxiosError) => {
        setBtnLoader(true)
    }

    /************************************
     *       ACTION HANDLER
     * *********************************/
    const onOpenHandler = () => {
        // uiCtx.setLoading(true)
        widgetApi.request({
            callbacks: {
                success: onSuccessWidgetApi,
                error: onErrorWidgetApi
            }
        })
    }




    /*---------------------------------------------------------------*/
    useEffect(() => {
        SectionCacher.getInstance().update(props.config.identifier,onOpenHandler)
    }, [])

    /*---------------------------------------------------------------*/


    return (
        <div className={`${styles.container}`} style={containerStyle}>

            {
                isOpen && currentConfigApi &&
                <Modal title={currentConfigApi.title} setIsOpen={setIsOpen} footer={<>
                {currentConfigApi.submit &&
                    <div className={styles.footer}><div className={`d-flex justify-content-center align-items-center w-100`}>
                        <NextButtonModal
                            style={{
                                bold: currentConfigApi?.submit?.style?.bold ?? 'bold',
                                size: currentConfigApi?.submit?.style?.size ?? 'medium',
                                color: currentConfigApi?.submit?.color ?? 'white',
                            }}
                            nextApi={saveDataHttp}
                        >{currentConfigApi?.submit?.locale ? currentConfigApi?.submit?.locale : "Next"}</NextButtonModal>
                    </div>
                    </div>}

                </>}>
                    <MapperTextLabelWithAction saveHttp={saveDataHttp} config={currentConfigApi}
                                               setConfigData={props.setConfigData}/>
                </Modal>
            }

            <div className={`row ${styles.row} cursor__pointer`}
                 onClick={(e: React.MouseEvent<HTMLElement>) => onOpenHandler()}>

                {props.config.startIcon &&
                    <div className={`col-md-2 col-2 ${styles.first_icon_container}`}>
                        <img style={{height:"100%",width:"100%"}} src={mustache(props.config.startIcon, imagePathData)}/>
                    </div>
                }
                <div className={`col-md-10 ${storage.isMobile ? "col-8" : "col-10"}`}
                     style={{padding: !props.config.startIcon ? "" : "0"}}>
                    <p className={`${styles.text_overflow} m-0`} style={textStyle}>{props.config.text.locale}</p>
                </div>
                {props.config.endIcon &&
                    <div className={`col-md-2 ${storage.isMobile ? "col-4" : "col-2"} ${styles.icon}`}>
                        <img src={mustache(props.config.endIcon, imagePathData)}/>
                    </div>
                }

            </div>
        </div>
    )
}

export default TextLabelWithAction