import React from "react";
import {Media} from "../../../UI/MediaUpload/MediaUpload.interfaces";

export enum MediaActionKind {
    UPDATE_STATE = "UPDATE_STATE",
    ADD_MEDIA = "ADD_MEDIA",
    UPDATE_MEDIA = "UPDATE_MEDIA",
    UPDATE_MEDIAS = "UPDATE_MEDIAS",
    UPDATE_PROGRESS = "UPDATE_PROGRESS",
    UPDATE_IS_LOADED = "UPDATE_IS_LOADED",
    DELETE_MEDIA = "DELETE_MEDIA",
    CHANGE_MEDIA = "CHANGE_MEDIA",
    CHANGE_MAIN_MEDIA = "CHANGE_MAIN_MEDIA",
    FORCE_CHANGE = "FORCE_CHANGE",
    DRAFTING = "DRAFTING",
}

export type Video = {
    url: string,
    uri?: string,
    progress: number,
    isLoaded: boolean,
    id?: string
}


export type StateDataType = {
    medias: MediaList
}

export type MediaList = {
    [key: string]: Video
}

export interface MediaAction {
    type: MediaActionKind;
    payload: any;
}

export interface VideoComponentProps {
    mediaKey: string,
    index: number,
    isMain?: boolean,
    state: StateDataType,
    onClickMenuItemHandler,
    onClickBoxHandler,
    deleteImageHandler,
    onClickChangeMediaHandler
}

export interface EditorMenuProps {
    mediaKey: string,
    isMain: boolean,
    getHashById: (mediaId: string) => string,
    dispatch: React.Dispatch<MediaAction>,
    state: StateDataType
}

export interface FileDataType {
    file: File,
    url: string,
    hash: string
}

export type DraftDataDataType = {
    uris: string[],
    mainUri?: string
}