import React, {useContext} from "react";
import {Button} from "@mui/material";
import {Props, Starting} from "./interface";
import {useTranslation} from "react-i18next";
import styles from './CityFlowField.module.css'
import PostContext from "../../../../storage/PostContext";
import DynamicObject from "../../../../models/dynamic-object";
import useSearch from "../../../../hooks/use-search/use-search";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import languageContext from "../../../../storage/LanguageContext";
import {degreesToRadians, getCurrentLocation, getImgPath, getSizes} from "../../../../helpers/functions";

const CityFlowField = (props: Props) => {

    const {config, nextApi} = props


    /************************************
     *             CONTEXT
     * *********************************/
    const langCtx = useContext(languageContext)

    /************************************
     *           TRANSLATION
     * *********************************/
    const {t} = useTranslation();

    const postCtx = useContext(PostContext)

    /************************************
     *     INLINE CELL TITLE STYLE
     * *********************************/
    const titleStyle = {
        fontWeight: config.cell.title.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.cell.title.style?.size),
        color: config.cell.title.color,
        direction:langCtx.language=='ar'?'ltr':'',
        textAlign:langCtx.language=='ar'?"end":''
    } as React.CSSProperties


    /*-------------------------------------
    *              HOOKS
    * ----------------------------------*/

    /************************************
     *      CUSTOME SEARCH HOOK
     * *********************************/
    const {filteredResults, Search} = useSearch({
        items: config.values,
        search: {
            placeholder: config?.placeholder?.locale
        }
    })

    /*-------------------------------------
     *             FUNCTIONS
     * ----------------------------------*/

    /************************************
     * ADD TO CONTEXT + REQUEST NEXT API
     * *********************************/
    const addItemHandler = (id: number | string) => {
        postCtx.addToForm(config.identifier, id)

        const httpConfig: DynamicObject = {
            config: {
                data: {}
            }
        }

        httpConfig["config"]["data"][config.identifier] = id

        nextApi?.request(httpConfig)
    }

    /************************************
     *        CALCULATE DISTANCE
     * @use between current positions and
     *      exist cities from  the API
     * *********************************/
    const calcCoordsDistance = (starting: Starting, destination: GeolocationPosition): number => {
        const startingLat = degreesToRadians(starting.lat);
        const startingLong = degreesToRadians(starting.lng);
        const destinationLat = degreesToRadians(destination.coords.latitude);
        const destinationLong = degreesToRadians(destination.coords.longitude);

        // Radius of the Earth in kilometers
        const radius: number = 6571;

        // Haversine equation
        const distanceInKilometers: number = Math.acos(Math.sin(startingLat) * Math.sin(destinationLat) +
            Math.cos(startingLat) * Math.cos(destinationLat) *
            Math.cos(startingLong - destinationLong)) * radius;

        return Math.floor(distanceInKilometers * 100) / 100;
    }

    /************************************
     *      GET CURRENT LOCATION
     * @USE If around me exist, it gets
     * the nearest to the current location
     * *********************************/
    const getClosestCountry = () => {
        getCurrentLocation().then((positions: GeolocationPosition) => {
            let closest: Starting = {
                lat: config.values[0].lat,
                lng: config.values[0].lng
            }
            let id = "";
            let closest_distance = calcCoordsDistance(closest, positions);
            for (const key in config.values) {
                const value = config.values[key]
                const coordinates = {
                    lat: value.lat,
                    lng: value.lng
                }
                if (calcCoordsDistance(coordinates, positions) < closest_distance) {
                    closest_distance = calcCoordsDistance(coordinates, positions);
                    closest = coordinates
                    id = value.id;
                }
            }

            addItemHandler(id)
        })

    }


    return (
        <div className={`${styles.container}`}>
            {/* SEARCH FIELD*/}
            <Search></Search>
            {/* AROUND ME , CURRENT LOCATION*/}
            {config?.aroundMe && <div className={`${styles.box} shadow`}>
                <div className={`${styles.btn_header}`}>
                    <h1>
                        {t('aroundMe')}
                    </h1>
                </div>
                <div className='row'>
                    <div className='col-md-12 mb-2 '>
                        <Button onClick={getClosestCountry} variant="outlined" color="inherit" fullWidth={true}>
                            {t('useCurrentLocation')}
                        </Button>
                    </div>
                </div>
            </div>
            }
            {/* LIST CELL OPTIONS*/}
            <div className={styles.items_container}>
                {
                    filteredResults.length < 1 && (
                        <div className={`row ${styles.no_found_data}`}>
                            <h3>
                                {t('noResults')}
                            </h3>
                        </div>
                    )
                }
                {
                    filteredResults.map(item => {
                            return (
                                <div key={item.id} className={`row ${styles.item}`}
                                     onClick={(e: React.MouseEvent<HTMLElement>) => addItemHandler(item.id)}>
                                    <div className={`col-md-9 col-10 ${styles.image_text}`}>
                                        {/*ITEM ICON*/}
                                        {

                                            item.icon && (
                                                <div className={styles.image}>
                                                    <img src={getImgPath(item.cell_icon)}/>
                                                </div>
                                            )
                                        }


                                        {/*ITEM LABEL*/}
                                        <div className={styles.text}>
                                            <h1 style={titleStyle}>{item.label}</h1>
                                        </div>
                                    </div>
                                    {/*ITEM ARROW */}
                                    <div className={`col-md-3 col-2 ${styles.icon}`}>
                                        {(langCtx.language == 'en') ?
                                            <IoIosArrowForward style={{height: "21.2px", width: "22.85px"}}/> :
                                            <IoIosArrowBack style={{height: "21.2px", width: "22.85px"}}/>}
                                    </div>
                                </div>


                            )
                        }
                    )
                }
            </div>

        </div>)
}

export default CityFlowField