import React, { useCallback, useContext, useEffect, useState } from "react";
import UIContext from "../../../storage/UIContext";
import { ComponentLoader } from "../../UI";
import { VasServices } from "opensooq-services";
import "opensooq-services/dist/esm/Bootstrap.css";
import { generateAuthToken } from "../../../helpers/auth.helper";
import useVasStore from "../../../storage/VasContext";
import { useSearchParams } from "react-router-dom";
import { ActionDimensions } from "../../../logging/dimensions/ActionDimensions";
import { toastify } from "../../tostify/snackbarAlert";
import { getDataStorage } from "../../../helpers/storage.helper";

const PromoteFlow = (): JSX.Element => {
  const uiCtx = useContext(UIContext);
  const [token, setToken] = useState<string>("");

  const getToken = () => {
    return generateAuthToken();
  };

  const {
    setIsPromoteFlow,
    setEnableBackButton,
    trigger,
    setTrigger,
    setPromoteFlowCloseText,
    setPromoteFlowTitle,
  } = useVasStore();

  const handleEnableBackButton = useCallback((show: boolean) => {
    setEnableBackButton(show);
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let flow = searchParams.get("flow");
    if (flow) {
      setIsPromoteFlow(true);
      sessionStorage.setItem("flowType", flow);
    } else {
      setIsPromoteFlow(false);
      flow = sessionStorage.getItem("flowType");
      sessionStorage.removeItem("flowType");
    }
    getToken().then((token) => {
      setToken(token ?? "");
    });
  }, []);

  type Toast = {
    toastType: "error" | "success" | "info" | "warning";
    locale: string;
    description: string;
    autoClose?: number;
  };

  const handleToast = ({
    autoClose,
    toastType,
    locale,
    description,
  }: Toast) => {
    toastify({ autoClose, toastType, locale, description });
  };
  const storge = getDataStorage()
  return (
    <div className={`${storge.isMobile ? "my-2" : "my-4"}`}>
      {uiCtx.isLoading && <ComponentLoader />}
      {
        <VasServices
          token={token}
          handleEnableBackButton={handleEnableBackButton}
          trigger={trigger}
          setTrigger={setTrigger}
          setPromoteFlowCloseText={setPromoteFlowCloseText}
          setPromoteFlowTitle={setPromoteFlowTitle}
          loggingConfig={{
            version: `${process.env.REACT_APP_PUBLIC_VERSION}`,
            loggingEnv: {
              url: process.env.REACT_APP_NEXT_PUBLIC_LOGGING_URL_CLIENT,
              token: '',
            },
            sessionFunnelSource: ActionDimensions.getInstance().currScreen,
            sessionFunnelPointOfEntry: ActionDimensions.getInstance().sessionFunnelPointOfEntry
          }}
          handleToast={handleToast}
        />
      }
    </div>
  );
};

export default PromoteFlow;
