import { create } from "zustand";
import DynamicObject from "../models/dynamic-object";

type State = {
  isSubmitUpdated: boolean;
  submitObj: DynamicObject
};

type Actions = {
  setIsSubmitUpdated: (isSubmitUpdated: boolean) => void;
  setSubmitObj: (submitObj: DynamicObject) => void;
} 

const useSubmitContext = create<State & Actions>((set) => ({
    isSubmitUpdated: false,
    submitObj: null,
    setIsSubmitUpdated: (isSubmitUpdated) => set({ isSubmitUpdated }),
    setSubmitObj: (submitObj) => set({ submitObj }),
}));

export default useSubmitContext;
