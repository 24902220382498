const defaultOptions = {
    expires: process.env.REACT_APP_DEFAULT_COOKIES_EXPIRED_IN_MINUTES,
    path: "/",
    ...(typeof window !== "undefined"
      ? window.location.hostname.includes("localhost")
        ? { domain: window.location.hostname }
        : {
            domain:
              "." +
              window.location.hostname.split(".").reverse()[1] +
              "." +
              window.location.hostname.split(".").reverse()[0],
          }
      : {}),
  };

const jsonSafeGet = <T>(jsonString : any, defaultData = {}): T => {
    let output = defaultData as T;
    try {
        output = JSON.parse(jsonString);
    } catch (e) {
        // do nothing.
    }
    return output;
};
  
const setCookie = (name : string , value : string, options : any)  => {
    // Default values for options:
    let { expires = "" , path = "/", domain = "" } = options;
  
    // If expires is a number, convert it to minutes and create a Date object:
    if (typeof expires === "number") {
      const minutes = expires;
      const now = new Date();
      now.setTime(now.getTime() + minutes * 60 * 1000);
      expires = now;
    }
  
    // Construct the cookie string:
    let cookieString = `${name}=${value}`;
  
    if (expires instanceof Date) {
      cookieString += `;expires=${expires.toUTCString()}`;
    }
  
    if (path) {
      cookieString += `;path=${path}`;
    }
  
    if (domain) {
      cookieString += `;domain=${domain}`;
    }
  
    // Set the cookie:
    document.cookie = cookieString;
}


export {defaultOptions, setCookie, jsonSafeGet}