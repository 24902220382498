import React, {FC, Ref, RefObject, useContext, useEffect, useImperativeHandle, useState} from "react";
import stepModule from '../Step.module.css'
import NextButton from "../../NextButton/NextButton";
import DynamicObject from "../../../../models/dynamic-object";
import {NormalStep as NormalStepModel} from '../../../../models'
import {UseHttpResponse} from "../../../../hooks/use-http/interfaces";
import RefType from "../../../../models/ref-type";
import {FaAngleLeft} from "react-icons/fa";
import styles from './../Step.module.css'

import Step from "../Step";
import {useTranslation} from "react-i18next";
import {getImgPath, getSizes} from "../../../../helpers/functions";
import UIContext from "../../../../storage/UIContext";
import {getDataStorage} from "../../../../helpers/storage.helper";
import LanguageContext from "../../../../storage/LanguageContext";
import contentContainer from "../../../Containers/ContentContainer/ContentContainer";
import {Simulate} from "react-dom/test-utils";
import submit = Simulate.submit;

interface Props {
    children?: React.ReactNode;
    nextApi?: UseHttpResponse<DynamicObject>;
    config: NormalStepModel,
}

interface RefDataType extends RefType<DynamicObject> {
}


const NormalStep = React.forwardRef<RefDataType, Props>((props, ref): JSX.Element => {
    const {config, nextApi} = props
    const {t} = useTranslation();
    const storage = getDataStorage()
    const langCtx=useContext(LanguageContext)
    const containerWidth=document.getElementById('content-container')
    const [screenWidth, setScreenWidth] = useState(containerWidth?.clientWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(containerWidth?.clientWidth);
        };

        //scroll to of the page in normal step
        const body = document.querySelector('#root');
        body.scrollIntoView({
            behavior: 'smooth'
        })

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array to run the effect only once


    return (
        <Step config={config} api={nextApi}>
            {!storage.isMobile && config.title && <div style={{padding: "0px 14px"}}>
                <p style={{
                    fontWeight: config.title.style?.bold ? "bold" : "normal",
                    fontSize: getSizes(config.title.style?.size),
                    color: config.title.color,
                }}>{config.title.locale}</p>
            </div>}

            <div style={{marginBottom:(storage.isMobile && config?.submit?.sticky) && '70px'}} className={stepModule.content}>{props.children}</div>
            {
                config.submit && (
                    <NextButton
                        style={{
                            bold: config.submit?.style?.bold,
                            size: config.submit?.style?.size ?? 'medium',
                            color: config.submit?.color ?? 'white',
                            backgroundColor: config.submit?.background.color ?? 'white',
                            width: storage.isMobile ? `${screenWidth-10}px` : "400px"
                        }}
                        isSticky={config.submit?.sticky}
                        nextApi={nextApi}
                    >
                        {props.config.submit.icon ? <div className={`d-flex justify-content-center`}>
                            <div
                                className={`col-7  d-flex justify-content-end align-items-center`}> {config.submit.locale ? config.submit.locale : t('next')}</div>

                            <div className={`col-5 d-flex justify-content-end`}><img  style={{width: "30px", height: "30px",transform:langCtx.language=='ar' && 'scaleX(-1)'}}
                                                                                      src={getImgPath(props.config.submit.icon)}/>
                            </div>
                        </div> : config.submit.locale ? config.submit.locale : t('next')}

                    </NextButton>
                )
            }
        </Step>
    )
})

export default NormalStep