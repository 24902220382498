import React from "react";
import styles from "./Separator.module.css";
import {Props,RefDataType} from "./interface";
import {getSizes} from "../../../../helpers/functions";

const Separator = React.forwardRef<RefDataType, Props>((props, ref) => {

    /************************************
     *     INLINE TEXT STYLE
     * *********************************/
    const textStyle = {
        fontWeight: props.config?.text?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config?.text?.style?.size),
        color: props.config?.text?.color,

    } as React.CSSProperties

    /************************************
     *     INLINE LINE STYLE
     * *********************************/
    const lineStyle = {
        borderTop:`1px ${props.config.line?.type?props.config?.line?.type:'solid'} ${props.config?.line?.color}`,
    } as React.CSSProperties

    return (
        <div >
            {props.config?.text?.enabled ? <div className={`${styles.container}`}>
                    <div className={`${styles.line}`} style={lineStyle}></div>
                    <div className={`${styles.text}`} style={textStyle}>{props.config?.text?.locale}</div>
                    <div className={`${styles.line}`} style={lineStyle}></div>
                </div> :
                <div style={lineStyle}/>


            }
        </div>
    )
})

export default Separator