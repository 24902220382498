import React, {useContext, useEffect, useImperativeHandle, useState} from "react";
import styles from './ListTagsOptions.module.css'
import {Props, RefDataType} from "./interface";
import PostContext from "../../../../storage/PostContext";
import {getSizes} from "../../../../helpers/functions";

const ListTagsOptions = React.forwardRef<RefDataType, Props>((props, ref) => {

    /************************************
     *             CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)

    /************************************
     *            CONSTANTS
     * *********************************/
    const ids = props.config.values.map(item => item.id);
    const defaultValue = ids.includes(postCtx.data.form[props.config.identifier]) ? postCtx.data.form[props.config.identifier].toString() : props.config.values[0].id.toString()

    /************************************
     *             STATES
     * *********************************/
    const [value, setValue] = useState<string>(defaultValue)

    const labelStyle = {
        fontWeight: props.config.cell.label.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.cell.label.style?.size),
        color: props.config.cell.label.color,
    } as React.CSSProperties



    /*----------------------------------------------------------------
     *                            FUNCTIONS
     * ---------------------------------------------------------------*/
    /************************************
     *        ACTION HANDLER
     * *********************************/
    const addItemHandler = (id: number) => {
        setValue(id.toString());
    }
    /*---------------------------------------------------------------*/

    /************************************
     *   GET INNER STATES USING REFS
     * *********************************/
    useImperativeHandle<RefDataType, RefDataType>(ref, () => {
        return {
            getState: (): string => value
        }
    })
    /************************************
     *       SET INITIAL VALUE
     * *********************************/
    // useEffect(function () {
    //     if (props.config?.values.length > 0 && !value) {
    //         addItemHandler(parseInt(props.config?.values[0]?.id) as number)
    //     }
    // }, [props.config?.values])

    return (
        <div
            className={`${styles.container}`}>
            <div className={`${styles.tags_row}`}>
                {props.config?.values.map((item, index) => {
                    return (
                        <button style={labelStyle} key={index} name={item.label} onClick={(e) => addItemHandler(parseInt(item.id))}
                                className={parseInt(item.id) == parseInt(value) ? `${styles.customButton} ${styles.active}` : `${styles.customButton}`}>
                            {item.label}
                        </button>
                    )
                })}

            </div>
        </div>
    )
})

export default ListTagsOptions


