import React, {CSSProperties, useContext} from "react";
import {Props, RefDataType} from './interfaces';
import {FormControl} from "@mui/material";
import styles from './RedirectSelectBtn.module.css'
import {getFinalPayload, getImgPath, getSizes} from "../../../../helpers/functions";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import LanguageContext from "../../../../storage/LanguageContext";
import redirectApi from "../../../../api/redirect.api";
import PostContext from "../../../../storage/PostContext";
import {MediaMapper} from "../../../../helpers/media.functions";
import UIContext from "../../../../storage/UIContext";
import {EmptyDraftPicture} from "../../../../assets/images";
import MediasCacher from "../../../../models/medias-cacher";

const RedirectSelectBtn = React.forwardRef<RefDataType, Props>((props, ref) => {
    const {config} = props
    const langCtx = useContext(LanguageContext)
    const postCtx = useContext(PostContext);
    const uiCtx = useContext(UIContext)


    {/*TITLE STYLING*/
    }
    const titleStyle = {
        color: config.title.color,
        fontSize: getSizes(config.title.style?.size),
        fontWeight: config.title.style?.bold ? "bold" : "normal",
    }

    {/*SUB TITLE STYLING*/
    }
    const subTitleStyle = {
        color: config.subTitle.color,
        fontSize: getSizes(config.subTitle.style?.size),
        fontWeight: config.subTitle.style?.bold ? "bold" : "normal",
    }

    const ContentStyle: CSSProperties = {}

    // if (!config?.imagePath) {
    //     ContentStyle.width = "100%"
    // }

    return (
        <div className={`${styles.container}}`}>
            <FormControl fullWidth>
                <div className={`${styles.btn}`}
                     onClick={(e: React.MouseEvent<HTMLElement>) => {
                         uiCtx.setLoading(true)

                         redirectApi({
                             data: {
                                 draftId: postCtx.data.draft_id,
                                 time_spent_ms: Date.now() - postCtx.data.start_time,
                                 workflowId: postCtx.data.workflow_id,
                                 currentStep: postCtx?.data?.step?.identifier,
                                 redirect_id: config.identifier,
                                 categoriesMain: postCtx.data.form.categoriesMain
                             },
                             lang: langCtx.language

                         }).then(response => {

                             const stepData = {
                                 workflow_id: response.data.workflow_id,
                                 draft_id: response.data.draft.id,
                                 step: response.data.step,
                                 start_time: Date.now(),
                                 previous: response.data.previous,
                             }


                             postCtx.updateStep({...stepData})

                             const payload = getFinalPayload(response.data.draft.payload)

                             if (response.data.draft.id !== postCtx.data.draft_id) {
                                 MediasCacher.getInstance().clear()
                             }
                             const mediaResult = MediaMapper(response.data.media)
                             MediasCacher.getInstance().concat(mediaResult)
                             postCtx.updateData({
                                 form: {...payload},
                                 media: mediaResult
                             })

                             uiCtx.setLoading(false)
                         })
                     }}
                >

                    {config?.imagePath ? <div className={`${styles.image_container}`}>
                        <img style={{height: "100%", width: "100%", objectFit: "cover", borderRadius: "10px"}}
                             src={getImgPath(config?.imagePath)}/>
                    </div> : <div className={`${styles.image_container}`}>
                        <img style={{height: "100%", width: "100%", objectFit: "cover", borderRadius: "10px"}}
                             src={EmptyDraftPicture}/>
                    </div>}
                    <div className={`${styles.content}`} style={ContentStyle}>
                        <div className={styles.text}>
                            <div style={titleStyle}>{config.title.locale}</div>
                            <p style={subTitleStyle}>{config.subTitle.locale}</p>
                        </div>
                        <div className={`${styles.icon}`}>
                            {(langCtx.language == 'en') ?
                                <IoIosArrowForward style={{height: "21.2px", width: "22.85px"}}/> :
                                <IoIosArrowBack style={{height: "21.2px", width: "22.85px"}}/>}
                        </div>

                    </div>

                </div>
            </FormControl>
        </div>
    )
})

export default RedirectSelectBtn