import styles from './ComponentLoader.module.css'
import {CircularProgress} from "@mui/material";
import {useContext} from "react";
import UIContext from "../../../storage/UIContext";
import {getDataStorage} from "../../../helpers/storage.helper";


const ComponentLoader = () => {
    const storage=getDataStorage()
    return (
        <div style={{backgroundColor:'unset'}} className={styles.container}>
            <CircularProgress />
        </div>
    )

}

export default ComponentLoader