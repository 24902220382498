import { UAParser } from "ua-parser-js";
import { DEFAULT_VALUE } from "../types/LoggingTypes";

export class TechDimensions {
    private static instance : TechDimensions;
    private ua : UAParser;
    private _version : string = DEFAULT_VALUE;
    private _language : string = DEFAULT_VALUE;
    private _platform : string = 'AddPost';

    public constructor(){
        this.ua = new UAParser(navigator.userAgent);
    }

    public static getInstance(){
        if(!TechDimensions.instance)
        {
            TechDimensions.instance = new TechDimensions();
        }
        return TechDimensions.instance;
    }

    get deviceAppUITheme():string {
        return "light";
    }

    get deviceOSVersion():string {
        return this.ua.getOS().version || DEFAULT_VALUE;
    }

    get deviceOS():string {
        return this.ua.getOS().name || DEFAULT_VALUE;
    }

    get deviceLanguage():string {
        return (navigator.language?.split("-")?.[0]) ?? DEFAULT_VALUE;
    }
    
    get deviceCpuArch():string {
        return this.ua.getCPU().architecture || DEFAULT_VALUE;
    }

    get deviceModel():string {
        return this.ua.getDevice().model || DEFAULT_VALUE;
    }

    get deviceBrand():string {
        return this.ua.getDevice().vendor || DEFAULT_VALUE;
    }

    get deviceBrowserVersion():string {
        return this.ua.getBrowser().version || DEFAULT_VALUE;
    }

    get deviceTimeZone(): string {
        const now = new Date();
        const timeZoneOffset = now.getTimezoneOffset();
        const hoursOffset = Math.abs(Math.floor(timeZoneOffset / 60));
        const sign = timeZoneOffset >= 0 ? "-" : "+";
        const deviceTimeZone = `UTC ${sign}${hoursOffset.toString().padStart(2, "0")}:00`;
        return deviceTimeZone
    }
    
    get deviceUserAgent() : string {
        return window.navigator.userAgent || DEFAULT_VALUE;
    }

    get screenResolution(): string {
        return `${window.screen.width}x${window.screen.height}` || DEFAULT_VALUE;
    }

    get httpReferrer(): string {
        return document.referrer || DEFAULT_VALUE;
    }

    get deviceRTT() : string {
        return (navigator && navigator.connection && navigator.connection.rtt) ? navigator.connection.rtt.toString() : DEFAULT_VALUE;
    }

    get connectionType():string {
        return (navigator && navigator.connection && navigator.connection.effectiveType) ? navigator.connection.effectiveType.toString() : DEFAULT_VALUE;
    }

    get deviceBrowser():string {
        return this.ua.getBrowser().name || DEFAULT_VALUE;
    }

    get platform():string {
        return this._platform;
    }

    set platform(platform:string){
        this._platform = platform;
    }

    get version():string {
        return this._version;
    }

    set version(version:string)
    {
        this._version = version;
    }

    get language():string {
        return this._language;
    }
    
    set language(language:string)
    {
        this._language = language;
    }
}