import {Button} from "@mui/material";
import React, {useContext} from "react";
import {Props, Starting} from './../interfaces';
import {useTranslation} from "react-i18next";
import styles from './CityFlowFieldMobile.module.css'
import PostContext from "../../../../../storage/PostContext";
import DynamicObject from "../../../../../models/dynamic-object";
import useSearch from "../../../../../hooks/use-search/use-search";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import LanguageContext from "../../../../../storage/LanguageContext";
import ValidationError from "../../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../../hooks/use-data-payload/use-data-payload";
import {degreesToRadians, getCurrentLocation, getImgPath, getSizes} from "../../../../../helpers/functions";

const CityFlowFieldMobile = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    const {config} = props

    /*-------------------------------------
    *              HOOKS
    * ----------------------------------*/

    /************************************
     *   SET & GET VALIDATION AND STATES
     * *********************************/
    const {state, validation} = useDataPayload<number | string>({
        ref,
        config: props.config
    })

    /************************************
     *        CUSTOME SEARCH HOOK
     * @use filtered results from the search action
     * *********************************/
    const {filteredResults, Search} = useSearch({
        items: config.values,
        search: {
            placeholder: config?.placeholder?.locale
        }
    })

    /************************************
     *        CONTEXT CONSTANT
     * *********************************/

    const postCtx = useContext(PostContext)
    const langCtx = useContext(LanguageContext)


    /************************************
     *         TRANSLATION
     * *********************************/
    const {t} = useTranslation();


    /************************************
     *       INLINE TITLE STYLE
     * *********************************/
    const titleStyle = {
        fontWeight: config.cell.title.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.cell.title.style?.size),
        color: config.cell.title.color,
        direction:langCtx.language=='ar'?'ltr':'',
        textAlign:langCtx.language=='ar'?"end":''
    } as React.CSSProperties


    /*-------------------------------------
     *             FUNCTIONS
     * ----------------------------------*/

    /************************************
     * ADD TO CONTEXT + REQUEST NEXT API
     * *********************************/
    const addItemHandler = (id: number | string, label: string) => {
        state.set(id)
        postCtx.addToForm(config.identifier, id)
        const previewConfig: DynamicObject = postCtx.previewStep
        const lastPreview = previewConfig.childs.filter((child: any) => {
            if (child.identifier === config.identifier) {
                const values = child.values
                values[0] = {...values[0], id: id, label: label}
                return {
                    ...child,
                    values: [...values]
                }
            } else {
                return child
            }
        })

        const finalConfig = {
            ...previewConfig,
            childs: lastPreview
        }
        props.setConfigData(finalConfig);

    }

    /************************************
     *        CALCULATE DISTANCE
     * @use between current positions and
     *      exist cities from  the API
     * *********************************/
    const calcCoordsDistance = (starting: Starting, destination: GeolocationPosition): number => {
        const startingLat = degreesToRadians(starting.lat);
        const startingLong = degreesToRadians(starting.lng);
        const destinationLat = degreesToRadians(destination.coords.latitude);
        const destinationLong = degreesToRadians(destination.coords.longitude);

        // Radius of the Earth in kilometers
        const radius: number = 6571;

        // Haversine equation
        const distanceInKilometers: number = Math.acos(Math.sin(startingLat) * Math.sin(destinationLat) +
            Math.cos(startingLat) * Math.cos(destinationLat) *
            Math.cos(startingLong - destinationLong)) * radius;

        return Math.floor(distanceInKilometers * 100) / 100;
    }

    /************************************
     *      GET CURRENT LOCATION
     * @USE If around me exist, it gets
     * the nearest to the current location
     * *********************************/
    const getClosestCountry = () => {
        getCurrentLocation().then((positions: GeolocationPosition) => {
            let closest: Starting = {
                lat: 0,
                lng: 0
            }
            let id = "";
            let label = "";
            let near_distance = null;

            for (const key in config.values) {
                const value = config.values[key]
                const coordinates = {
                    lat: value.lat,
                    lng: value.lng
                }
                const closest_distance = calcCoordsDistance(coordinates, positions)
                if (near_distance === null || closest_distance < near_distance) {
                    near_distance = closest_distance;
                    closest = coordinates
                    id = value.id;
                    label = value.label;
                }

            }
            if (id)
                addItemHandler(id, label)

        })

    }

    return (
        <div className={`${styles.container}`}>
            <Search></Search>
            {config?.aroundMe && <div className={`${styles.box} shadow`}>
                <div className={`${styles.btn_header}`}>
                    <h1>
                        {t('aroundMe')}
                    </h1>

                </div>
                <div className='row'>
                    <div className='col-md-12 mb-2 '>
                        <Button onClick={getClosestCountry} variant="outlined" color="inherit" fullWidth={true}>
                            {t('useCurrentLocation')}
                        </Button>
                    </div>
                </div>
            </div>
            }
            <div className={styles.items_container}>
                {
                    filteredResults.length < 1 && (
                        <div className={`row ${styles.no_found_data}`}>
                            {t('noResults')}
                        </div>
                    )
                }
                {
                    filteredResults.map(item => {
                            return (
                                <div key={item.id} className={`d-flex ${styles.item}`}
                                     onClick={(e: React.MouseEvent<HTMLElement>) => addItemHandler(item.id,item.label)}>
                                    <div className={`col-md-9 col-10 ${styles.image_text}`}>
                                        {

                                            item.icon && (
                                                <div className={styles.image}>
                                                    <img src={getImgPath(item.cell_icon)}/>
                                                </div>
                                            )
                                        }


                                        <div className={styles.text}>
                                            <h1 style={titleStyle}>{item.label}</h1>
                                        </div>
                                    </div>
                                    <div className={`col-md-3 col-2 ${styles.icon}`}>
                                        {(langCtx.language == 'en') ?
                                            <IoIosArrowForward style={{height: "21.2px", width: "22.85px"}}/> :
                                            <IoIosArrowBack style={{height: "21.2px", width: "22.85px"}}/>}
                                    </div>
                                </div>


                            )
                        }
                    )
                }
            </div>
            <ValidationError validation={validation}/>
        </div>)
})
export default CityFlowFieldMobile