import Cookies from "js-cookie";
import {getFlowData, jsonSafeGet} from "./functions";

const urlParams = new URLSearchParams(window.location.search)
const cookiePrefix = urlParams.get('cookiePrefix') || ''

export type LiteUserInfo = {
    id: number;
    full_name: string;
    profile_picture: string;
    phone_number: string;
    hasPassword: boolean;
};

export type AuthTicketType = {
    t: number;
    k: string;
};

export const isDeviceMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent || ""
    );
}

const getProductionStorage = () => {
    const source = Cookies.get('source') || 'desktop'
    return {
        userInfo:jsonSafeGet<LiteUserInfo>(Cookies.get(cookiePrefix + 'userInfo')),
        tgtTimestamp: jsonSafeGet<AuthTicketType>(Cookies.get('auth')).t || Cookies.get(cookiePrefix + 'tgt_timestamp'),
        tgtTicket: jsonSafeGet<AuthTicketType>(Cookies.get('auth')).k || Cookies.get(cookiePrefix + 'tgt_ticket'),
        lang: Cookies.get('NEXT_LOCALE'),
        source: source,
        audience: urlParams.get('audience') || Cookies.get('audience') || source,
        country: Cookies.get('ecountry') || 'jo',
        device_uuid: Cookies.get('device_uuid'),
        flowData: getFlowData(),
        isMobile: source === 'mobile' || window.sessionStorage.getItem('flow-type') === 'job',
        isDesktopDevice : isDeviceMobile() ? false : true
    }
}

const getDevelopmentStorage = () => {


    // return {
    //     userInfo: JSON.parse(process.env.REACT_APP_TESTING_AUTH_USER_INFO),
    //     tgtTimestamp: process.env.REACT_APP_TESTING_AUTH_TIMEOUT,
    //     tgtTicket: process.env.REACT_APP_TESTING_AUTH_TGT,
    //     source: process.env.REACT_APP_TESTING_AUTH_SOURCE,
    //     audience: process.env.REACT_APP_TESTING_AUTH_SOURCE,
    //     country: process.env.REACT_APP_TESTING_AUTH_COUNTRY,
    //     device_uuid: process.env.REACT_APP_TESTING_AUTH_UUID
    // }
    const source = Cookies.get('source') || 'mobile'


    //sooqdev2
    return {
        userInfo: {id: 67585804, hasPassword: true},
        tgtTimestamp: '1693400305',
        tgtTicket: '7f6804ee610d615181599f1b0cfe8c831c0338480b400632c2d1681aea2cd429',
        source: source,
        audience: 'desktop',
        country: 'jo',
        device_uuid: 'a44cc735-c1de-4657-97d6-e34b6c109190',
        flowData: getFlowData(),
        isMobile: source === 'mobile' || window.sessionStorage.getItem('flow-type') === 'job',
        lang: Cookies.get('NEXT_LOCALE'),
        isDesktopDevice : isDeviceMobile() ? false : true
    }

    //sooqtest
    // return {
    //     userInfo: {id: 50986704},
    //     tgtTimestamp: '1683796248',
    //     tgtTicket: '4ec5a45aea348a41926bb922a7af19054aba4289fc18b0dc659af57c4a6ada93',
    //     source: 'desktop',
    //     audience: 'desktop',
    //     country: 'jo',
    //     device_uuid: 'a44cc735-c1de-4657-97d6-e34b6c109190',
    //     flowData: getFlowData(),
    //     isMobile: source === 'mobile',
    //     lang: Cookies.get('NEXT_LOCALE'),
    //     hasNoPassword : true
    // }
}

export const getDataStorage = () => {
    if (process.env.REACT_APP_ENV == "production")
        return getProductionStorage()
    return getDevelopmentStorage()
}