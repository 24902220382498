import { Props } from "./interface";
import styles from './Button.module.css'
import Modal from "../../../UI/Modal/Modal";
import React, { useState } from "react";
import { Button as MuiButton } from "@mui/material";
import { getImgPath, getSizes, injectUtmsIntoWebviewUrl } from "../../../../helpers/functions";
import ConfirmationModal from "../../PreviewTools/Button/ConfirmationModal";
import { getDataStorage } from "../../../../helpers/storage.helper";
import FlowReferences from "../../../../flow-references";
import useDataPayload, { RefDataType } from "../../../../hooks/use-data-payload/use-data-payload";
import Cookies from "js-cookie";


const Button = React.forwardRef<RefDataType<any>, Props>((props, ref) => {

    const { validation, state } = useDataPayload<any>({
        ref,
        config: props.config,
        setConfig: props.setConfig,
        defaultValue: ""
    })


    /************************************
     *             STATES
     * *********************************/
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isYes, setIsYes] = useState(false)
    const storage = getDataStorage()
    const rightAlignment = props?.config?.text?.icon ? ((props.config.text?.align.toLowerCase() == 'right' && !storage.isMobile) ? 'end' : props.config.text.align) : props.config.text.align

    const isMobileOrDesktopStyle = {
        border: props.config?.border && `1px ${props.config?.border?.type.toLowerCase() == 'none' && props.config?.border?.color ? 'solid' : props.config?.border?.type} ${props.config?.border?.color}`,
        borderRadius: props.config?.border?.round ? "8px" : "0px",
        textAlign: storage.isMobile ? 'center' : props.config.text.align,
        height: getSizes(props.config.text?.style?.size) < '20px' ? '25px' : '30px',
        width: getSizes(props.config.text?.style?.size) < '20px' ? '25px' : '30px',
    }


    /************************************
     *       INLINE BUTTON STYLE
     * *********************************/
    const buttonStyle = {
        fontWeight: props.config.text?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.text?.style?.size),
        color: props.config.text.color,
        textAlign: isMobileOrDesktopStyle.textAlign,
        backgroundColor: props.config.background.color,
        borderRadius: isMobileOrDesktopStyle.borderRadius,
        border: isMobileOrDesktopStyle.border,
        textTransform: "unset",
        letterSpacing: "0",
        height: props.config.identifier.toLowerCase().includes('cvbutton') && "50px",
        '--font-weight': props.config.text?.style?.bold ? "bold" : "normal",
        '--font-size': getSizes(props.config.text?.style?.size),
        '--font-color': props.config.text.color,
    } as React.CSSProperties

    const iconStyle = {
        height: isMobileOrDesktopStyle.height,
        width: isMobileOrDesktopStyle.width
    } as React.CSSProperties

    /*-------------------------------------
     *          FUNCTIONS
     * ----------------------------------*/

    /************************************
     *      BUTTON CLICK HANDLER
     * *********************************/
    const onClickHandler = () => {

        if (props.config.confirmation) {
            setIsOpen(true)
        } else {
            if (props.config.target.webviewURL) {
                const webviewURLWithUTMS = injectUtmsIntoWebviewUrl(props.config.target.webviewURL);
                window.open(webviewURLWithUTMS);
            } else if (props.config.target?.deeplink) {
                const deeplink = props.config.target.deeplink.replace("xx", storage.country)
                if (deeplink.includes("dynaFormAction/close")) {
                    window.location.replace(Cookies.get('addPostRef')?.replace("{post_id}", sessionStorage.getItem("post_id")))
                    return
                }
                window.location.replace(`${process.env.REACT_APP_JO_SOOQ_URL.replace("XX.", "").replace("xx.", "")}/site/dl?requestUri=${deeplink}`)
            } else return
        }
    }

    /************************************
     *     BUTTON ACTION HANDLER
     * *********************************/
    const buttonActionHandler = (event: React.MouseEvent<HTMLElement>, value: boolean) => {
        if (value) {
            setIsYes(true)
        }
        setIsOpen(false);
    }

    const Text = () => {
        if (props.config?.text?.parse_as?.toLowerCase() == 'html')
            return <div dangerouslySetInnerHTML={{ __html: props.config?.text?.locale }} />;
        else
            return <span
                className={`d-flex flex-column justify-content-center align-items-center`}>{props.config.text.locale}</span>
    }

    return (
        <div>
            {
                isOpen && props.config?.confirmation &&
                <Modal title={props.config?.confirmation?.title} setIsOpen={setIsOpen}>
                    <ConfirmationModal config={props.config} buttonActionHandler={buttonActionHandler} />
                </Modal>
            }
            <MuiButton variant='outlined' style={buttonStyle} fullWidth={true}
                onClick={(e: React.MouseEvent<HTMLElement>) => onClickHandler()}
                className={`d-block ${styles.button_body}`}>
                <div
                    className={`${styles.text_icon_container} justify-content-${buttonStyle?.textAlign.toLowerCase() == 'right' ? rightAlignment : buttonStyle?.textAlign}`}>
                    {props.config.text?.icon &&
                        <img style={iconStyle} src={getImgPath(props.config.text?.icon)}></img>}
                    {Text()}
                </div>
            </MuiButton>
            {/*<MuiButton variant='outlined' style={buttonStyle} fullWidth={true} endIcon={props.config.text?.icon &&*/}
            {/*    <img className={styles.img_icon} width="50px" height="50px"*/}
            {/*         src={getImgPath(props.config.text?.icon)}></img>}*/}
            {/*           onClick={(e: React.MouseEvent<HTMLElement>) => onClickHandler()}*/}
            {/*           className={`${styles.button_body}`}>{props.config?.text.locale}</MuiButton>*/}
        </div>
    )
})

export default Button