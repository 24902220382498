import DynamicObject from "./models/dynamic-object";


export default class FlowReferences {
    static fields = {}
    static reRenderedFlow: DynamicObject = {}
    static flow = "normal"
    static actions = {}

    static load(identifier, config) {
        this.fields[identifier] = config
    }



    static clear(identifier) {
        this.fields[identifier] = null
    }

    static get(identifier) {
        return this.fields[identifier]
    }

    static map(callback: (identifier: string, field: any) => void) {
        for (const identifier in this.fields) {
            callback(identifier, this.fields[identifier])
        }
    }

    static resetReRenderFlow() {
        this.reRenderedFlow = {}
    }

    static updateFlow(flow) {
        this.flow = flow
    }

    static getFlow() {
        return this.flow || "normal";
    }

    static resetFlow() {
        this.flow = "normal"
    }
}
