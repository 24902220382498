// import styles from "./TextArea.module.css"
// import {Props, RefDataType} from './interfaces';
// import {getSizes} from "../../../../helpers/functions";
// import PostContext from "../../../../storage/PostContext";
// import React, {useContext, useImperativeHandle, useState} from "react";
// import {FormHelperText, TextField as TextFieldMUI} from "@mui/material";
// import LanguageContext from "../../../../storage/LanguageContext";
// import useValidationNew from "../../../../hooks/use-validation/use-validation-new";
//
// const TextArea = React.forwardRef<RefDataType, Props>((props, ref) => {
//
//     const postCtx = useContext(PostContext)
//     const [value, setValue] = useState<string>(postCtx.data.form[props.config.identifier])
//     const [count, setCount] = useState(value?.length ? value.length : 0);
//     const langCtx = useContext(LanguageContext)
//     const {error, setValidationError} = useValidationNew()
//     const alignCenter = (langCtx.language == 'en') ? 'left' : 'right'
//
//     const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//             setValue(e.target.value)
//             setCount(e.target.value?.length)
//         }
//
//     useImperativeHandle<RefDataType, RefDataType>(ref, () => {
//         return {
//             getState: (): string => value,
//             setValidationError
//         }
//     })
//
//         const attributes = {
//             id: "outlined-multiline-static",
//             fullWidth: true,
//             multiline: true,
//             rows: props.config.height,
//             placeholder: props.config.placeholder.locale,
//             InputProps: {
//                 style: {
//                     color: props.config.text.color,
//                     fontWeight: props.config.text?.style?.bold ? 'bold' : 'normal',
//                     fontSize: getSizes(props.config.text?.style?.size) ,
//                 }
//             },
//         }
//
//
//         return (
//             <div className={`mt-4 ${styles.container}`}>
//                 <TextFieldMUI className={styles.text_area}
//                               {...attributes} onChange={onChange}
//                               value={value} error={!!error}
//                               inputProps={{maxLength: props.config.limit}}/>
//                 <p style={{
//                     marginTop: "-20px",
//                     marginRight: "8px",
//                     marginLeft: "8px",
//                     opacity: "0.4",
//                     fontSize: "10px",
//                     display: "flex",
//                     justifyContent: "flex-end"
//                 }}>
//                     {`${count} / ${props.config.limit}`}
//                 </p>
//                 {error && <FormHelperText style={{
//                     color: "red",
//                     fontSize: "15px",
//                     paddingTop: "2px",
//                     textAlign: alignCenter
//                 }}>
//                     {`*  ${error}`}
//                 </FormHelperText>
//                 }
//             </div>
//         )
//     }
// )
//
// export default TextArea

import React, {useContext, useEffect, useRef, useState} from "react";
import {getSizes} from "../../../../helpers/functions";
import {TextArea as TextAreaModel} from "../../../../models";
import styles from "../../Tools/TextArea/TextArea.module.css";
import {getDataStorage} from "../../../../helpers/storage.helper";
import languageContext from "../../../../storage/LanguageContext";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";

interface Props {
    config: TextAreaModel;
    className?: string,
    setConfigData?: React.Dispatch<React.SetStateAction<any>>;
    children?: React.ReactNode
}

const TextArea = React.forwardRef<RefDataType<string>, Props>((props, ref) => {
    const {validation, state} = useDataPayload<string>({ref, config: props.config, autoSaveDelay: 3000})


    const [count, setCount] = useState(state.value?.length ?? 0);
    const [row, setRows] = useState<number>(props.config.height)

    const storage = getDataStorage()
    const langCtx = useContext(languageContext)


    const fontSize: string = storage.isMobile ? "18px" : getSizes(props.config.text.style.size)


    /************************************
     *       Text Area Style
     * *********************************/
    const textareaStyle = {
        '--padding': '16px 14px', // Set desired padding value
        '--border': validation.error ? '1px solid rgb(241, 79, 117)' : '1px solid #e6e3e3',
        '--borderRadius': '8px', // Set desired border radius
        "--text-font-size": fontSize, // Set desired font size
        "--text-font-weight": props.config.text?.style?.bold ? 'bold' : 'normal', // Set desired font weight
        "--text-font-color": props.config.text.color, // Set desired text color
        '--transition': 'border-color 0.3s', // Add transition for smooth color change
        '--outline': 'none', // Remove default focus outline
        "--placeholder-font-size": getSizes(props.config.placeholder?.style?.size), // Set desired font size
        "--placeholder-font-weight": props.config.placeholder?.style?.bold ? 'bold' : 'normal', // Set desired font weight
        "--placeholder-font-color": props.config.placeholder?.color,
    } as React.CSSProperties;

    /************************************
     *        ACTION HANDLER
     * *********************************/


    const handleChange = (event) => {
        state.set(event.target.value);
    };

    /************************************
     *       CLEAR VALIDATION
     * *********************************/
    useEffect(() => {
        setCount(state.value?.length || 0)
        validation.clear()
    }, [state.value])


    return (
        <div>
            <TextareaAutosize
                id={props.config.identifier}
                className={`${styles.textArea}`}
                minRows={row}
                value={state.value}
                maxLength={props.config.limit} // Set the maximum length to 10
                onChange={handleChange}
                readOnly={!props.config.editable}
                style={textareaStyle}
                dir={langCtx.language == 'ar' ? 'rtl' : 'ltr'}
                placeholder={props.config.placeholder.locale}
            />
            {props.config.counter && <p style={{
                marginTop: "6px",
                opacity: "0.5",
                fontSize: "10px",
                display: "flex",
                justifyContent: "flex-end"
            }}>{langCtx.language == 'ar' ? `${props.config.limit} / ${count} ` : `${count} / ${props.config.limit}`}</p>}
            <ValidationError validation={validation}/>
        </div>

    );
})


export default TextArea


