import React, {useContext, useState} from 'react';
import styles from './ButtonsRow.module.css'
import {Props} from "./interface";
import {getImgPath, getSizes} from "../../../../helpers/functions";
import {useTranslation} from "react-i18next";
import LanguageContext from "../../../../storage/LanguageContext";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import DynamicObject from "../../../../models/dynamic-object";

const ButtonsRow = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    const {state, validation} = useDataPayload<string>({
        ref,
        config: props.config
    })

    const {t} = useTranslation();
    const langCtx = useContext(LanguageContext)

    const clickHandler = (id: string) => {
        state.set(id)
        validation.clear()
    }

    const titleStyle = {
        fontWeight: props.config?.cell?.title?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config?.cell?.title?.style?.size),
        color: props.config?.cell?.title?.color,
        alignItems: props.config?.cell?.title?.align
    } as React.CSSProperties
    const minMaxStyle = {
        fontWeight: props.config?.legend?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config?.legend?.style?.size),
        color: props.config?.legend?.color,
    } as React.CSSProperties
    const buttonStyle = {
        display: "grid",
        gridTemplateColumns: `repeat(${props.config.maxPerRow}, 1fr)`,
        justifyContent: "space-evenly",
        justifyItems: "center",
        alignContent: "space-evenly",
        alignItems: "center"
    } as React.CSSProperties

    return (<>
            <div className={`${styles.container}`}>
                <div className={`${styles.rowButtonContainer}`} style={{
                    flexWrap: props.config?.legend ? 'wrap' : 'nowrap',
                    padding: props.config?.legend ? '10px' : '0px'
                }}>
                    {props.config.values.map((item, index) => {
                        return <div onClick={() => {
                            if (!props.config?.legend) {
                                return clickHandler(item.id)
                            }
                        }} style={{
                            flexBasis: `calc(100% /${props.config.maxPerRow})`,
                            margin: props.config.legend ? '0' : '0 1px',
                            boxShadow: props.config.legend ? '' : ' 0px 0px 20px rgba(0, 0, 0, 0.07)',
                            cursor: props.config?.legend ? 'auto' : 'pointer',
                            padding:props.config?.legend?'0':'20px'
                        }}
                                    className={`${styles.labelButtonContainer} ${state.value && state.value == item.id && !props.config.legend ? styles.selectedButton : ''}`}>
                            <div
                                className={`${styles.button_container} ${state.value && state.value != item.id && props.config.greyOnSelect ? styles.gray : ''}`}>
                                <button onClick={() => {
                                    if (props.config?.legend) {
                                        return clickHandler(item.id)
                                    }
                                }}
                                        style={{border: !item.icon && '1px solid'}}
                                        className={`${styles.button} ${state.value && state.value == item.id && !item.icon ? styles.buttonInClassicSelected : ''}`}>
                                    <div className={`${styles.emoji_container}`}>
                                        {item.icon ? <img className={`${styles.emoji}`}
                                                          src={getImgPath(item.icon)}/> : <div style={titleStyle}
                                                                                               className={`${styles.labelContainerInClassic} ${state.value && state.value == item.id ? styles.selectedText : ''}`}>{item?.title?.locale}</div>}
                                    </div>
                                </button>
                            </div>
                            {item.icon && <div style={titleStyle}
                                               className={`${styles.labelContainer} ${state.value && state.value == item.id ? styles.selectedText : ''}`}>{item?.title?.locale}</div>}
                        </div>
                    })}
                </div>
                {props.config.legend && <div className={`${styles.legend_container}`}>
                    {props.config.legend?.min && <div style={minMaxStyle}>{props.config.legend?.min.locale}</div>}
                    {props.config.legend?.max && <div style={minMaxStyle}>{props.config.legend?.max.locale}</div>}
                </div>}

            </div>
            <ValidationError validation={validation}/>
        </>
    );
});

export default ButtonsRow;
