import React from "react";
import styles from './RedirectBtn.module.css'
import {IoIosArrowForward} from "react-icons/io";
import {getSizes} from "../../../../helpers/functions";

const RedirectBtn = ((props) => {


    {/*CONTAINER STYLING*/
    }

    const subtitleStyle = {
        fontWeight: props.config.subTitle.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.subTitle.style?.size) + "px",
        fontColor: props.config.subTitle.color,
    } as React.CSSProperties

    const titleStyle = {
        fontWeight: props.config.title.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.title.style?.size) + "px",
        fontColor: props.config.title.color,
    } as React.CSSProperties
    return (
        <div className={`${styles.container}`}>
            <div className={`${styles.box}`}>
                <div className={`row`}>
                    {/*TITLE HEADER*/}
                    <div className={`col-md-12`}>
                        <h1 style={titleStyle}>{props.config.title.locale}</h1>
                    </div>
                </div>
                <div className={`row mt-3`}>
                    {/*SUB TITLE HEADER*/}
                    <div className={`col-md-6`}>
                        <h6 style={subtitleStyle}>{props.config.subTitle.locale}</h6>
                    </div>
                    <div className={`col-md-6 ${styles.icon}`}>
                        <IoIosArrowForward/>
                    </div>
                </div>
            </div>
        </div>)
})

export default RedirectBtn











