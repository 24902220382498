import React, {useEffect} from "react";
import {Props} from './interfaces';
import styles from './PhoneField.module.css'
import MenuItem from "@mui/material/MenuItem";
import {getSizes} from "../../../../helpers/functions";
import DynamicObject from "../../../../models/dynamic-object";
import {Select, SelectChangeEvent, Switch} from "@mui/material";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";

const PhoneField = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

        const {validation, state} = useDataPayload<DynamicObject>({ref, config: props.config})

        const {config} = props

        {/*--------ACTION HANDLER----------*/
        }

        document.addEventListener("wheel", function (event) {
            document.getElementById("phone")?.blur()

        });
        useEffect(function () {
            if (props.config?.country?.values.length > 0) {
                if (state.value && !state.value['unit']) {
                    state.set({...state.value, country: config.country.values[0].abbreviation})

                }
            }
        }, [props.config?.country.values])
        {/*THIS IS FOR UPDATE IF THE USER NEED TO SHOW YOUR PHONE OR NOT */
        }
        const changeHideFlagHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            state.set({...state.value, hideFlag: event.target.checked})
        }
        {/*THIS IS FOR ADD SELECTED COUNTRY TO CONTEXT */
        }
        const addCountryHandler = (e: SelectChangeEvent) => {
            state.set({...state.value, country: e.target.value})
        }

        const phoneHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
            state.set({...state.value, phone: e.target.value})

        }

        useEffect(() => {
            validation.clear()
        }, [state.value ? state.value['phone'] : [state.value]])

        {/*PREPARE COUNTRIES VALUES TO SHOW FOR USER*/
        }
        const countries = config.country?.values.map((country, i) => {
            return <MenuItem value={country.abbreviation}>
                <div className={`${styles.img_label}`}>
                    <div className={`${styles.item}`}>
                        <img className={`${styles.image}`} src={country.icon}/>
                    </div>
                    <span> {country.phone_code}</span></div>
            </MenuItem>
        })

        {/*PREPARE HEADER OF PHONE FILED*/
        }
        const header = <div className={`${styles.header}`}>
            <Select labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={state.value ? state.value['country'] : ''}
                    onChange={(e: SelectChangeEvent) => addCountryHandler(e)}
                    sx={{boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': {border: 0}}}>
                {countries}
            </Select>
        </div>

        return (
            <div id={props.config.identifier} className={`${styles.parent_container}`}>
                <div className={`${styles.container}`}>

                    {/*FIRST ITEM COUNTRY ICON AND COUNTRY CODE */}
                    <div className={`${styles.country_container} ${styles.border_0}`}>
                        {config.country.values.length > 1 && header}
                        <div className={`${styles.img_label}`}>
                            <div className={`${styles.item}`}>
                                <img className={`${styles.image}`} src={config.country.values[0].icon}/>
                            </div>
                            <span className={'me-1'}> {config.country.values[0].phone_code}</span></div>
                    </div>

                    {/*SECOND ITEM PHONE NUMBER */}
                    <input disabled={!config.editable}
                           name="phone"
                           id="phone"
                           onChange={phoneHandler}
                           type={"tel"}

                           className={`br-10 form-control ${styles.border_0}`}
                           style={{
                               color: config.text.color,
                               width: "100%",
                               fontSize: getSizes(config.placeholder.style?.size),
                               fontWeight: config.placeholder.style?.bold ? "bold" : "normal",
                           }}

                           placeholder={config.placeholder.locale} aria-describedby="basic-addon2"/>
                </div>

                {config.hideFlag &&
                    <div>
                        <hr className={styles.line}/>
                        <div className={`row ${styles.flag}`}>
                            <div className={"col-md-6 ms-2"}>
                                <p>{config.hideFlag.locale}</p>
                            </div>
                            <div className={`col-md-6 ${styles.switch}`}>
                                <Switch onChange={changeHideFlagHandler}
                                        checked={state.value ? state.value['hideFlag'] : false}/>
                            </div>

                        </div>
                    </div>

                }
                <ValidationError validation={validation}/>
            </div>
        )
    }
)

export default PhoneField