import React, {useContext, useImperativeHandle, useState} from "react";
import {Checkbox as CheckboxMui, FormHelperText} from "@mui/material";
import {FormControlLabel, FormGroup} from "@mui/material";
import PostContext from "../../../../storage/PostContext";
import {RefDataType} from "../../Tools/TextField/interfaces";
import useValidationNew from "../../../../hooks/use-validation/use-validation-new";
import languageContext from "../../../../storage/LanguageContext";
import {Props} from "./interface";

const Checkbox = React.forwardRef<RefDataType, Props>((props, ref) => {

    const {config} = props

    /*-------------------------------------
     *              HOOKS
     * ----------------------------------*/

    /************************************
     *   SET & GET VALIDATION AND STATES
     * *********************************/

    const {error, setValidationError, clearValidationError} = useValidationNew()

    /*------------------------------------*/

    /************************************
     *            CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)
    const langCtx = useContext(languageContext)

    /************************************
     *            CONSTANTS
     * *********************************/
    const alignCenter = (langCtx.language == 'en') ? 'left' : 'right'

    /************************************
     *            STATES
     * *********************************/
    const [value, setValue] = useState<number>(+postCtx.data.form[props.config.identifier])

    /*-------------------------------------
     *              FUNCTIONS
     * ----------------------------------*/

    const addItemHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        clearValidationError()
        setValue(+e.target.checked)
    }

    useImperativeHandle<RefDataType, RefDataType>(ref, () => {
        return {
            getState: (): any => value,
            setValidationError
        }
    })

    /*------------------------------------*/


    return (
        <div>
            <FormGroup>
                <FormControlLabel
                    control={
                        <CheckboxMui
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => addItemHandler(e)}
                            checked={!!value}
                        />
                    }
                    label={config.text.locale}
                    value={value}
                    style={{'justifyContent': config?.text?.align,width:"fit-content"}}
                />
            </FormGroup>
            {error && <FormHelperText style={{
                color: "red",
                fontSize: "18px",
                paddingTop: "2px",
                textAlign: alignCenter
            }}>{`*   ${error}`}</FormHelperText>
            }
        </div>
    )
})

export default Checkbox