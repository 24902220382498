import MenuItem from "@mui/material/MenuItem";
import styles from './FieldWithUnit.module.css'
import {Props} from './interfaces';
import {getInputType, getSizes, handleFieldWithUnitInput, numberCommaFormatter, toEnDigit} from "../../../../helpers/functions";
import DynamicObject from "../../../../models/dynamic-object";
import {Select, SelectChangeEvent, TextField} from "@mui/material";
import React, {ChangeEvent, CSSProperties, useEffect, useState} from "react";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {getDataStorage} from "../../../../helpers/storage.helper";


const FieldWithUnit = React.forwardRef<RefDataType<string>, Props>((props, ref) => {
    const {state, validation} = useDataPayload<DynamicObject>({
        ref,
        config: props.config,
        autoSaveDelay: 3000
    })

    const [unit, setUnit] = useState<number | string>(state?.value ? state?.value['unit']?.toString() : parseInt(props?.config?.units && props?.config?.units[0]?.toString()))

    const {config, nextApi} = props
    const storage = getDataStorage()
    const [maxDisplayValue, setMaxDisplayValue] = useState<string>('')
    const [minDisplayValue, setMinDisplayValue] = useState<string>('')


    useEffect(() => {
        if(config?.thousands_separator){
            setMaxDisplayValue(numberCommaFormatter(state?.value?.max))
            setMinDisplayValue(numberCommaFormatter(state?.value?.min))
        } else {
            setMaxDisplayValue(state?.value?.max || '')
            setMinDisplayValue(state?.value?.min || '')
        }
    }, [config?.thousands_separator])


    const inputMinType = getInputType(props.config?.min?.format ? props.config?.min?.format : '')
    const inputMaxType = getInputType(props.config?.max?.format ? props.config?.max?.format : '')


    document.addEventListener("wheel", function (event) {
        document.getElementById("outlined-number")?.blur()

    });

    useEffect(() => {
        validation.clear()
    }, [state.value ? state.value['min' || 'max'] : state.value])


    useEffect(function () {
        if (!state.value)
            setUnit(props.config?.units[0]?.id)

    }, [])

    useEffect(() => {
        if (state?.value?.min || state?.value?.max) {
            state.set({...state.value, unit: unit})
        }
    }, [state?.value?.min, state?.value?.max])


    const addUnitHandler = (e: SelectChangeEvent<number | string>) => {
        setUnit(e.target.value)
        state.set({...state.value, unit: e.target.value})
        // postCtx.addToForm(config.identifier, {...postCtx.data.form[config.identifier], unit: e.target.value})
    }

    const handleInput = (event, type, eventType = 'key') => {
        const fieldFormat = props.config?.[type]?.format;
        const stateSetter = (value) => {
            state.set({
                ...state.value,
                [type]: value
            });
        }

        const thousandsSeparator = props.config?.thousands_separator
        
        handleFieldWithUnitInput({event,
             fieldFormat,
             setDisplayedValue: type == "min" ? setMinDisplayValue : setMaxDisplayValue,
             stateSetter,
             thousandsSeparator,
             maxNumberOfChar: props.config?.limit,
             maxNumberAfterAllowed: props.config?.decimal_digits
            })
    };


    const minStyle = {
        color: config?.min?.placeholder.color,
        fontWeight: config?.min?.placeholder.style?.bold ? 'bold' : 'normal',
        fontSize: getSizes(config?.min?.placeholder.style?.size),
    } as CSSProperties

    const maxStyle = {
        color: config?.min?.placeholder.color,
        fontWeight: config?.min?.placeholder.style?.bold ? 'bold' : 'normal',
        fontSize: getSizes(config?.min?.placeholder.style?.size),
    } as CSSProperties

    const units = config.units.map((unit, i) => {
        return <MenuItem value={unit.id}>
            <span> {unit.label}</span>
        </MenuItem>

    })


    const header = <div className={`mt-auto`}>
        <Select labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={parseInt(unit?.toString())}
                IconComponent={ExpandMoreIcon}
                onChange={(e: SelectChangeEvent<number>) => addUnitHandler(e)}
                sx={{boxShadow: 'none', borderRadius: "8px"}}
                MenuProps={{sx: {borderBottom: '1px solid black'}}}

        >
            {units}
        </Select>
    </div>

    const min = <TextField className={`${styles.min}`} id="outlined-number"
                           sx={{'.MuiOutlinedInput-notchedOutline': {borderRadius: "8px", borderColor: "#E6E3E3"}}}
                           placeholder={config.min?.placeholder.locale} value={minDisplayValue}
                           onChange={(e) => handleInput(e, 'min')}
                           InputLabelProps={{sx: minStyle}}
                           inputMode={inputMinType}
                           onPaste={(event) => {

                               if (!(props.config?.min?.format == 'all' || props.config?.min?.format == 'open')) {
                                   event.preventDefault()
                               }
                           }}
                           onCopy={(event) => {
                               if (!(props.config?.min?.format == 'all' || props.config?.min?.format == 'open')) {
                                   event.preventDefault()
                               }
                           }}
                           onCut={(event) => {

                               if (!(props.config?.min?.format == 'all' || props.config?.min?.format == 'open')) {
                                   event.preventDefault()
                               }
                           }}
                           autoComplete={"off"}
                           autoCorrect={"off"}
                           spellCheck={"false"}
                           onKeyPress={(e) => handleInput(e, 'min')}


    />

    const max = <TextField className={`${styles.max}`} id="outlined-number"
                           sx={{'.MuiOutlinedInput-notchedOutline': {borderRadius: "8px", borderColor: "#E6E3E3"}}}
                           placeholder={config.max?.placeholder.locale}
                           onChange={(e) => handleInput(e, 'max')}
                           InputLabelProps={{sx: maxStyle}}
                           inputMode={inputMaxType}
                           onPaste={(event) => {
                               if (!(props.config?.max?.format == 'all' || props.config?.max?.format == 'open')) {
                                   event.preventDefault()
                               }

                           }}
                           onCopy={(event) => {
                               if (!(props.config?.max?.format == 'all' || props.config?.max?.format == 'open')) {
                                   event.preventDefault()
                               }
                           }}
                           onCut={(event) => {
                               if (!(props.config?.max?.format == 'all' || props.config?.max?.format == 'open')) {
                                   event.preventDefault()
                               }
                           }}
                           autoComplete={"off"}
                           autoCorrect={"off"}
                           spellCheck={"false"}
                           value={maxDisplayValue}
                           onKeyPress={(e) => handleInput(e, 'max')}

    />

    return (
        <>
            <div id={props.config.identifier}
                 className={`${storage.isMobile ? styles.mobileContainer : styles.container} `}>
                <div className={`${styles.body}`}>
                    {config.units.length > 1 ? header : config.units.length == 1 ? <div className={`${styles.header}`}>
                        <span> {props.config.units[0].label}</span>
                    </div> : ''}
                    {config.min && min}
                    {config.max && max}

                </div>
            </div>
            <ValidationError validation={validation}/>
        </>
    )
})

export default FieldWithUnit







