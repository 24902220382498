import {Props} from './interfaces';
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import styles from './ListCellOptions.module.css'
import PostContext from "../../../../storage/PostContext";
import {Item} from "../../../../hooks/use-search/interfaces";
import DynamicObject from "../../../../models/dynamic-object";
import useSearch from "../../../../hooks/use-search/use-search";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import {getImgPath, getSizes, init} from "../../../../helpers/functions";
import LanguageContext from "../../../../storage/LanguageContext";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";

const ListCellOptions = React.forwardRef<RefDataType<string>, Props>((props, ref) => {


    const {state, validation} = useDataPayload<string | number>({
        ref,
        config: props.config
    })


    const {config, nextApi} = props
    const {t} = useTranslation();
    const langCtx = useContext(LanguageContext)
    const postCtx = useContext(PostContext)



    const {filteredResults, Search} = useSearch({
        items: config.values,
        search: {
            placeholder: config?.search?.placeholder?.locale
        }
    })

    {/*STYLING HANDLER*/
    }
    const labelStyle = {
        fontWeight: config.cell.label.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.cell.label.style?.size),
        color: config.cell.label.color,
        direction:langCtx.language=='ar'?'ltr':'',
        textAlign:langCtx.language=='ar'?"end":''
    } as React.CSSProperties


    /************************************
     *        CHECK PARENT
     * *********************************/
    const checkParent = (parent: DynamicObject) => {
        let startIds = [props.config.identifier]
        for (const key of Object.keys(parent.childs)) {
            const child = parent.childs[key];
            if (child.parent && child.parent.length > 0 && child.parent.includes(startIds[startIds.length - 1])) {
                startIds.push(child.identifier);
            }
        }
        return startIds;
    }

    /************************************
     *        CHECK LINKED
     * *********************************/
    const checkLinked = () => {
        if (props.selectFieldConfig.linked?.length) {
            const linkedData = {}

            //reset the child based on linked
            for (const field of props.selectFieldConfig.linked)
                linkedData[field] = ''

            const stepConfig: DynamicObject = postCtx.data.step
            const recursiveValue = init(postCtx.data.step, config.identifier);
            let selectFieldLevels: number [] = recursiveValue.split('-').map(Number)

            let parent=null
            let child = JSON.parse(JSON.stringify(stepConfig));

            for (const level of selectFieldLevels) {
                // Ensure a new object for each level
                parent = { ...child, childs: { ...child.childs } };
                child = parent.childs[level];
            }
            const values = child?.values;

            if (values) {
                delete child.values
                child.values = [];
            }

            //to reset the child recursively based on parent also
            let parentIds=checkParent(parent)

            for (const field of parentIds)
                linkedData[field] = ''

            return linkedData
        }

    }

    /************************************
     * UPDATE ON CONTEXT + NEXT API
     * *********************************/
    const addItemHandler = (item?:Item) => {
        state.set(item?.id)
        const stepConfig: DynamicObject = postCtx.data.step
        const recursiveValue = init(postCtx.data.step, config.identifier);
        let selectFieldLevels: number [] = recursiveValue.split('-').map(Number)


        const clonedStepConfig = JSON.parse(JSON.stringify(stepConfig));

        let child = clonedStepConfig;

        for (const level of selectFieldLevels) {
            // Ensure a new object for each level
            child = { ...child, childs: { ...child.childs } };
            child = child.childs[level];
        }
        const values = child?.values;

        if (values) {
            // Create a new array and a new object within the array
            child.values = [
                {
                    ...values[0],
                    label: item.label,
                    id: item.id,
                },
                ...values.slice(1), // Copy the remaining items
            ];
        }

        const linkedData = checkLinked()

        const formData = {
            ...postCtx.data.form,
            ...props.tempFormData,
            ...linkedData,
            [config.identifier]: item.id
        }

        postCtx.updateData({
            form: {
                ...formData,
            },
            step:{
                ...clonedStepConfig
            }
        })


    }

    return (<>
            <div className={styles.container}>
                {/* SEARCH */}
                {config?.search &&
                    <Search></Search>
                }
                {/* ITEMS */}
                <div className={styles.items_container}>
                    {
                        filteredResults.length < 1 && (
                            <div className={`${styles.no_found_data}`}>
                                {t('noResults')}
                            </div>
                        )
                    }
                    {
                        filteredResults.map(item => {
                                if (item.id) {
                                    return (
                                        <div key={item.id} className={`d-flex ${styles.item}`} style={{padding:item.icon && "10px"}}
                                             onClick={(e: React.MouseEvent<HTMLElement>) => addItemHandler(item)}>
                                            <div className={`col-md-9 col-10 ${styles.image_text}`}>
                                                {/*ITEM ICON*/}
                                                {

                                                    item.icon && (
                                                        <div className={styles.image_container}>
                                                            <img className={styles.image}
                                                                 style={{width: config.identifier.includes("categoriesMain") ? "50px" : "30px"}}
                                                                 src={getImgPath(item.icon)}/>
                                                        </div>
                                                    )
                                                }


                                                {/*ITEM LABEL*/}
                                                <div className={styles.text}>
                                                    <div style={labelStyle}>{item.label}</div>
                                                </div>
                                            </div>
                                            {/*ITEM ARROW */}
                                            <div className={`col-md-3 col-2 ${styles.icon}`}>
                                                {(langCtx.language == 'en') ?
                                                    <IoIosArrowForward style={{height: "21.2px", width: "22.85px"}}/> :
                                                    <IoIosArrowBack style={{
                                                        height: "21.2px",
                                                        width: "22.85px"
                                                    }}/>}                                        </div>
                                        </div>
                                    )
                                }
                                return (
                                    <h3 style={{textAlign: "center"}}>{item.label}</h3>
                                )
                            }
                        )
                    }
                </div>
                <ValidationError validation={validation}/>
            </div>
        </>
    )
})
export default ListCellOptions
