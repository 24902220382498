import MD5 from "crypto-js/md5";
import Cookies from "js-cookie";

export const generateABBucket = () => {
    const deviceUUID = Cookies.get('device_uuid');

    if (!deviceUUID || deviceUUID.length !== 36) {
        console.log("Invalid deviceUUID: Must be 36 characters long");
        return null;
    }

    const lastTwoChars = MD5(deviceUUID).toString().slice(-2);
    const hexValue = parseInt(lastTwoChars, 16);
    const bucketNumber = Math.ceil((hexValue / 255.0) * 10) || 1;

    return String(bucketNumber);
};



