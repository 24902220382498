import React, {useContext} from "react";
import UIContext from "../../../storage/UIContext";
import { ComponentLoader } from "../../UI";
import {useNavigate} from "react-router-dom";
import PostContext from "../../../storage/PostContext";
import LanguageContext from "../../../storage/LanguageContext";
import DynamicObject from "../../../models/dynamic-object";
import { useHttp } from "../../../hooks";
import { AxiosResponse } from "axios";
import { setSessionByKey } from "../../../helpers/functions";
import NextApi from "../../../api/next.api";
import MapperFlow from "../../MapperFlow";
import { Args } from "../../../hooks/use-http/interfaces";
import initReviewsApi from "../../../api/init-reviews.api";

const Reviews = (): JSX.Element => {

    const postCtx = useContext(PostContext)
    const languageCtx = useContext(LanguageContext)
    const uiCtx = useContext(UIContext)
    const navigate = useNavigate()
    const urlParams = new URLSearchParams(window.location.search)

    const configInitReviewsApi = {
        callback: initReviewsApi,
        initialData: {},
        withLoader: true
    }


    /************************************
     *         INIT REQUEST API
     * *********************************/
    const initApi = useHttp<DynamicObject>(configInitReviewsApi)
    const onSuccessInitApi = (response: AxiosResponse) => {
        setSessionByKey("flowIdentifier", "review")
        postCtx.updateStep({
            workflow_id: response.data.workflow_id,
            draft_id: response.data.draft.id,
            step: response.data.step,
            start_time: Date.now(),
            previous: response.data.previous,
            flow_type: "reviews",
            formType:response?.data?.review_identifier
        })
        setSessionByKey('draftId', response.data.draft.id)
        setSessionByKey('flow-type', 'reviews')
        setSessionByKey("formType",urlParams.get('formType'))
        navigate(`${response.data.step.uri}`)
    }

    initApi.request({
        config: {
            language: languageCtx.language,
            formType: urlParams.get('formType')
        },
        callbacks: {
            success: onSuccessInitApi
        },
    })
    
    /************************************
     *         Next REQUEST API
     * *********************************/
    const configNextApi = {
        callback: NextApi,
        initialData: {},
        withLoader: true
    }

    const nextApi = useHttp<DynamicObject>(configNextApi)
    const request2 = nextApi.request


    function initializeData() {
        const data: DynamicObject = {
            draftId: postCtx.data.draft_id,
            time_spent_ms: Date.now() - postCtx.data.start_time,
            workflowId: postCtx.data.workflow_id,
            currentStep: postCtx?.data?.step?.identifier,
        }


        for (const key in postCtx.data.currentStepData) {
            data[key as keyof typeof data] = postCtx.data.form[key as keyof typeof postCtx.data.form]
        }
        return data;
    }


    {/*Prepare Request next api*/
    }
    nextApi.request = function (args?: Args) {
        const data = initializeData();

        request2({
            ...args,
            config: {
                flow_type: postCtx.data.flow_type,
                ...args?.config,
                data: {
                    ...args?.config?.data,
                    ...data
                },
                language: languageCtx.language
            },
            callbacks: {
                ...args?.callbacks,
                before: () => {
                    uiCtx.setLoading(true)
                }
            }
        })
    }


    return (
        <div>
            {uiCtx.isLoading && <ComponentLoader/>}
            {
                postCtx.data?.step && !uiCtx.isLoading &&
                <MapperFlow config={postCtx.data.step}
                            nextApi={nextApi}
                />
            }

        </div>
    )
};

export default Reviews;
