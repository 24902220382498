import React, {useContext, useEffect, useImperativeHandle, useState} from "react";
import {MediaStep as MediaStepModel} from "../../../../models";
import {UseHttpResponse} from "../../../../hooks/use-http/interfaces";
import DynamicObject from "../../../../models/dynamic-object";
import styles from './MediaStep.module.css'
import {FaRegCheckCircle} from "react-icons/fa";
import {MediaUpload} from "../../../UI";
import NextButton from "../../NextButton/NextButton";
import Step from "../Step";
import {useTranslation} from "react-i18next";
import PostContext from "../../../../storage/PostContext";
import {Box} from "@mui/material";
import Media from "../../../UI/MediaUpload/Media";
import UIContext from "../../../../storage/UIContext";
import {RefDataType} from "../../Tools/TextField/interfaces";
import {Props} from "../../JobTools/Checkbox/interface";
import {getDataStorage} from "../../../../helpers/storage.helper";
import FlowReferences from "../../../../flow-references";
import {getImgPath, getSizes} from "../../../../helpers/functions";
import {Tip} from "../../../../assets/images";
import LanguageContext from "../../../../storage/LanguageContext";

interface MediaStepProps {
    config: MediaStepModel;
    nextApi?: UseHttpResponse<DynamicObject>,
    className?: string,
}


const MediaStep = React.forwardRef<any, MediaStepProps>((props, ref) => {
    const {config, nextApi} = props
    const {t} = useTranslation();
    const postCtx = useContext(PostContext);
    const storage = getDataStorage()
    const langCtx = useContext(LanguageContext)
    const [error, setError] = useState('')
    const containerWidth = document.getElementById('content-container')
    const [screenWidth, setScreenWidth] = useState(containerWidth?.clientWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(containerWidth?.clientWidth);
        };

        //scroll to of the page in normal step
        const body = document.querySelector('#root');
        body.scrollIntoView({
            behavior: 'smooth'
        })

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array to run the effect only once


    useImperativeHandle<any, any>(ref, () => {
        return {
            validation: {
                set: (err) => setError(err)
            }
        }
    })

    FlowReferences.load(config.identifier, {
        validation: {
            set: (err) => setError(err),
            clear: () => setError('')
        },
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const containerStyle = {
        fontWeight:"normal",
        fontSize:"14px",
        color:"#000000",
        width: '100%',
        border: '1px dashed rgba(1, 121, 255, 1)',
        borderRadius: '8px',
        backgroundColor: "rgba(235, 244, 255, 1)",
        padding: '10px'
    } as React.CSSProperties
    return (
        <Step className={`${styles.container} mb-5`}>

            <div className={styles.header}>
                <div className={styles.headerTitle}>
                    {/*<h1>{config.title?.locale }</h1>*/}
                    <h1>{t('addMediaTitle')}</h1>
                </div>
                <div style={{...containerStyle, gap:langCtx.language=='ar'?'20px':'5px'}} dir={langCtx.language=='ar'?'rtl':'ltr'} className={`col d-flex ${styles.icon_list_container}`}>
                            <div style={{
                                marginTop:'2px'
                            }}><img style={{width: "22px"}}
                                    src={Tip}/></div>
                            <ul style={{margin:"0",padding:"0px 2px 0px 25px"}}>
                                <li> {t('youCanAddUp')} <strong> {config?.config?.numberOfImages || 30} {t('image')}</strong></li>
                                <li>{t('increaseImageCountNotice')}</li>
                                <li>{t('re-orderImage')}</li>
                            </ul>
                </div>
            </div>
            <div style={{marginBottom:storage.isMobile && '50px'}} className={styles.content}>
                <Media identifier={config.fieldIdentifier} error={error} setError={setError} config={config} />
            </div>
            <NextButton
                style={{
                    bold: true,
                    size: "m",
                    color: "#fff",
                    backgroundColor: "#0C5CF9",
                    width: storage.isMobile ? `${screenWidth - 20}px` : "400px"
                }}
                isSticky={storage.isMobile}
                nextApi={nextApi}
            >{t('continue')}</NextButton>
        </Step>
    )
})

export default MediaStep