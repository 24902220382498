import {Props} from './interfaces';
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import styles from './ListCellOptions.module.css'
import PostContext from "../../../../storage/PostContext";
import {Item} from "../../../../hooks/use-search/interfaces";
import DynamicObject from "../../../../models/dynamic-object";
import useSearch from "../../../../hooks/use-search/use-search";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import {getImgPath, getSizes, removeSessionByKey, RTL_DIR_SWITCHER_IDENTIFIERS, setSessionByKey} from "../../../../helpers/functions";
import LanguageContext from "../../../../storage/LanguageContext";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";

const ListCellOptions = React.forwardRef<RefDataType<string>, Props>((props, ref) => {
    const {state, validation} = useDataPayload<string | number>({
        ref,
        config: props.config
    })


    const {config, nextApi} = props
    const {t} = useTranslation();
    const langCtx = useContext(LanguageContext)
    const postCtx = useContext(PostContext)

    const [showDropdown, setShowDropdown] = useState(false);


    const {filteredResults, Search} = useSearch({
        items: config.values,
        search: {
            placeholder: config?.search?.placeholder?.locale
        }
    })

    {/*STYLING HANDLER*/
    }
    const labelStyle = {
        fontWeight: config.cell.label.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.cell.label.style?.size),
        color: config.cell.label.color,
        direction:langCtx.language=='ar'?'ltr':'',
        textAlign:langCtx.language=='ar'?"end":''
    } as React.CSSProperties

    // edge case for engineSize, the text is in arabic and the numbers are in english
    if (RTL_DIR_SWITCHER_IDENTIFIERS.indexOf(config.identifier) != -1 && langCtx.language == 'ar') {                    
        labelStyle.direction = 'rtl'
    }

    {/*ACTION HANDLER*/
    }
    const addItemHandler = (item: Item) => {
        const httpConfig: DynamicObject = {
            config: {
                data: {}
            }
        }
        httpConfig["config"]["data"][config.identifier] = item.id
        if (item.target?.deeplink === 'xx/applyToJob/0') {
            httpConfig["config"]["flow_type"] = 'init-cv'
            postCtx.updateData({
                'flow_type': 'init-cv'
            })
            setSessionByKey('flow-type','job')

        }

        state.set(item.iMapperd)
        postCtx.addToForm(config.identifier, item.id)


        nextApi?.request(httpConfig)
        if (item.target?.deeplink === 'xx/applyToJob/0') {
            setSessionByKey('flow-type','job')

            postCtx.updateData({'flow_type': 'cv'})
        }
    }

    return (<>
            <div className={styles.container}>
                {/* SEARCH */}
                {config?.search &&
                    <Search></Search>
                }
                {/* ITEMS */}
                <div className={styles.items_container}>
                    {
                        filteredResults.length < 1 && (
                            <div className={`${styles.no_found_data}`}>
                                {t('noResults')}
                            </div>
                        )
                    }
                    {
                        filteredResults.map(item => {
                                if (item.id) {
                                    return (
                                        <div key={item.id} className={`d-flex ${styles.item}`} style={{padding:item.icon && "10px"}}
                                             onClick={(e: React.MouseEvent<HTMLElement>) => addItemHandler(item)}>
                                            <div className={`col-md-9 col-10 ${styles.image_text}`}>
                                                {/*ITEM ICON*/}
                                                {

                                                    item.icon && (
                                                        <div className={styles.image_container}>
                                                            <img className={styles.image}
                                                                 style={{width: config.identifier.includes("categoriesMain") ? "50px" : "30px"}}
                                                                 src={getImgPath(item.icon)}/>
                                                        </div>
                                                    )
                                                }


                                                {/*ITEM LABEL*/}
                                                <div className={styles.text}>
                                                    <div style={labelStyle}>{item.label}</div>
                                                </div>
                                            </div>
                                            {/*ITEM ARROW */}
                                            <div className={`col-md-3 col-2 ${styles.icon}`}>
                                                {(langCtx.language == 'en') ?
                                                    <IoIosArrowForward style={{height: "21.2px", width: "22.85px"}}/> :
                                                    <IoIosArrowBack style={{
                                                        height: "21.2px",
                                                        width: "22.85px"
                                                    }}/>}                                        </div>
                                        </div>
                                    )
                                }
                                return (
                                    <h3 style={{textAlign: "center"}}>{item.label}</h3>
                                )
                            }
                        )
                    }
                </div>
                <ValidationError validation={validation}/>
            </div>
        </>
    )
})
export default ListCellOptions



//list Cell Options in DropDownMenu
// <>
//     <div className={`${styles.wrapper}`}
//          style={{width: "365px", marginLeft: "0.25rem !important", marginRight: "0.25rem !important"}}>
//         <div className={!showDropdown ? `${styles.selectBtn}` : `${styles.selectBtn_UnClick}`}
//              onClick={() => setShowDropdown(prev => !showDropdown)} style={{
//             fontSize: "22px",
//             display: "flex",
//             padding: "12px 10px",
//             borderRadius: "8px",
//             border: "1px solid #EDEDED",
//             background: "#ffff",
//             height: "46px",
//             justifyContent: "space-between"
//         }}>
//             <span className={`${styles.select_span}`}>{props.placeholder && props.placeholder}</span>
//             {!showDropdown ? <IoIosArrowDown/> : <IoIosArrowUp/>}
//
//         </div>
//         <div className={`${styles.content}`} style={{
//             position:"absolute",
//             width: "inherit",
//             background:"white",
//             zIndex:1,
//             padding: "20px 10px 0px 10px",
//             border: "1px solid #E7E7E7",
//             display: !showDropdown ? "none" : "block",
//             transform: !showDropdown ? "rotate(-180deg)" : "",
//             borderRadius: "7px",
//         }}>
//             <div className={`${styles.serach}`} style={{}}>
//                 <i className="uli uli-search"></i>
//                 <input type="text" placeholder="Search" className={`${styles.searchInput}`}/>
//             </div>
//             <ul className={`${styles.options}`} style={{paddingLeft: 0}}>
//                 <div className={`${styles.drop_down_items_container}`}>
//                     {config.values.map((item, i) => {
//                         if (item.id) {
//                             return (
//                                 <div key={item.id} className={`row ${styles.drop_down_items}`}
//                                      onClick={(e: React.MouseEvent<HTMLElement>) => addItemHandler(item)}>
//                                     <div className={`col-md-9 col-10 ${styles.image_text}`}>
//                                         {/*ITEM ICON*/}
//                                         {
//
//                                             item.icon && (
//                                                 <div className={styles.image_container}>
//                                                     <img className={styles.image}
//                                                          style={{width: "30px"}}
//                                                          src={getImgPath(item.icon)}/>
//                                                 </div>
//                                             )
//                                         }
//
//                                         {/*ITEM LABEL*/}
//                                         <div className={styles.text}>
//                                             <p style={labelStyle}>{item.label}</p>
//                                         </div>
//                                     </div>
//                                 </div>
//                             )
//                         }
//                         return (
//                             <h3 style={{textAlign: "center"}}>{item.label}</h3>
//                         )
//                     })}
//                 </div>
//
//
//             </ul>
//         </div>
//     </div>
// </>
