import ArachnaApi from './requests/arachna-api'
import {AxiosInstance, AxiosProgressEvent, AxiosResponse} from "axios";
import DynamicObject from "../models/dynamic-object";
import Cookies from "js-cookie";
import {generateABBucket} from "../helpers/generateAbbucket";

export default (config?: DynamicObject): Promise<AxiosResponse> => {
    const extraParams = config.extraParams || {};
    const userAbBucket = Cookies.get("userABBucket") || generateABBucket();

    return ArachnaApi.getInstance().request.post(`${process.env.REACT_APP_ARACHNA_API_URL }/vertical/forms/v2/add-post/normal/drafts/media/${config?.draftId}`, config?.data, {
        params: {
            abBucket: userAbBucket,
            ...extraParams
        }
    })
}