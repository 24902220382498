import React, {useState} from "react";
import styles from "../../../UI/MediaUpload/MediaUpload.module.css";
import DynamicObject from "../../../../models/dynamic-object";
import {Camera} from "../../../../assets/images";
import 'video-react/dist/video-react.css'; // import css
import {Player, ControlBar, VolumeMenuButton, BigPlayButton, PlayToggle} from 'video-react';
import {Video} from "./Video.interfaces";
import {Modal} from "../../../UI";

interface VideoBoxProps {
    children?: React.ReactNode;
    video: Video
}


const VideoBox = React.memo((props: VideoBoxProps): JSX.Element => {
    const {video = null} = props
    const [isPlay, setIsPlay] = useState(false)

    if (!video)
        return <div className={styles.icon}>
            <img src={Camera} alt={'image'} id="midContainer"/>
        </div>


    return (
        <div className={styles.icon}>
            <Player fluid={false} height={"100%"} width={"100%"} preload={'metadata'} ref={(player) => {
            }}>
                <BigPlayButton actions={{
                    play: () => {
                        console.log("play")
                        setIsPlay(true)
                    }
                }} />
                <source src={video.url} />
                {/*<ControlBar autoHide={false} disableDefaultControls>*/}
                {/*    <PlayToggle />*/}
                {/*    <VolumeMenuButton/>*/}
                {/*</ControlBar>*/}
                {isPlay && <VideoModal setIsPlay={setIsPlay} url={video.url} />}
            </Player>
        </div>
    )


}, videoPropsAreEqual)


const VideoModal = (props : DynamicObject) => {
    const {setIsPlay, url} = props

    return <Modal
        setIsOpen={setIsPlay}
        styles={{
            modal: {
                height: "80vh",
                width: "80vw",
            }
        }}
    >
        <Player fluid={false} height={"100%"} width={"100%"} autoPlay={true} preload={'metadata'} ref={(player) => {
        }}>

            <source src={url} />
            <ControlBar >
                <PlayToggle />
                <VolumeMenuButton/>
            </ControlBar>
        </Player>
    </Modal>
}


function videoPropsAreEqual(prevProps, nextProps) {
    return prevProps.video && nextProps.video || !prevProps.video && !nextProps.video
}
export default VideoBox