import React from "react";
import styles from './ContentContainer.module.css';

interface ContentContainerProps {
    children?: React.ReactNode;
}

const ContentContainer = (props: ContentContainerProps): JSX.Element => {
    // Check if the current URL includes "/add/review"
    const shouldAddPadding = !window.location.pathname.includes('/add/review');
    console.log(shouldAddPadding);
    return (
        <div 
            id="content-container" 
            className={`${styles["content-container"]} ${!shouldAddPadding ? styles["no-padding"] : ''}`}
            style={shouldAddPadding ? { padding: '16px' } : undefined}
        >
            {props.children}
        </div>
    );
};

export default ContentContainer;
