import './Overlay.css'
import ReactDOM from "react-dom";
import React from 'react';

interface ContentProps {
    children?: React.ReactNode;
}

const Overlay = (props: ContentProps) => {
    const portalElement = document.getElementById('overlay') as HTMLInputElement
    return (
        <>
            {
                ReactDOM.createPortal(<div className="overlay-container">
                    {props.children}
                </div>, portalElement)
            }
        </>


    )
}

export default Overlay;