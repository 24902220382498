import { useContext, useEffect, useState } from "react";
import { getDataStorage } from "../../../helpers/storage.helper";
import ContactUs from "./ContactUs";
import ListingCard from "./ListingCard";
import createNafathRequest from "./apis/create-request.api";
import DynamicObject from "../../../models/dynamic-object";
import { ICreateNafathReq, IRes } from "./types/create-nafath.type";
import FirstStep from "./FirstStep";
import RandomStep from "./RandomStep";
import checkNafathStatus from "./apis/check-status.api";
import { INafathStatus, IStatusRes } from "./types/check-status.type";
import { toastify } from "../../tostify/snackbarAlert";
import LanguageContext from "../../../storage/LanguageContext";
import { useNavigate } from "react-router-dom";
import useNafathStore from "../../../storage/NafathContext";

const Nafath = (): JSX.Element => {
  const storage = getDataStorage();
  const langCtx = useContext(LanguageContext);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const listingId = urlParams.get("post_id") ?? "";
  const view = urlParams.get("view") || "init";

  const [national_id, setNational_id] = useState<string>(
    sessionStorage.getItem("national_id")
      ? sessionStorage.getItem("national_id")
      : ""
  );

  let initialState = {
    id: 0,
    random: "0",
    mobile_site_deeplinks: {
      android: "",
      ios: "",
    },
    text: {
      button: {
        label: "",
      },
      steps: {
        step_label: {
          label: "",
          icon: "",
        },
        steps: [],
      },
      title: {
        label: "",
        icon: "",
      },
      subTitle: {
        label: "",
        icon: "",
      },
    },
  };

  const isRefresh =
    performance.navigation.type === performance.navigation.TYPE_RELOAD;
  if (isRefresh) {
    if (view === "random") {
      initialState = JSON.parse(sessionStorage.getItem("apiRes"));
    }
  }

  const {setIsNafath, setIsNafathMobile, setIsNafathRandomStepMobile, isNafath, isNafathMobile, setIsNafathRandomStepDesktop} = useNafathStore()
  const [response, setResponse] = useState<ICreateNafathReq>(initialState);

  useEffect(() => {
    setIsNafath(sessionStorage.getItem("loggingFlow") === "nafath" ||
        new URLSearchParams(window.location.search).get("flow") === "nafath")
        setIsNafathMobile(isNafath && storage.isMobile)
        setIsNafathRandomStepMobile(isNafathMobile && new URLSearchParams(window.location.search).get("view") === "random")
        setIsNafathRandomStepDesktop(isNafath && new URLSearchParams(window.location.search).get("view") === "random")
  }, [])

  const [error, setError] = useState<DynamicObject>();
  const [status, setStatus] = useState<INafathStatus>({
    status: null,
    message: "",
  });

  const handleCheckStatus = () => {
    checkNafathStatus({
      id: response?.id,
    })
      .then(({ data }: { data: IStatusRes }) => {
        setStatus(data.response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const startPolling = () => {
    const intervalId = setInterval(() => {
      handleCheckStatus();
    }, 2000);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, 200000);

    return { intervalId, timeoutId };
  };

  useEffect(() => {
    if (
      response?.random !== "0" &&
      (status.status === null || status.status === "WAITING")
    ) {
      const { intervalId, timeoutId } = startPolling();
      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      };
    }
  }, [response, status]);

  function removeSearchParam(param) {
    const url = new URL(window.location.href);
    url.searchParams.delete(param);
    sessionStorage.removeItem("apiRes");
    window.history.replaceState({}, "", url);
    window.location.reload();
  }

  useEffect(() => {
    if (status.status !== null && status.status !== "WAITING") {
      if (status.status === "EXPIRED" || status.status === "REJECTED") {
        toastify({
          toastType: "error",
          description: status?.message ?? "Something Went Wrong!",
          locale: langCtx.language,
          onClose: () => removeSearchParam("view"),
        });
      } else if (status.status === "COMPLETED") {
        toastify({
          toastType: "success",
          description: status?.message ?? "Something Went Wrong!",
          locale: langCtx.language,
          onClose: () => {
            navigate(
              `?flow=activate&post_id=${listingId}&pos=addlisting_success&actionSource=add`
            );
            sessionStorage.removeItem("apiRes");
          },
        });
      }
    }
  }, [status]);

  const handleClick = (e) => {
    createNafathRequest({
      national_id,
    })
      .then(({ data }: { data: IRes }) => {
        setResponse(data.response);
        sessionStorage.setItem("apiRes", JSON.stringify(data.response));
        const url = new URL(window.location.href);
        url.searchParams.append("view", "random");
        window.history.replaceState({}, "", url);
        setIsNafath(sessionStorage.getItem("loggingFlow") === "nafath" ||
        new URLSearchParams(window.location.search).get("flow") === "nafath")
        setIsNafathMobile(isNafath && storage.isMobile)
        setIsNafathRandomStepMobile(isNafathMobile && new URLSearchParams(window.location.search).get("view") === "random")
        setIsNafathRandomStepDesktop(isNafath && new URLSearchParams(window.location.search).get("view") === "random")
      })
      .catch((error) => {
        setError(error?.response?.data?.[0]?.message);
      });
  };

  return (
    <div className={`d-flex ${(view === "init" || !storage.isMobile) ? "mt-4" : ""} ${storage.isMobile ? "" : "row col-12"}`}>
      {view === "init" ? (
        <FirstStep
          national_id={national_id}
          setNational_id={setNational_id}
          onClickHandler={handleClick}
          error={error}
        />
      ) : (
        <RandomStep response={response} />
      )}
      {!storage.isMobile ? (
        <div className="side col-4">
          <ListingCard />
          <ContactUs />
        </div>
      ) : null}
    </div>
  );
};

export default Nafath;
