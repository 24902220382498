import React from "react";
import {IconButton, Menu, MenuItem} from "@mui/material";
import {StateDataType} from "./MediaUpload.interfaces";
import styles from './EditorMenu.module.css'
import {MakeAsCover} from '../../../assets/images'
import {useTranslation} from "react-i18next";
import {MoreHoriz} from "@mui/icons-material";
import {TfiTrash} from "react-icons/tfi";
import {MdOutlineModeEdit} from "react-icons/md";

interface EditorMenuProps {
    mediaKey: string,
    isMain: boolean,
    onClickMenuItemHandler: (e: React.MouseEvent, handler: () => any) => void,
    deleteImageHandler: (e: React.MouseEvent, mediaId: string) => void,
    onClickChangeMediaHandler: (hash: string, isMainImage: boolean) => void,
    changeMainImage: (hash: string) => void,
    state: StateDataType,
    isCanDelete: boolean
}

interface AnchorState {
    [key: string]: HTMLElement
}


const EditorMenu = (props: EditorMenuProps) => {
    const {
        mediaKey,
        isMain,
        onClickMenuItemHandler,
        deleteImageHandler,
        onClickChangeMediaHandler,
        changeMainImage,
        state
    } = props


    const {t} = useTranslation()
    const [anchorEl, setAnchorEl] = React.useState<null | AnchorState>(null);

    const open = Boolean(anchorEl && anchorEl[mediaKey]);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        event.preventDefault()

        setAnchorEl({[`${mediaKey}`]: event.currentTarget});
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
            <IconButton
                className={`${styles.container} menu-icon`}
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleOpen}
            >
                <MoreHoriz/>
            </IconButton>
            <Menu
                id="basic-menu"
                open={open}
                anchorEl={anchorEl && anchorEl[mediaKey]}
                MenuListProps={{'aria-labelledby': 'basic-button',}}
                onClick={handleClose}
            >
                {
                    props.isCanDelete && <MenuItem
                        onClick={(e: React.MouseEvent) => onClickMenuItemHandler(e, () => deleteImageHandler(e, state.medias[mediaKey].id as string))}>
                        <div className={`${styles.icon_label}`}>
                            <div className={`${styles.icon_dimensions}`}>
                                <TfiTrash className={`${styles.icon_color}`} style={{height: "100%", width: "100%"}}/>
                            </div>
                            <div>{t('delete')}</div>
                        </div>
                    </MenuItem>
                }
                <div className={`${styles.separator}`}></div>
                <MenuItem
                    onClick={(e: React.MouseEvent) => onClickMenuItemHandler(e, () => onClickChangeMediaHandler(mediaKey, isMain))}>
                    <div className={`${styles.icon_label}`}>
                        <div className={`${styles.icon_dimensions}`}>
                            <MdOutlineModeEdit className={`${styles.icon_color}`}
                                               style={{height: "100%", width: "100%"}}/>
                        </div>
                        <div>{t('change')}</div>
                    </div>
                </MenuItem>
                {!isMain && <div className={`${styles.separator}`}></div>}
                {!isMain && <MenuItem
                    onClick={(e: React.MouseEvent) => onClickMenuItemHandler(e, () => changeMainImage(mediaKey))}>
                    <div className={`${styles.icon_label}`}>
                        <div className={`${styles.icon_dimensions}`}>
                            <img className={`${styles.icon_color}`} src={MakeAsCover} alt={'image'}/>
                        </div>
                        <div>{t('makeMain')}</div>
                    </div>
                </MenuItem>}
            </Menu>
        </>
    )
}

export default EditorMenu