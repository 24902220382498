import React, { useContext } from "react";
import styles from "./RedirectBtn.module.css";
import { Props, RefDataType } from "./interfaces";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {
  getSizes,
  injectUtmsIntoWebviewUrl,
} from "../../../../helpers/functions";
import PostContext from "../../../../storage/PostContext";
import DynamicObject from "../../../../models/dynamic-object";
import { getDataStorage } from "../../../../helpers/storage.helper";
import LanguageContext from "../../../../storage/LanguageContext";
import Cookies from "js-cookie";

const RedirectBtn = React.forwardRef<RefDataType, Props>((props, ref) => {
  const { config, nextApi } = props;
  const langCtx = useContext(LanguageContext);

  const postCtx = useContext(PostContext);
  const storage = getDataStorage();
  const { title, subTitle, button = null } = config;

  const containerStyle = {
    "--title-font-weight": title?.style?.bold ? "bold" : "normal",
    "--title-font-size": getSizes(title?.style?.size) ?? "18px",
    "--title-font-color": title?.color ?? "#000",
    "--subtitle-font-weight": subTitle?.style?.bold ? "bold" : "normal",
    "--subtitle-font-size": getSizes(subTitle?.style?.size) ?? "18px" ,
    "--subtitle-font-color": subTitle?.color ?? "#000", 
}as React.CSSProperties

  const buttonStyle = {
    border: `1px ${config.button?.border?.type} ${config.button?.border?.color}`,
    borderRadius: config.button?.border?.round ? "8px" : "0px",
  } as React.CSSProperties;

  const resetAndSubmit = (resetList: string[]) => {
    let httpConfig = postCtx as DynamicObject;
    for (let identifier = 0; identifier < resetList.length; identifier++) {
      httpConfig = {
        config: {
          data: {
            [`${resetList[identifier]}`]: ``,
          },
        },
      };
      httpConfig["config"]["data"][resetList[identifier]] = ``;
    }
    nextApi?.request(httpConfig);
  };

  const onActionHandler = () => {
    if (config?.stepRole) {
      //skip behavior
      if (config?.stepRole?.submit && config?.stepRole?.reset) {
        resetAndSubmit(config?.stepRole?.resetList);
      } else {
        // submit
        const httpConfig: DynamicObject = {
          config: {
            data: {},
          },
        };
        nextApi?.request(httpConfig);
      }
    } else if (config?.target) {
      if (config.target?.webviewURL) {
        const webviewURLWithUTMS = injectUtmsIntoWebviewUrl(
          config.target.webviewURL
        );
        window.open(webviewURLWithUTMS);
      } else if (config.target?.deeplink) {
        const deeplink = config.target.deeplink.replace("xx", storage.country);
        if(deeplink.includes("dynaFormAction/close")) {
          window.location.replace(Cookies.get('addPostRef').replace("{post_id}", sessionStorage.getItem("post_id")))
          return
        }
        window.location.replace(
          `${process.env.REACT_APP_JO_SOOQ_URL.replace("XX.", "").replace(
            "xx.",
            ""
          )}/site/dl?requestUri=${deeplink}`
        );
      } else return;
    } else return;
  };

  return button ? (
    <div
      className={`${styles.container} justify-content-between`}
      style={{
        ...containerStyle,
        background: config?.background?.color,
        border: `1px ${config?.border?.type} ${config?.border?.color}`,
        borderRadius: config?.border?.round ? "8px" : "0px",
      }}
    >
      <div>
        <div className={`row`}>
          <div className={`col-md-12`}>
            <h1 className={`${styles.title}`}>{config.title.locale}</h1>
          </div>
        </div>
        {config?.subTitle && (
          <div className={`row`}>
            <span className={`col-md-12 ${styles.subtitle}`}>
              {config.subTitle.locale}
            </span>
          </div>
        )}
        <div
          className={`row col-5 mt-3 ms-1 align-items-center p-3 g-0`}
          style={{ ...buttonStyle, cursor: "pointer" }}
          onClick={onActionHandler}
        >
          <div className="col-md-6 flex-grow-1">{button.text.locale}</div>
          <div className={`col-md-2 ${styles.icon}`}>
            {langCtx.language === "en" ? (
              <IoIosArrowForward size={25} strokeWidth={3} />
            ) : (
              <IoIosArrowBack size={25} strokeWidth={3} />
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`${styles.container} justify-content-between`}
      style={{...containerStyle, cursor: "pointer"}}
      onClick={onActionHandler}
    >
      <div className={`${styles.box}`}>
        <div className={`${storage.isMobile ? "d-flex justify-content-between " : "row"} ${config?.subTitle ? "" : "align-items-center"}`}>
          <div className={`${config?.subTitle ? "col-md-12" : "col-md-2"}`}>
            <h1 className={`${styles.title}`}>{config.title.locale}</h1>
          </div>
          {!config?.subTitle && (
            <div className={`col-md-1 ${styles.icon}`}>
            {langCtx.language === "en" ? (
              <IoIosArrowForward />
            ) : (
              <IoIosArrowBack />
            )}
          </div>
          )}
        </div>
        {config?.subTitle && (
          <div className={`row mt-3`}>
            <div className={`col-md-6`}>
              <h6 className={`col-md-12 ${styles.subtitle}`}>{config.subTitle.locale}</h6>
            </div>
            <div className={`col-md-6 ${styles.icon}`}>
              {langCtx.language === "en" ? (
                <IoIosArrowForward />
              ) : (
                <IoIosArrowBack />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default RedirectBtn;
