import {StateDataType as Media} from "../components/UI/MediaUpload/MediaUpload.interfaces";
import DynamicObject from "./dynamic-object";

export interface MediaResult {
    [key: string]: Media
}

export interface MediasOrder {
    [key: string]: string[]
}


export default class MediasCacher {

    private _medias: DynamicObject
    medias_order: MediasOrder = {}
    saved_orders: MediasOrder = {}
    static instance: MediasCacher


    private constructor() {
        this._medias = {}
    }

    static getInstance() {
        if (!this.instance)
            this.instance = new MediasCacher()

        return this.instance
    }


    fireEvent(type = "update-media") {

        window.dispatchEvent(new CustomEvent(type, {
            detail: {
                medias: this._medias
            }
        }))
    }

    clear(){
        this._medias = {}
        this.medias_order = {}
        this.saved_orders = {}
    }

    get(id: string) {
        if (!this._medias[id])
            return

        return {
            ...this._medias[id],
            medias: {
                ...this._medias[id].medias
            }
        }

    }

    all() {
        return this._medias
    }

    initialMedia(id: string) {
        if (this._medias[id])
            return

        this._medias[id] = {
            medias: []
        }
    }

    changeOrder(key: string, order: string[]) {
        this.medias_order[key] = order
    }


    update(id: string, media: Media) {
        this._medias[id] = media
        this.fireEvent(`update-media-${id}`)
    }


    concat(medias: MediaResult) {

        for (const key in medias) {
            const mediaDraft: DynamicObject = {}
            const mediaCache: DynamicObject = {}
            let mediasBuffer: DynamicObject = {}

            for (const fileKey in medias[key].medias) {
                mediaDraft[medias[key].medias[fileKey].uri] = {
                    ...medias[key].medias[fileKey],
                    hash: fileKey
                }
            }


            for (const fileKey in this._medias[key]?.medias) {
                mediaCache[this._medias[key].medias[fileKey].uri] = {
                    ...this._medias[key].medias[fileKey],
                    hash: fileKey
                }
            }


            mediasBuffer = {
                ...mediaCache
            }


            for (const uri in mediaDraft) {
                mediasBuffer[uri] = {
                    ...mediaCache[uri],
                    ...mediaDraft[uri],
                }
            }
console.log(mediasBuffer, 'mediasBuffer')

            let mediaResult: DynamicObject = {}

            for (const uri in mediasBuffer) {
                const hash = mediasBuffer[uri].hash
                delete mediasBuffer[uri].hash
                mediaResult[hash] = {
                    ...mediasBuffer[uri]
                }
            }


            mediaResult = Object.keys(mediaResult).sort().reduce(
                (obj, key) => {
                    obj[key] = mediaResult[key];
                    return obj;
                },
                {}
            );

            console.log(medias, 'mediaResult')
            MediasCacher.getInstance().medias_order[key] = Object.keys(mediaResult)
            MediasCacher.getInstance().saved_orders[key] = Object.keys(mediaResult)


            this._medias[key] = {
                medias: {
                    ...mediaResult
                },
                main_media: medias[key].main_media
            }

            this.fireEvent(`update-media-${key}`)

        }


    }




}
