import React, {useContext} from "react";
import {FormHelperText} from "@mui/material";
import DynamicObject from "../../../models/dynamic-object";
import LanguageContext from "../../../storage/LanguageContext";


interface Props {
    validation: DynamicObject
}

const ValidationError = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {validation} = props
    const langCtx = useContext(LanguageContext)
    const alignCenter = (langCtx.language == 'en') ? 'left' : 'right'
    const isDesktop : boolean = true;
    return (
        isDesktop ?
        <div ref={ref}>
            {validation.error && <FormHelperText style={{
                margin: "5px 0px ",
                paddingTop: "2px",
                textAlign: alignCenter,
                color: "black",
                fontWeight: "700",
                borderRadius: "5px",
                border: "1px dashed #F14F75",
                fontSize: "14px",
                padding: "4px 11px 0px 11px",
                background: "rgba(247, 213, 222, 0.35)"
            }}>
                {` ${validation.error}`}
            </FormHelperText>

            }
        </div>
            :
            <div ref={ref}>
                {validation.error && <FormHelperText style={{
                    color: "red",
                    fontSize: "15px",
                    paddingTop: "2px",
                    textAlign: alignCenter
                }}>
                    {`*  ${validation.error}`}
                </FormHelperText>

                }
            </div>
    )
})

export default ValidationError