import React, {useContext, useState} from "react";
import {Props} from "./interface";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import UIContext from "../../../../storage/UIContext";
import ListCheckboxOptionsMobile from "./ListCheckboxOptionsMobile/ListCheckboxOptionsMobile";
import ListCheckboxOptionsDesktop from "./ListCheckboxOptionsDesktop/ListCheckboxOptionsDesktop";
import {getDataStorage} from "../../../../helpers/storage.helper";

interface CurrentProps extends Props {
    onChangeValues?: (values) => void,
}

const ListCheckboxOptions = React.forwardRef<RefDataType<string>, CurrentProps>((props, ref) => {

    const {config, onChangeValues, values} = props
    const storage = getDataStorage()
    /*---------------------------------------------------------------*/
    /************************************
     *   SET & GET VALIDATION AND STATES
     * *********************************/
    const {state, validation} = useDataPayload<string>({
        ref,
        config: props.config,
        value: values || undefined,
        setConfig: props.setConfig,
        defaultValue: null
    })


    return (
        <>
            {storage.isMobile ? <ListCheckboxOptionsMobile
                    onChangeValues={onChangeValues}
                    state={state} validation={validation}
                    config={props.config} setConfigData={props.setConfigData}
                    selectFieldConfig={props.selectFieldConfig}
                    selectFieldLevels={props.selectFieldLevels}
                    ref={ref}></ListCheckboxOptionsMobile> :
                <ListCheckboxOptionsDesktop
                    onChangeValues={onChangeValues}
                    state={state} validation={validation}
                    config={props.config} setConfigData={props.setConfigData}
                    selectFieldConfig={props.selectFieldConfig}
                    selectFieldLevels={props.selectFieldLevels}
                    ref={ref}></ListCheckboxOptionsDesktop>
            }
        </>
    )
})

export default ListCheckboxOptions