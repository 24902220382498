import React from "react";
import Item from "./Item";
import {Props} from "./interface";
import Carousel from 'react-material-ui-carousel'
import FlowReferences from "../../../../flow-references";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";

const CardSlider = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    const {validation, state} = useDataPayload<string>({ref, config: props.config, setConfig: props.setConfig,defaultValue:""})

    return (<div style={{width: "100%"}}>
        <Carousel navButtonsAlwaysInvisible={true}>
            {
                props.config.cards.map((item, i) => <Item info={item}/>)
            }
        </Carousel>
    </div>)
})
export default CardSlider















