import React, {useContext} from "react";
import {Props} from "./interface";
import styles from './ListRadioOptions.module.css'
import UIContext from "../../../../storage/UIContext";
import PostContext from "../../../../storage/PostContext";
import DynamicObject from "../../../../models/dynamic-object";
import {FormControlLabel, Radio, RadioGroup, Button as MuiButton} from "@mui/material";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import {getSizes} from "../../../../helpers/functions";


const ListRadioOptions = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

        const {config} = props


        /************************************
         *             CONTEXT
         * *********************************/
        const postCtx = useContext(PostContext)
        const uiCtx = useContext(UIContext)

        /*-------------------------------------
         *              HOOKS
         * ----------------------------------*/

        /************************************
         *   SET & GET VALIDATION AND STATES
         * *********************************/
        const {state, validation} = useDataPayload<number | string>({
            ref,
            config: props.config,
            defaultValue: ""
        })

        /*-------------------------------------
         *           FUNCTIONS
         * ----------------------------------*/

        const updateContext = (id: string | number, label: string) => {
            postCtx.addToForm(config.identifier, id)
            const previewConfig: DynamicObject = postCtx.previewStep
            const lastPreview = previewConfig.childs.filter((child: any) => {
                if (child.identifier === config.identifier) {
                    const values = child.values
                    values[0] = {...values[0], label: label, id: id}
                    return {
                        ...child,
                        values: [...values]
                    }
                } else {
                    return child
                }
            })

            const finalConfig = {
                ...previewConfig,
                childs: lastPreview
            }
            props.setConfigData(finalConfig);
        }
        /************************************
         *        ACTION HANDLER
         * *********************************/
        const addItemHandler = (id: number | string, label: string) => {
            state.set(id)
            validation.clear()
            if (!props.config?.submit) {
                updateContext(id, label);
            }

        }
        const getLabelById = (id: string | number): string => {
            const item = props.config?.values?.find((obj) => obj.id === id);
            return item ? item.label : ''; // Return
        }
        const pushItems = () => {
            updateContext(state.value, getLabelById(state.value));
        }
        /*---------------------------------------------------------------*/


        /************************************
         *    GET ITEMS AS COMPONENT
         * *********************************/
        const items = config?.values?.map((item, i) => {
            return (
                    <FormControlLabel  className={`${styles.item} cursor__pointer`}
                        control={<Radio/>}
                                      onClick={(e: React.MouseEvent<HTMLElement>) => addItemHandler(item.id, item.label)}
                                      label={item.label} value={item.id}/>
             )
        });

        return (
            <div

            >
                <div className={`${styles.body} rounded fw-bold`}>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        defaultValue={state.value}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            padding:"0px 2px"
                        }}
                    >
                        {
                            items
                        }
                    </RadioGroup>
                    <ValidationError validation={validation}/>
                    {props.config?.submit && props.config?.submit?.enabled &&
                        <div className={`${props.config?.submit?.sticky ? styles.button_container : ''}`}>
                            <div className={`d-flex justify-content-center align-items-center w-100`}>
                                <MuiButton style={{
                                    height: "50px",
                                    width: "100%",
                                    fontWeight: props.config.submit?.style?.bold ? 'bold' : 'normal',
                                    fontSize: getSizes(props.config.submit?.style?.size ?? 'medium'),
                                    color: props.config.submit?.color ?? 'white',
                                    backgroundColor: config.submit?.background.color ?? 'white',
                                    borderRadius: "8px"
                                }} variant='contained'
                                           className={`d-block`}
                                           onClick={(e) => pushItems()}
                                >{props.config?.submit?.locale}</MuiButton>
                            </div>

                        </div>}
                </div>
            </div>
        )
    }
)
export default ListRadioOptions