import { AxiosResponse } from "axios";
import ArachnaApi from "./requests/arachna-api";
import DynamicObject from "../models/dynamic-object";
import { AddPostFilterResponse } from "../interceptors";

let currentPromise: Promise<AxiosResponse> | null = null;

export default function deletePost(
  config?: DynamicObject
): Promise<AxiosResponse> {
  if (!currentPromise) {
    const request = ArachnaApi.getInstance().request;
    request.interceptors.response.use(
      AddPostFilterResponse().success,
      AddPostFilterResponse().error
    );

    currentPromise = request
      .get(
        `${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/deleteReason/deeplink/${config?.post_id}`,
        {}
      )
      .finally(() => {
        currentPromise = null;
      });

    return currentPromise;
  } else {
    return currentPromise;
  }
}
