import generate_jwt from "../libs/jwt/jwt";
import {getDataStorage} from "./storage.helper";
import Auth, {UserIdentity} from "opensooq-sso";


export const generateToken = () => {
    const storage = getDataStorage()
    const userInfoId = storage.userInfo?.id || ''
    return generate_jwt(storage.tgtTimestamp, storage.tgtTicket, parseInt(userInfoId as string))
}

export const generateAuthToken = async () => {
    return await Auth.getInstance().getToken(true,true)
}