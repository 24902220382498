import { Skeleton } from "@mui/material";
import React from "react";

export default function SelectFieldLoader({ rowsNumber }: {rowsNumber: number}) {



  const renderItems = () => {
    const elements = [];

    for (let row = 0; row < rowsNumber; row++) {
      elements.push(<Skeleton className="mx-3" width={"70%"} height={45} />);
    }

    return elements;
  };


  return (
    <>
      {
       renderItems()
      }
    </>
  );
}
