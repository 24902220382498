import axios, { AxiosResponse } from "axios";
import { getDataStorage } from "../../../../helpers/storage.helper";
import { checkOnBehalfLogInFlow } from "../../../../helpers/functions";
import Cookies from "js-cookie";
import { generateAuthToken } from "../../../../helpers/auth.helper";
import i18n from "../../../../i18n";

export default async function getListingViewDataLight({
  listingId,
}: {
  listingId: string;
}): Promise<AxiosResponse> {
  const storage = getDataStorage();

  const device_uuid = storage.device_uuid || storage.userInfo?.id || "";

  let token = "";
  if (await checkOnBehalfLogInFlow()) {
    token = Cookies.get("EmployeeToken");
  } else {
    token = await generateAuthToken();
  }

  return axios.get(
    `${
      process.env.REACT_APP_NODE_API_URL_CLIENT_SSO
    }/listings/listing-view/light/${listingId}`,
    {
      headers: {
        source: storage.source,
        country: storage.country,
        [`enforce-language`]: i18n.language,
        [`session-id`]: device_uuid,
        [`X-Tracking-UUID`]: String(device_uuid),
        "Authorization": "Bearer " + token,
        "release-version": storage.flowData.flow.releaseVersion,
      },
    }
  );
}
