import React, {useContext, useEffect} from "react";
import {useHttp} from "../../../hooks";
import DynamicObject from "../../../models/dynamic-object";
import {AxiosResponse} from "axios";
import {getFinalPayload, removeSessionByKey, setSessionByKey} from "../../../helpers/functions";
import PostContext from "../../../storage/PostContext";
import MapperFlow from "../../MapperFlow";
import {Args} from "../../../hooks/use-http/interfaces";
import {ComponentLoader} from "../../UI";
import {useTranslation} from "react-i18next";
import {MdEditOff} from "react-icons/md";
import UIContext from "../../../storage/UIContext";
import {MediaMapper} from "../../../helpers/media.functions";
import LanguageContext from "../../../storage/LanguageContext";
import {
    configEditPostApi,
    configInitApi,
    configJobApplyApi,
    configNextApi,
    configRequestFeatureApi
} from '../../../config/api.config'
import MediasCacher from "../../../models/medias-cacher";
import FlowReferences from "../../../flow-references";
import {useNavigate} from "react-router-dom";


const RequestFeature = (): JSX.Element => {

    /************************************
     *              CONSTANT
     * *********************************/
    /*CONTEXT*/
    const postCtx = useContext(PostContext)
    const languageCtx = useContext(LanguageContext)
    const uiCtx = useContext(UIContext)
    /*TRANSLATE*/
    const navigate = useNavigate()

    const {t} = useTranslation()
    /*URL PARAMS*/
    const urlParams = new URLSearchParams(window.location.search)

    /*---------------------------------------------------------------
    *                       HTTP REQUEST
    * --------------------------------------------------------------*/
    /************************************
     *         INIT REQUEST API
     * *********************************/
    const RequestFeatureApi = useHttp<DynamicObject>(configRequestFeatureApi)
    const onSuccessInitApi = (response: AxiosResponse) => {
        uiCtx.setLoading(false)
        postCtx.updateStep({
            workflow_id: response.data.workflow_id,
            draft_id: response.data.draft.id,
            step: response.data.step,
            start_time: Date.now(),
            previous: response.data.previous,
            flow_type: 'requestFeature'
        })
        setSessionByKey('draftId', response.data.draft.id)
        setSessionByKey('flow-type','requestFeature')
        navigate(`${response.data?.step?.uri}`)
    }


    RequestFeatureApi.request({
        config: {
            language: languageCtx.language,
            featureType: urlParams.get('feature-type')
        },
        callbacks: {
            success: onSuccessInitApi
        },
    })
    /************************************
     *       NEXT API REQUEST API
     * *********************************/
    const nextApi = useHttp<DynamicObject>(configNextApi)
    const request = nextApi.request
    nextApi.request = function (args?: Args) {
        request({
            ...args,
            config: {
                flow_type:'requestFeature',
                ...args?.config,
                data: {
                    ...args?.config?.data,

                },
                language: languageCtx.language
            },
            callbacks: {
                ...args?.callbacks,
                before: () => {
                    uiCtx.setLoading(true)
                }
            }
        })
    }

    /*---------------------------------------------------------------
    *                       EFFECTS
    * --------------------------------------------------------------*/
    useEffect(() => {
        if (!postCtx.data.isEdit) {
            uiCtx.setLoading(true)
        }

    }, [languageCtx.language])

    /*---------------------------------------------------------------
    *                       FUNCTION
    * --------------------------------------------------------------*/


    return (
        <div>
            {uiCtx.isLoading && <ComponentLoader/>}
            {postCtx.data?.step && !uiCtx.isLoading &&
                <MapperFlow config={postCtx.data.step} nextApi={nextApi}/>}
        </div>
    )
}

export default RequestFeature