import { TechDimensions } from "../dimensions/TechDimensions";
import { GeoDimensions } from "../dimensions/GeoDimensions";
import { SessionDimensions } from "../dimensions//SessionDimensions";
import { UserDimensions } from "../dimensions/UserDimensions";
import { getDataStorage } from "../../helpers/storage.helper";
import { ActionDimensions } from "../dimensions/ActionDimensions";
import Cookies from "js-cookie";
import { useEffect } from "react";

const useLogging = ()=>{
    useEffect(()=>{
        const DEFAULT_VALUE = 'NA';
        const urlParams = new URLSearchParams(window.location.search);
        const urlPoe = urlParams.get('pos');
        const cookiePoe = Cookies.get('sessionFunnelPointOfEntry');
        const cookies = getDataStorage();
        ActionDimensions.getInstance().sessionFunnelPointOfEntry = urlPoe ? urlPoe : cookiePoe ?? DEFAULT_VALUE;
        Cookies.remove('sessionFunnelPointOfEntry');
        GeoDimensions.getInstance().geoAppCountry = GeoDimensions.getInstance().geoPostCountry = cookies.country ?? DEFAULT_VALUE;
    
        const userId = (cookies && cookies.userInfo && cookies.userInfo.id) ? cookies.userInfo.id.toString() : DEFAULT_VALUE;
        UserDimensions.getInstance().userMemberId = userId;
        UserDimensions.getInstance().userTrackingId = (cookies && cookies.device_uuid && cookies.device_uuid.length > 0) ? cookies.device_uuid : DEFAULT_VALUE;
    
        TechDimensions.getInstance().language = cookies.lang ?? DEFAULT_VALUE
        TechDimensions.getInstance().version = process.env.REACT_APP_PUBLIC_VERSION ?? DEFAULT_VALUE;
        
        SessionDimensions.getInstance().sessionId = (cookies.tgtTicket) ?? DEFAULT_VALUE;
    }, [])
}

export {useLogging}