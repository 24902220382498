import { Props } from "./../interface";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from './ListCellOptionsMobile.module.css'
import UIContext from "../../../../../storage/UIContext";
import PostContext from "../../../../../storage/PostContext";
import DynamicObject from "../../../../../models/dynamic-object";
import useSearch from "../../../../../hooks/use-search/use-search";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import languageContext from "../../../../../storage/LanguageContext";
import { getImgPath, getItemsAsString, getSizes, RTL_DIR_SWITCHER_IDENTIFIERS } from "../../../../../helpers/functions";
import ValidationError from "../../../../UI/ValidationError/ValidationError";
import useDataPayload, { RefDataType } from "../../../../../hooks/use-data-payload/use-data-payload";
import FlowReferences from "../../../../../flow-references";
import NotAutoSaveState from "../../../../../not-auto-save-state";

interface CurrentProps extends Props {
    state: DynamicObject,
    validation: DynamicObject,
}

const ListCellOptionsMobile = React.forwardRef<RefDataType<string>, CurrentProps>((props, ref) => {

    const { config, state, validation } = props

    /*-------------------------------------
     *              HOOKS
     * ----------------------------------*/

    /************************************
     *      CUSTOME SEARCH HOOK
     * *********************************/
    const { filteredResults, Search } = useSearch({
        items: config.values,
        search: {
            placeholder: config?.search?.placeholder?.locale
        }
    })

    /************************************
     *          TRANSLATION
     * *********************************/

    const { t } = useTranslation();

    /************************************
     *            CONTEXT
     * *********************************/
    const langCtx = useContext(languageContext)
    const postCtx = useContext(PostContext)
    const uiCtx = useContext(UIContext)


    /************************************
     *            CONTEXT
     * *********************************/
    const labelStyle = {
        fontWeight: config.cell.label.style?.bold ? "bold" : "normal",
        fontSize: getSizes(config.cell.label.style?.size),
        color: config.cell.label.color,
        direction: langCtx.language == 'ar' ? 'ltr' : '',
        textAlign: langCtx.language == 'ar' ? "end" : ''
    } as React.CSSProperties

    // edge case for engineSize, the text is in arabic and the numbers are in english
    if (RTL_DIR_SWITCHER_IDENTIFIERS.indexOf(config.identifier) != -1 && langCtx.language == 'ar') {
        labelStyle.direction = 'rtl'
    }


    const oldValue = postCtx.data.form[config.identifier]

    /*-------------------------------------
     *            FUNCTIONS
     * ----------------------------------*/

    const rest = () => {
        console.log("here in reset")

        if (!config.linked?.length) {
            return
        }

        config.linked.map(identifier => {
            NotAutoSaveState.set(identifier)
            if (FlowReferences.get(identifier)?.state)
                FlowReferences.get(identifier).state.clear()
        })
    }


    /************************************
     * UPDATE ON CONTEXT + NEXT API
     * *********************************/
    const addItemHandler = (id: number | string, label: string) => {

        const previewConfig: DynamicObject = postCtx.previewStep

        if (!props.config.autoSave) {
            postCtx.addToForm(config.identifier, id)
        }
        let child = postCtx.previewStep as DynamicObject
        for (const level of props.selectFieldLevels) {
            child = child.childs[level]
        }


        const values = child?.values
        let currentValues = [];//in case re-render without return the child in preview

        if (values) {
            child.values[0] = { ...values[0], label: label, id: id }
        } else {
            currentValues[0] = { group_label: null, icon: null, id: id, label: label }
        }

        if (props.onChangeValues) {
            return props.onChangeValues(id, values ? values : currentValues)
        }
        state.set(id)
        FlowReferences.get(config.identifier).validation.clear()
        // const setDataToPreview = (config, values, levels) => {
        //
        //     if (levels.length == 1)
        //     {
        //         console.log(config, 'config rec')
        //         return config
        //     }
        //
        //     const level = levels.shift()
        //     config.childs[level] = setDataToPreview(config.childs[level], values, levels)
        //
        //     return config
        // }
        //
        // console.log(setDataToPreview(postCtx.previewStep, values, props.selectFieldLevels))

        // props.setConfigData(previewConfig);

        const formData = {
            ...postCtx.data.form,
            ...props.tempFormData,
            [config.identifier]: id
        }
        // const errors = [
        //     ...uiCtx.errors.filter((error: DynamicObject) => error.field !== props.config.identifier)
        // ]
        //
        // if (errors.length !== uiCtx.errors.length)
        //     uiCtx.setErrors(errors.filter((error: DynamicObject) => error.field !== props.config.identifier))

        // uiCtx.setErrors(errors)

        // postCtx.updateData({
        //     form: {
        //         ...formData,
        //     }
        // })
    }


    return (
        <div className={styles.container}>
            {/* SEARCH */}
            {config?.search &&
                <Search></Search>
            }
            {/* ITEMS */}
            <div className={styles.items_container}>
                {
                    filteredResults.length < 1 && (
                        <div className={`d-flex ${styles.no_found_data}`}>
                            <h3>
                                {t('noResults')}
                            </h3>
                        </div>
                    )
                }
                {
                    filteredResults.map(item => {
                        if (item.id) {
                            return (
                                <div key={item.id} className={`d-flex ${styles.item}`}
                                    onClick={(e: React.MouseEvent<HTMLElement>) => addItemHandler(item.id, item.label)}>
                                    <div className={`col-md-9 col-10 ${styles.image_text}`}>
                                        {/*ITEM ICON*/}
                                        {

                                            item.icon && (
                                                <div className={styles.image_container}>
                                                    <img className={styles.image}
                                                        style={{ width: config.identifier.includes("categoriesMain") ? "50px" : "30px" }}
                                                        src={getImgPath(item.icon)} />
                                                </div>
                                            )
                                        }


                                        {/*ITEM LABEL*/}
                                        <div className={styles.text}>
                                            <h1 style={labelStyle}>{item.label}</h1>
                                        </div>
                                    </div>
                                    {/*ITEM ARROW */}
                                    <div className={`col-md-3 col-2 ${styles.icon}`}>
                                        {(langCtx.language == 'en') ?
                                            <IoIosArrowForward style={{ height: "21.2px", width: "22.85px" }} /> :
                                            <IoIosArrowBack style={{
                                                height: "21.2px",
                                                width: "22.85px"
                                            }} />}                                        </div>
                                </div>
                            )
                        }
                        return (
                            <h3 style={{ textAlign: "center" }}>{item.label}</h3>
                        )
                    }
                    )
                }
            </div>
            <ValidationError validation={validation} />

        </div>
    )
}
)
export default ListCellOptionsMobile