import React, {useEffect, useMemo, useState} from "react";
import DynamicObject from "../../../models/dynamic-object";
import * as flow from "./index";
import {flowLevels, flowRefs} from "../../../storage/PostContext";
import {attributes} from "js-cookie";
import RefType from "../../../models/ref-type";




const Render = (props: DynamicObject): JSX.Element => {

    const typeName = props.config.type.capitalize();
    const Component = flow[typeName as keyof typeof flow]
    flowLevels[props.config.identifier] = props.levels

    return (
       <Component
                  {...props}
                  config={props.config}
                  setConfigData={props.setConfigData}
                  flowRefs = {flowRefs}
                  setConfig={props.setConfig}
                  ref={flowRefs[props.config.identifier]}
       >{props.children}</Component>
    )
}

export default Render