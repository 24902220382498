import React, {useContext, useState} from "react";
import styles from './Button.module.css'
import {Props, RefDataType} from './interfaces';
import {Button as MuiButton} from "@mui/material";
import {getImgPath, getSizes, injectUtmsIntoWebviewUrl} from "../../../../helpers/functions";
import Modal from "../../../UI/Modal/Modal";
import ConfirmationModel from "./ConfirmationModel";
import TakeAction from "../../../../api/take-action";
import PostContext from "../../../../storage/PostContext";
import {getDataStorage} from "../../../../helpers/storage.helper";
import DynamicObject from "../../../../models/dynamic-object";
import Cookies from "js-cookie";

const configTakeAction = {
    callback: TakeAction,
    initialData: {},
    withLoader: true
}

const Button = React.forwardRef<RefDataType, Props>((props, ref) => {


    const {config, nextApi} = props
    const postCtx = useContext(PostContext)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isYes, setIsYes] = useState(false)
    const storage = getDataStorage()
    const rightAlignment = props.config.text?.align.toLowerCase() == 'right' && 'end'


    // const takeActionApi = useHttp<DynamicObject>(configTakeAction)
    //
    //
    // const request = takeActionApi.request
    //
    // const onSuccessTakeActionApi = (response: AxiosResponse) => {
    //     postCtx.updateStep({
    //         workflow_id: response.data.workflow_id,
    //         draft_id: response.data.draft.id,
    //         step: response.data.step,
    //         start_time: Date.now(),
    //         previous: response.data.previous
    //     })
    // }
    //
    // takeActionApi.request = (args?: Args) => {
    //     return request({
    //         ...args,
    //         config: {
    //             ...args?.config,
    //             data: {
    //                 ...args?.config?.data,
    //                 ...postCtx.data.form,
    //                 draftId: postCtx.data,
    //                 time_spent_ms: Date.now() - postCtx.data.start_time,
    //
    //             }
    //         },
    //         callbacks: {
    //             ...args?.callbacks,
    //             success: onSuccessTakeActionApi,
    //         }
    //     })
    // }

    const buttonStyle = {
        fontWeight: props.config.text?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.text?.style?.size),
        color: props.config.text.color,
        textAlign: props.config.text.align,
        backgroundColor: props.config.background.color,
        borderRadius: props.config?.border?.round && '8px',
        border: props.config?.border && `1px ${props.config?.border?.type.toLowerCase() == 'none' && props.config?.border?.color ? 'solid' : props.config?.border?.type} ${props.config?.border?.color}`,
        textTransform: "unset",
        letterSpacing: "0",
        '--font-weight': props.config.text?.style?.bold ? "bold" : "normal",
        '--font-size': getSizes(props.config.text?.style?.size),
        '--font-color': props.config.text.color,
        margin:storage.isMobile?'5px 0px':''
    } as React.CSSProperties

    const iconStyle = {
        height: buttonStyle.fontSize < '20px' ? '25px' : '30px',
        width: buttonStyle.fontSize < '20px' ? '25px' : '30px',
    } as React.CSSProperties

    const resetAndSubmit = (resetList: string[]) => {
        let httpConfig = postCtx as DynamicObject
        for (let identifier = 0; identifier < resetList.length; identifier++) {
            httpConfig = {
                config: {
                    data: {
                        [`${resetList[identifier]}`]: ``,
                    }
                }
            }
            httpConfig["config"]["data"][resetList[identifier]] = ``
        }
        nextApi?.request(httpConfig)
    }
    const onActionHandler = () => {
        if (props.config.confirmation) {
            setIsOpen(true)
        } else if (props?.config?.stepRole) {
            //skip behavior
            if (props?.config?.stepRole?.submit && props?.config?.stepRole?.reset) {
                resetAndSubmit(props?.config?.stepRole?.resetList)
            } else {
                // submit
                const httpConfig: DynamicObject = {
                    config: {
                        data: {}
                    }
                }
                nextApi?.request(httpConfig)
            }
        } else {
            if (props.config.target.webviewURL){
                const webviewURLWithUTMS = injectUtmsIntoWebviewUrl(props.config.target.webviewURL);
                window.open(webviewURLWithUTMS);
            }else if (props.config.target.deeplink) {
                const deeplink = props.config.target.deeplink.replace("xx", storage.country)
                if(deeplink.includes("dynaFormAction/close")) {
                    window.location.replace(Cookies.get('addPostRef').replace("{post_id}", sessionStorage.getItem("post_id")))
                    return
                  }
                window.location.replace(`${process.env.REACT_APP_JO_SOOQ_URL.replace("XX.","").replace("xx.","")}/site/dl?requestUri=${deeplink}`)
            }
            else return
        }


    }

    const buttonActionHandler
        = (event: React.MouseEvent<HTMLElement>, value: boolean) => {
        if (value) {
            setIsYes(true)


        }
        setIsOpen(false);

    }

    const Text = () => {
        if (props.config?.text?.parse_as?.toLowerCase() == 'html')
            return <div dangerouslySetInnerHTML={{__html: props.config?.text?.locale}}/>;
        else
            return <span>{props.config.text.locale}</span>
    }
    return (
        <div>
            {
                isOpen && props.config?.confirmation &&
                <Modal title={props.config?.confirmation?.title} setIsOpen={setIsOpen}>
                    <ConfirmationModel config={props.config} buttonActionHandler={buttonActionHandler}/>
                </Modal>
            }
            <MuiButton variant='outlined' style={buttonStyle}
                       onClick={(e: React.MouseEvent<HTMLElement>) => onActionHandler()}
                       className={`d-block ${styles.button_body}`}>
                <div
                    className={`${styles.text_icon_container} justify-content-${buttonStyle?.textAlign.toLowerCase() == 'right' ? rightAlignment : buttonStyle?.textAlign}`}>
                    {props.config.text?.icon &&
                        <img style={iconStyle} src={getImgPath(props.config.text?.icon)}></img>}
                    {Text()}
                </div>
            </MuiButton>
        </div>


    )
})

export default Button