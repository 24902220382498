import {Props} from "./interface";
import styles from './PhoneField.module.css'
import PhoneFieldCode from "./PhoneFieldCode";
import React, {useEffect} from "react";
import {getSizes} from "../../../../helpers/functions";
import {SelectChangeEvent, Switch} from "@mui/material";
import DynamicObject from "../../../../models/dynamic-object";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import {getDataStorage} from "../../../../helpers/storage.helper";


const PhoneField = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

        const {config} = props


        /*---------------------------------------------------------------
         *                           HOOKS
         * -------------------------------------------------------------*/

        /************************************
         *   SET & GET VALIDATION AND STATES
         * *********************************/
    const {validation, state} = useDataPayload<DynamicObject>({ref, config: props.config, autoSaveDelay: 3000, setConfig: props.setConfig,defaultValue:""})


        const storage = getDataStorage()

    /*---------------------------------------------------------------*/

        /************************************
         *            CONTEXT
         * *********************************/

        /*---------------------------------------------------------------*/

        /************************************
         *   PREVENT SCROLLING ON INPUTS
         * *********************************/
        document.addEventListener("wheel", function (event) {
            document.getElementById("phone")?.blur()

        });

        /*---------------------------------------------------------------
         *                         FUNCTIONS
         * -------------------------------------------------------------*/
        /************************************
         *        ACTIONS HANDLER
         * *********************************/
        const changeHideFlagHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            state.set({...state.value, [config.hideFlag.identifier]: +event.target.checked})
        }

        const phoneHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
            validation.clear()
            state.set({...state.value, phone: e.target.value})
        }

        const addCountryHandler = (e: SelectChangeEvent) => {
            state.set({...state.value, country: e.target.value})
        }

        /*---------------------------------------------------------------*/

        /************************************
         *         INITIAL COUNTRY
         * *********************************/
        useEffect(function () {
            if (props.config?.country?.values.length > 0) {
                if (state.value && !state.value['country']) {
                    state.set({...state.value, country: config.country.values[0].abbreviation})
                }
            }
        }, [props.config?.country.values])



        return (
            <>
                <div id={props.config.identifier}
                     className={`${storage.isMobile ? styles.parent_container_in_mobile : styles.parent_container_in_desktop} ${styles.parent_container}`}>
                    <div className={`${styles.container}`}>

                        {/*FIRST ITEM COUNTRY ICON AND COUNTRY CODE */}
                        <div className={`${styles.country_container} ${styles.border_0}`}>
                            <PhoneFieldCode country={config.country} state={state.value ? state.value['country'] : ''}
                                            addCountryHandler={addCountryHandler}/>
                        </div>

                        {/*SECOND ITEM PHONE NUMBER */}
                        <input disabled={!config.editable}
                               name="phone"
                               id="phone"
                               value={state.value ? state.value['phone'] : ''}
                               onChange={phoneHandler}
                               type={"tel"}
                               dir={'ltr'}
                               className={`br-10 form-control ${styles.border_0}`}
                               style={{
                                   color: config.text.color,
                                   width: "100%",
                                   fontSize: getSizes(config.placeholder.style?.size),
                                   fontWeight: config.text.style?.bold ? "bold" : "normal",
                               }}

                               placeholder={config.placeholder.locale} aria-describedby="basic-addon2"/>

                    </div>
                    {config.hideFlag &&
                        <div>
                            <div className={styles.line}/>
                            <div className={`row ${styles.flag}`}>
                                <div className={`col-12 d-flex justify-content-between align-items-center`}>
                                    <p className={`ms-1`}>{config.hideFlag.locale}</p>
                                    <Switch onChange={changeHideFlagHandler}
                                            checked={state.value ? state.value[config.hideFlag.identifier] : false}/>
                                </div>


                            </div>
                        </div>

                    }
                </div>
                <ValidationError validation={validation}/>
            </>
        )
    }
)

export default PhoneField