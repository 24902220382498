import { create } from 'zustand';

interface CheckboxStore {
    isChecked: boolean; // State for checked
    labelText: string; // State for label text
    isInitialized: boolean; // Tracks initialization
    apiPayload: string; // State for API payload
    initializeChecked: (initialValue: boolean, label: string, payload: string) => void; // Initialize all states
    setLabelText: (text: string) => void; // Update the label text
    setApiPayload: (text: string) => void; // Update the API payload
    toggleChecked: () => void; // Toggle checkbox
}

const useCheckboxStore = create<CheckboxStore>((set) => ({
    isChecked: false, // Default initial state
    labelText: 'Hide Car Plate', // Default label
    isInitialized: false, // Initialization flag
    apiPayload: 'hide_plate_number', // Default API payload
    initializeChecked: (initialValue, label, payload) =>
        set((state) => {
            if (state.isInitialized) {
                return state; // No changes, prevent unnecessary state update
            }
            return {
                isChecked: initialValue,
                labelText: label,
                apiPayload: payload,
                isInitialized: true,
            };
        }),
    setLabelText: (text) => set({ labelText: text }), // Update label text
    setApiPayload: (text) => set({ apiPayload: text }), // Update API payload
    toggleChecked: () => set((state) => ({ isChecked: !state.isChecked })), // Toggle function
}));

export default useCheckboxStore;
