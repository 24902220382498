import React from "react";
import {Props} from "./interface";
import MapMobile from "./MapMobile/MapMobile";
import MapDesktop from "./MapDesktop/MapDesktop";
import {getDataStorage} from "../../../../helpers/storage.helper";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";

const Map = React.forwardRef<RefDataType<any>, Props>((props, ref) => {

    const {config, setConfig} = props

    const storage = getDataStorage()

    const {state, validation} = useDataPayload<any>({
        ref,
        config: props.config,
        setConfig: props.setConfig,
        autoSaveDelay: 3000,
        defaultValue: props.config.center
    })


    return (<>
        {storage.isMobile ?
            <MapMobile state={state} validation={validation} config={config} setConfigData={props.setConfigData}
                       selectFieldConfig={props.selectFieldConfig}
                       nextApi={props.nextApi}></MapMobile> :
            <MapDesktop state={state} validation={validation} setConfig={setConfig} config={config}
                        setConfigData={props.setConfigData}
                        selectFieldConfig={props.selectFieldConfig} nextApi={props.nextApi}></MapDesktop>}

    </>)
})

export default Map