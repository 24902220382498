import ArachnaApi from "./requests/arachna-api";

const getLocation = (): Promise<{ lat: number; lng: number }> => {
    return new Promise((resolve, reject) => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                },
                (error) => {
                    reject(error);
                }
            );
        } else {
            reject(new Error("Geolocation is not supported by this browser."));
        }
    });
};

export const updatePostLocationApi = async (postId: string): Promise<void> => {
    try {
        const coords = await getLocation();
        const {lat, lng} = coords;

        await ArachnaApi.getInstance().request.put(`${process.env.REACT_APP_ARACHNA_API_URL}/v2.1/posts/${postId}`, {
            "Post[geo_lat]": lat,
            "Post[geo_lng]": lng,
        });
    } catch (error) {
        console.error("Failed to update post location:", error);
    }
};
