import React, {useContext} from "react";
import {useHttp} from "../../../hooks";
import DynamicObject from "../../../models/dynamic-object";
import PostContext from "../../../storage/PostContext";
import MapperFlow from "../../MapperFlow";
import {Args} from "../../../hooks/use-http/interfaces";
import LanguageContext from "../../../storage/LanguageContext";
import UIContext from "../../../storage/UIContext";
import {ComponentLoader} from "../../UI";
import NextApi from "../../../api/next.api";
import FlowReferences from "../../../flow-references";

const Mycv = (): JSX.Element => {
    const postCtx = useContext(PostContext)
    const languageCtx = useContext(LanguageContext)
    const uiCtx = useContext(UIContext)
    const configNextApi = {
        callback: NextApi,
        initialData: {},
        withLoader: true
    }

    const nextApi = useHttp<DynamicObject>(configNextApi)
    const request2 = nextApi.request

    FlowReferences.updateFlow('cv')

    function initializeData() {
        const data: DynamicObject = {
            draftId: postCtx.data.draft_id,
            time_spent_ms: Date.now() - postCtx.data.start_time,
            workflowId: postCtx.data.workflow_id,
            currentStep: postCtx?.data?.step?.identifier,
        }


        for (const key in postCtx.data.currentStepData) {
            data[key as keyof typeof data] = postCtx.data.form[key as keyof typeof postCtx.data.form]
        }
        return data;
    }



    {/*Prepare Request next api*/
    }
    nextApi.request = function (args?: Args) {
        const data = initializeData();

        request2({
            ...args,
            config: {
                flow_type: 'cv',
                ...args?.config,
                data: {
                    ...args?.config?.data,
                    ...data
                },
                language: languageCtx.language
            },
            callbacks: {
                ...args?.callbacks,
                before: () => {
                    uiCtx.setLoading(true)
                }
            }
        })
    }

    return (
        <div>
            {uiCtx.isLoading && <ComponentLoader/>}
            {
                postCtx.data?.step && !uiCtx.isLoading &&
                <MapperFlow config={postCtx.data.step}
                            nextApi={nextApi}
                />
            }

        </div>
    )
}


export default Mycv