import React, { useState } from 'react';
import { Props } from "./interface";
import { FaStar } from 'react-icons/fa';
import ValidationError from '../../../UI/ValidationError/ValidationError';
import useDataPayload, { RefDataType } from "../../../../hooks/use-data-payload/use-data-payload";

const Rate = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    const { state, validation } = useDataPayload<string>({
        ref,
        config: props.config
    })


    const [rating, setRating] = useState(state.value ?? 0);
    const [hover, setHover] = useState(null);

    const handleRate = (rate) => {
        state.set(rate)
        setRating(rate);
        validation.clear()
    }

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {[...Array(5)].map((star, index) => {
                    const ratingValue = index + 1;

                    return (
                        <label key={index} style={{ cursor: 'pointer' }}>
                            <input
                                type="radio"
                                name="rating"
                                value={ratingValue}
                                onClick={() => handleRate(ratingValue)}
                                style={{ display: 'none' }}
                            />
                            <FaStar
                                size={40}
                                color={ratingValue <= (hover || rating) ? "#FBCE0F" : "#EDEDED"}
                                onMouseEnter={() => setHover(ratingValue)}
                                onMouseLeave={() => setHover(null)}
                            />
                        </label>
                    );
                })}
            </div>
            <ValidationError validation={validation} ></ValidationError>
        </>
    );
});

export default Rate;
