import {Props} from './interfaces';
import React, {useContext, useEffect, useRef, useState} from "react";
import styles from './ListTagsOptions.module.css'
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import {
    Button as MuiButton,
    Checkbox as CheckboxMui,
    FormControlLabel,
    FormGroup,
    Radio,
    Typography
} from "@mui/material";
import DynamicObject from "../../../../models/dynamic-object";
import {IoIosArrowUp} from "react-icons/io";
import {AiOutlinePlusCircle} from "react-icons/ai";
import {getImgPath, getSizes} from "../../../../helpers/functions";
import {useTranslation} from "react-i18next";
import LanguageContext from "../../../../storage/LanguageContext";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import { IoEllipsisHorizontal } from 'react-icons/io5';

const ListTagsOptions = React.forwardRef<RefDataType<string>, Props>((props, ref) => {
    const {state, validation} = useDataPayload<string>({
        ref,
        config: props.config
    })

    const {t} = useTranslation();
    const langCtx = useContext(LanguageContext)


    const labelStyle = {
        fontWeight: props.config.cell.label.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.cell.label.style?.size),
        color: props.config.cell.label.color,
        direction:"ltr"
    } as React.CSSProperties


    {/*STATES*/
    }
    const [visibleChildren, setVisibleChildren] = useState<number>(0);
    const [displayAllItems, setDisplayAllItems] = useState<boolean>(false);
    const [displayMoreButton, setDisplayMoreButton] = useState<boolean>(false);

    useEffect(() => {
        props.config.values.map((item, i) => {
            if (displayMoreButton) {
                if (props.config.multi_select) {
                    if (!!checkedItems[item.id] && i>visibleChildren)
                        setDisplayAllItems(true)
                } else {
                    if (state.value == item.id && i>visibleChildren)
                        setDisplayAllItems(true)
                }
            }
        })
    })
    {/*CONSTANTS*/
    }
    const oneItemRemain = props.config.values.length - visibleChildren == 1;
    const parentRef = useRef<HTMLDivElement>(null);
    const checkedItems: DynamicObject = {}

    {/*LISTING ALL ITEMS*/
    }
    const showAllItem = () => {
        setDisplayAllItems(true)
        setDisplayMoreButton(false)
    }

    {/*LISTINGf THE VISIBLE ITEMS ONLY*/
    }
    const showLessItem = () => {
        if (displayAllItems) {
            setDisplayAllItems(false)
            setDisplayMoreButton(true)
        }
    }


    const addItemHandler = (id: number | string) => {
        // if (!state.value) {
        //     state.set(id.toString())
        //     return
        // }
        if (id === state.value)
            return

        if (props.config.multi_select) {
            state.set(state.value + (',' + id))
        } else {
            state.set(id.toString());
        }
        validation.clear()


    }

    {/*IN CHECKBOX DECORATION*/
    }
    const removeItemHandler = (itemValue: string) => {
        const oldItems = state.value ? (state.value).split(',') : []
        const newItems = oldItems.filter((item: string) => {
            return itemValue !== item
        })
        state.set(newItems.join(','))
    }

    const changeItem = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.checked) {
            removeItemHandler(e.target.value)
            return
        }
        addItemHandler(e.target.value)
        validation.clear()
    }

    state.value?.split(',')?.map((item: string) => {
        checkedItems[item] = true
    })

    {/*ITEMS TO RENDER BASED ON DECORATION*/
    }
    const itemsAsCheckboxes = props.config.values.map((item, i) => {
        return <FormGroup
            style={{padding: 0}}>
            <FormControlLabel
                className={`${styles.item} ${!!checkedItems[item.id] ? styles.selected_item : styles.unselected_item}`}
                sx={{padding: "0", margin: 0}}
                control={
                    <CheckboxMui
                        sx={{
                            padding: "5px 0px 5px 5px",
                            color: "#BABABA",
                            borderRadius: "55px",
                            '&.Mui-checked': {
                                borderRadius: "55px",
                                color: "#0179FF"
                            },
                        }}
                        disabled={!props.config.editable}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeItem(e)}
                        checked={!!checkedItems[item.id]}
                    />
                }
                label={
                    <Typography sx={{
                        padding: "6px 6px 0 6px",
                        color: labelStyle.color,
                        fontWeight: !!checkedItems[item.id] ? "bold" : labelStyle.fontWeight,
                        fontSize: labelStyle.fontSize,
                        direction:"ltr"

                    }}>
                        {item.label}
                    </Typography>
                }
                value={item.id}
            />
        </FormGroup>

    })
    const itemsAsNormalText = props.config?.values.map((item, index) => {
        return (
            <button disabled={!props.config.editable} key={index} name={item.label} onClick={(e) => addItemHandler(item.id)}
                    style={labelStyle}
                    className={item.id == state.value as string ? `${styles.customButton} ${styles.active}` : `${styles.customButton}`}>
                {item.label}
            </button>
        )
    })
    const itemsAsRadioButton = props.config.values.map((item, i) => {
        return (<>
                <FormControlLabel sx={{marginLeft: "0px", marginRight: "0px"}}
                                  control={<Radio checked={item.id == state.value} disabled={!props.config.editable} />}
                                  label={<Typography sx={{
                                      padding: "5px 5px 0px 0px",
                                      color: labelStyle.color,
                                      fontWeight: `${item.id == state.value ? 'bold' : labelStyle.fontWeight}`,
                                      fontSize: labelStyle.fontSize,
                                      direction:"ltr"
                                  }}>
                                      {item.label}
                                  </Typography>} value={item.id}
                                  className={`${styles.item} ${item.id == state.value ? styles.selected_item : styles.unselected_item}`}
                                  onClick={(e: React.MouseEvent<HTMLElement>) => addItemHandler(item.id)}/>
            </>
        )
    });

    const itemsAsIconButton = props.config?.values.map((item, index) => {
        return (
            <button key={index} name={item.label} onClick={(e) => addItemHandler(item.id)} disabled={!props.config.editable}
                    style={labelStyle}
                    className={item.id == state.value as string ? `${styles.customIconButton} ${styles.activeIcon}` : `${styles.customIconButton}`}>
                 {item.icon && <div className={`${styles.iconContainer}`}> <img className={styles.image}
                                                                 style={{width:"100%",height:"100%"}}
                                                                 src={getImgPath(item.icon)}/></div> }       
                {item.label}
            </button>
        )
    })


    {/*SET THE FIRST ITEM TO ACTIVE IN NONE DECORATION*/
    }
    // useEffect(function () {
    //     if (!props.config.multi_select && props.config.decoration?.toLowerCase() == 'none') {
    //         if (props.config?.values.length > 0 && !state.value) {
    //             addItemHandler(props.config?.values[0]?.id)
    //         }
    //     }
    //
    // }, [props.config?.values])

    {/*DISPLAY MORE DEPEND IN CALCULATIONS*/
    }
    useEffect(() => {
        const parent = parentRef.current;
        if (!parent) return;

        const children = parent.children;

        let count = 0;

        for (let i = 0; i < children.length; i++) {
            const child = children[i] as HTMLElement;
            if (child.offsetTop <= (props.config?.decoration?.toLowerCase()=='icon'?111:56)) {
                count++;
            }

        }

        setVisibleChildren(count);

    }, [props.config.values?.length]);

    useEffect(() => {
        if (parentRef?.current?.scrollHeight > (props.config?.decoration?.toLowerCase() == 'icon' ? 185 : 100)) {
            setDisplayMoreButton(true)
        } else {
            setDisplayMoreButton(false)
        }
    }, [displayMoreButton, displayAllItems,parentRef, props.config.values?.length]);

    {/*GET ITEM TYPE TO RENDER DEPEND ON DECORATION*/
    }
    const getItemType = (decoration: string) => {
        switch (decoration) {
            case 'checkbox': {
                return itemsAsCheckboxes
            }
            case 'radio': {
                return itemsAsRadioButton
            }
            case 'icon': {
                return itemsAsIconButton
            }
            default: {
                return itemsAsNormalText
            }
        }
    }

    {/*GET SINGLE ITEM TYPE TO RENDER BESIDE OF MORE DEPEND ON DECORATION*/
    }
    const getSingleItem = (decoration: string) => {
        switch (decoration) {
            case 'checkbox': {
                return <FormControlLabel
                    style={{
                        border: "1px solid #EDEDED",
                        borderRadius: "8px",
                        borderColor: !!checkedItems[props.config.values[visibleChildren]?.id] ? "#0179FF" : "#EDEDED",
                        height: "38px"
                    }}
                    sx={{padding: "0", margin: 0}}
                    control={
                        <CheckboxMui
                            sx={{
                                padding: "5px 0px 5px 5px",
                                color: "#BABABA",
                                borderRadius: "55px",
                                '&.Mui-checked': {
                                    borderRadius: "55px",
                                    color: "#0179FF"
                                },
                            }}
                            disabled={!props.config.editable}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeItem(e)}
                            checked={!!checkedItems[props.config.values[visibleChildren]?.id]}
                        />
                    }
                    label={
                        <Typography sx={{
                            padding: "6px 6px 0 6px",
                            color: labelStyle.color,
                            fontWeight: !!checkedItems[props.config.values[visibleChildren]?.id] ? "bold" : labelStyle.fontWeight,
                            fontSize: labelStyle.fontSize,
                            direction:"ltr"
                        }}>
                            {props.config.values[visibleChildren]?.label}
                        </Typography>
                    }
                    value={props.config.values[visibleChildren]?.id}
                />
                break;
            }
            case 'radio': {
                return <FormControlLabel sx={{marginLeft: "0px", marginRight: "0px"}} control={<Radio disabled={!props.config.editable}
                    checked={state.value == props.config.values[visibleChildren]?.id}
                />}
                                         label={<Typography sx={{
                                             padding: "5px 5px 0px 0px",
                                             color: labelStyle.color,
                                             fontSize: labelStyle.fontSize,
                                             fontWeight: `${props.config.values[visibleChildren]?.id == state.value ? 'bold' : labelStyle.fontWeight}`,
                                             direction:"ltr"
                                         }}>
                                             {props.config.values[visibleChildren]?.label}
                                         </Typography>} value={props.config.values[visibleChildren]?.id}
                                         className={`${styles.single_item} ${props.config.values[visibleChildren]?.id == state.value ? styles.selected_item : styles.unselected_item}`}
                                         onClick={(e: React.MouseEvent<HTMLElement>) => addItemHandler(props.config.values[visibleChildren]?.id)}/>

                break;
            }
            case 'icon':{
                return <button key={visibleChildren} name={props.config.values[visibleChildren]?.label} onClick={(e) => addItemHandler(props.config.values[visibleChildren]?.id)} disabled={!props.config.editable}
                 style={labelStyle}
                 className={props.config.values[visibleChildren]?.id == state.value as string ? `${styles.customIconButton} ${styles.activeIcon}` : `${styles.customIconButton}`}>
              {props.config.values[visibleChildren]?.icon && <div className={`${styles.iconContainer}`}> <img className={styles.image}
                                                              style={{width:"100%",height:"100%"}}
                                                              src={getImgPath(props.config.values[visibleChildren]?.icon)}/></div> }       
             {props.config.values[visibleChildren]?.label}
         </button>
             }
            default: {
                return <button disabled={!props.config.editable} key={visibleChildren} name={props.config.values[visibleChildren]?.label}
                               onClick={(e) => addItemHandler(props.config.values[visibleChildren]?.id)}
                               style={labelStyle}
                               className={props.config.values[visibleChildren]?.id == state.value as string ? `${styles.customButton} ${styles.active}` : `${styles.customButton}`}>
                    {props.config.values[visibleChildren]?.label}
                </button>
            }
        }
        return
    }

    return (
        <div
            className={`${styles.container}`} style={{pointerEvents:!props.config.editable?'none':'auto',outline:"0px"}}>
            <div  style={{maxHeight:props.config?.decoration?.toLowerCase()=='icon'?"185px":"100px"}}
             className={`${styles.list_container} ${displayAllItems && styles.list_all_items_container}`}
                 ref={parentRef}>
                {getItemType(props.config?.decoration?.toLowerCase())}
            </div>
            {displayMoreButton && !displayAllItems &&
                <div className={`${props.config?.decoration?.toLowerCase() == 'icon' ? styles.single_item_icon_container : styles.single_item_container}`}>
                    {getSingleItem(props.config?.decoration?.toLowerCase())}
                    {!oneItemRemain && <>
                        {props.config?.decoration?.toLowerCase() == 'icon' ? <button onClick={(e) => showAllItem()} className={`${styles.customIconButton}`} >
                            <div className={`${styles.iconContainer}`}> <IoEllipsisHorizontal style={{ height: "100%", width: "100%" }} color="#EDEDED" /></div>
                            {t('showMore')}
                        </button> : <MuiButton className={`${styles.show_more_button}`}
                            onClick={(e) => showAllItem()}
                        >
                            <div className={`${styles.button_container}`}>
                                <AiOutlinePlusCircle
                                    className={`${styles.icon_dimensions}`} />
                                <div
                                    className={`${styles.more_text} ${langCtx.language == 'ar' ? styles.margin_left : ''}`}>{t('showMore')}</div>
                            </div>

                        </MuiButton>}
                    </>}
                </div>}
            <ValidationError validation={validation}/>
        </div>
    )
})
export default ListTagsOptions


