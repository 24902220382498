import styles from "./ConfirmationModel.module.css"
import {Button as MuiButton} from "@mui/material";
import React, {useState} from "react";
import Overlay from "../Overlay/Overlay";
import {useTranslation} from "react-i18next";

interface Props {
    acceptAction: () => void,
    setIsOpen: (value: any) => void,
    message?: string
    title?: string

}

const ConfirmationModel = (props: Props) => {

    const {setIsOpen} = props

    const {t} = useTranslation();

    const closeHandler = () => {
        setIsOpen(false)
    }

    const acceptButtonHandler = () => {
       props.acceptAction()
    }
    return (
        <Overlay>
            <div className={styles.container} onClick={closeHandler}>
                <div className={styles.model} onClick={e => e.stopPropagation()}>
                    <div className={`${styles.header}`}>
                        {props.title && <div className={`${styles.title}`}>{props.title}</div>}
                        {props.message && <div>{props.message}</div>}
                    </div>
                    <div className={`${styles.button_container}`}>
                        <MuiButton className={`${styles.button}`} fullWidth={true} variant={"outlined"}
                                   onClick={acceptButtonHandler}>{t('yes')}</MuiButton>

                        <MuiButton variant="outlined" color="error" className={`${styles.cancel_button}`}
                                   fullWidth={true}
                                   onClick={closeHandler}>{t('cancel')}</MuiButton>

                    </div>
                </div>
            </div>
        </Overlay>
    )


}

export default ConfirmationModel