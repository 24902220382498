export default {
    ListCellOptions: true,
    ListRadioOptions: true,
    MediaStep: true,
    VideoStep: true,
    NormalStep: true,
    PreviewStep: true,
    CityFlowField:true,
    Map: true,
    Button:true
}