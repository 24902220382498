import axios, { AxiosResponse } from "axios";
import ArachnaApi from "../../../../api/requests/arachna-api";
import { getDataStorage } from "../../../../helpers/storage.helper";
import i18n from "../../../../i18n";
import { generateAuthToken } from "../../../../helpers/auth.helper";


export default async function createNafathRequest({national_id} : {national_id : string}): Promise<AxiosResponse> {
    const storage = getDataStorage()
        return axios.post(
        `${process.env.REACT_APP_ARACHNA_API_URL}/v2.1/nafath/create-request`,
        {
          national_id
        },
        {
            headers: {
                source: storage.source,
                country: storage.country,
                [`enforce-language`]: i18n.language,
                'Authorization': "Bearer " +await generateAuthToken(),
            }
        }
      )
}