import React, {
    FunctionComponent,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import DynamicObject from "../models/dynamic-object";
import NextButton from "./Flow/NextButton/NextButton";
import { useQueryParams } from "../hooks";
import { UseHttpResponse } from "../hooks/use-http/interfaces";
import PostContext, { flowRefs } from "../storage/PostContext";
import LanguageContext from "../storage/LanguageContext";
import UIContext from "../storage/UIContext";
import { removeMatchingKeys } from "../helpers/functions";
import ActiveRequests from "../models/active-requests";
import PendingUploads from "./PendingUploads/PendingUploads";
import { getDataStorage } from "../helpers/storage.helper";
import SectionCacher from "../models/section-cacher";
import { useNavigate } from "react-router-dom";
import { REFRESH_PAGE } from "../constants/Status";
import Render from "./Flow/PreviewTools/Render";
import FlowReferences from "../flow-references";
import { logAddListingSubmit } from "../logging/helpers/commonLoggingFunctions";
import { FUNNEL_EVENTS, trackEvent } from "../helpers/Gtm";
import useModalStore from "../storage/ModalContext";
import PQSv4 from "./Flow/PreviewTools/PQSv4/PQSv4";

interface Config {
    [key: string]: any;
}

interface Props {
    config: Config;
    setConfigData: React.Dispatch<React.SetStateAction<any>>;
    publishApi: UseHttpResponse<DynamicObject>;
}

interface ValidatePublish {
    isValid: boolean;
    invalidElement?: {
        component: FunctionComponent;
        props: DynamicObject;
    };
}

const DEFAULT_VALIDATE_PUBLISH: ValidatePublish = {
    isValid: true,
};

const Mapper = (props: DynamicObject) => {
    const { loadData } = props;
    const [config, setConfig] = useState<DynamicObject>(props.config);

    const levels = props.levels as number[];
    const postCtx = useContext(PostContext);
    const identifier = config.identifier;
    const attributes: DynamicObject = {};
    flowRefs[config.identifier] = React.useRef<any>();
    flowRefs[`${postCtx?.previewStep["PQSv4"]?.scrollToReason}`] =
        React.useRef<any>();

    const pqsRef = useRef(null);

    const divRef = useRef(null);
    const params = useQueryParams();

    let scrollByIdentifier = params.query.get("scrollByIdentifier");
    {
        /*PREPARE REF*/
    }
    const executeScroll = () => {
        divRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        setTimeout(() => params.replaceURL("scrollByIdentifier", ""), 500);
    };
    useEffect(() => {
        if (divRef.current)
            setTimeout(() => {
                if (scrollByIdentifier) executeScroll();
            }, 10);
    }, []);

    useEffect(() => {
        setConfig(props.config);
    }, [props.config]);

    attributes.loadData = loadData;

    const handlePqsScrollByIdentitifer = () => {
        // params.setQueryParams(`scrollByIdentifier=${postCtx?.previewStep["PQSv4"]?.scrollToReason}`)
        const element = document.getElementById(postCtx?.previewStep["PQSv4"]?.scrollToReason)
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        }
    };
    return (
        <>


            {config?.identifier == scrollByIdentifier && <span ref={divRef}></span>}


            <Render
                setConfigData={props.setConfigData}
                config={config as any}
                {...attributes}
                levels={levels}
                setConfig={setConfig}
                values={postCtx.data.form[identifier]}
            >
                <>
                    {config.childs &&
                        config.childs.map((child: Config, index) => {
                            const newProps = {
                                ...props,
                                config: { ...child },
                                levels: [...levels, index],
                                setConfigData: props.setConfigData,
                            };
                            return (
                                <>
                                    {index === 0 &&
                                        postCtx?.previewStep["PQSv4"] &&
                                        config.identifier === "finalStepLeftMegaIdentifier" ? (
                                        <div onClick={handlePqsScrollByIdentitifer}>
                                            {" "}
                                            <PQSv4 key="pqs-v4" {...postCtx?.previewStep["PQSv4"]} />
                                        </div>
                                    ) : null}
                                    {child.identifier == scrollByIdentifier && (
                                        <span ref={divRef}></span>
                                    )}
                                    <Mapper {...newProps} />
                                </>
                            );
                        })}
                </>
            </Render>
        </>
    );
};

const MapperPreview = (props: Props): JSX.Element => {
    const { config } = props;
    const { publishApi } = props;
    const postCtx = useContext(PostContext);
    const navigate = useNavigate();
    const langCtx = useContext(LanguageContext);
    const [validatePublish, setValidatePublish] = useState<ValidatePublish>(
        DEFAULT_VALIDATE_PUBLISH
    );
    const storage = getDataStorage();
    const containerWidth = document.getElementById("content-container");
    const [screenWidth, setScreenWidth] = useState(containerWidth?.clientWidth);
    const { updatePayLoad } = useModalStore();


    useMemo(
        function () {
            for (const key in postCtx.data.form) {
                const value = postCtx.data.form[key];
                FlowReferences.load(key, {
                    state: {
                        get: () => value,
                    },
                });
            }
        },
        [postCtx.data.form]
    );

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(containerWidth?.clientWidth);
        };

        window.addEventListener("resize", handleResize);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []); // Empty dependency array to run the effect only once

    const uiCtx = useContext(UIContext);

    function injectState(key: string, data: DynamicObject, state) {
        const keys = key.match(/\b(\w+)\b/g) || [];
        if (/label/i.test(key)) {
            return;
        }
        if (keys.length > 1) {
            let mainKey = keys[0] || "";
            if (!data[mainKey]) data[mainKey] = {};

            for (let i = 1; i < keys.length; i++) {
                let name = keys[i];
                data[mainKey][name] = state;
            }
        } else {
            if (
                typeof state === "object" &&
                !Array.isArray(state) &&
                state !== null
            ) {
                if (!data[key]) data[key] = {};
                removeMatchingKeys(Object.keys(state), data[key]);
                data[key] = { ...data[key], ...state };
            } else {
                data[key] = state;
            }
        }
    }

    function loadData() {
        const data: DynamicObject = {};
        for (const key in flowRefs) {
            const ref = flowRefs[key];
            if (!ref?.current?.state?.get) continue;
            injectState(key, data, ref.current.state.get());
        }

        FlowReferences.map((identifier, field) => {
            if (field?.state && field?.state?.get())
                injectState(identifier, data, field.state.get());
        });
        return data;
    }

    useEffect(() => {
        return () => {
            SectionCacher.clearInstance();
        };
    }, []);

    {
        /*RECURSION FUNCTION TO GET CHILD'S AND SUB COMPONENT INSIDE PREVIEW STEP */
    }

    const logClickingSubmit = (payload: any) => {
        if (!payload) return;

        const categoriesSub =
            config && config.GA && config.GA.cd && config.GA.cd.Subcategory
                ? config.GA.cd.Subcategory
                : "";
        const categoriesMain =
            config && config.GA && config.GA.cd && config.GA.cd.Category
                ? config.GA.cd.Category
                : "";

        logAddListingSubmit({
            id: payload.post_id,
            category: { label: categoriesMain },
            subCategory: categoriesSub,
            service: "",
        });
    };
    useEffect(() => { }, []);

    useEffect(() => {
        if (!uiCtx.errors.length) return;

        // for (const key in uiCtx.errors) {
        //     const errorObject = uiCtx.errors[key] as DynamicObject
        //
        //     const ref = flowRefs[errorObject.field]
        //
        //
        //     if (!ref?.current?.validation?.set)
        //         continue
        //
        //     if (key == "0") {
        //         let element = document.getElementById(errorObject.field)
        //         element?.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
        //     }
        //     ref.current.validation.set(errorObject?.message)
        // }
        return () => {
            // uiCtx.setErrors([])
        };
    }, [uiCtx.errors]);

    const x = config.submit ? (
        <div>
            <NextButton
                style={{
                    bold: config.submit?.style?.bold,
                    size: config.submit?.style?.size ?? "medium",
                    color: config.submit?.color ?? "red",
                    backgroundColor: config.submit?.background.color ?? "white",
                    width: storage.isMobile ? `${screenWidth - 10}px` : "400px",
                }}
                nextApi={publishApi}
                onClick={(api: UseHttpResponse<DynamicObject>) => {
                    if (
                        sessionStorage.getItem("loggingFlow") &&
                        (sessionStorage.getItem("loggingFlow") === "draft" ||
                            sessionStorage.getItem("loggingFlow") === "republish")
                    ) {
                        trackEvent(FUNNEL_EVENTS.SUBMIT);
                    } else if (
                        postCtx.data.flow_type === "add" ||
                        postCtx.data.flow_type === "post"
                    ) {
                        trackEvent(FUNNEL_EVENTS.SUBMIT);
                    }
                    const data = loadData();
                    //logging
                    logClickingSubmit(data);
                    //

                    updatePayLoad(data);
                    api.request = api.request.bind(this, { config: { data } });

                    if (!ActiveRequests.getInstance().uploadsCounter()) {
                        return api.request();
                    }

                    const attributes: DynamicObject = {
                        publishApi: api,
                        totalRequests: ActiveRequests.getInstance().uploadsCounter(),
                        lang: langCtx.language,
                    };

                    setValidatePublish({
                        isValid: false,
                        invalidElement: {
                            component: PendingUploads as FunctionComponent,
                            props: { ...attributes },
                        },
                    });

                    const PreviousButton = document.getElementById("PreviousButton");
                    if (PreviousButton) PreviousButton.style.display = "none";
                }}
                isSticky={props.config?.submit?.sticky}

            // isSticky={config.submit?.sticky}
            >
                {config.submit?.locale ? config.submit?.locale : "Next"}{" "}
            </NextButton>
        </div>
    ) : (
        []
    );

    if (!validatePublish.isValid && validatePublish.invalidElement) {
        const ValidateComponent = validatePublish.invalidElement.component;
        return React.createElement(ValidateComponent as FunctionComponent, {
            ...validatePublish.invalidElement.props,
        });
    }

    {
        /*
        - THIS IS CHILD'S FOR PREVIEW STEP
        - MAPPING FROM FOLDER PREVIEW TOOLS
        */
    }
    const components = config.childs.map((child: Config, index: number) => {
        // return Mapper(child, [index])
        return (
            <>
                <Mapper
                    config={child}
                    loadData={loadData}
                    levels={[index]}
                    setConfigData={props.setConfigData}
                />
            </>
        );
    });

    components.push(x);

    return components;
};

export default MapperPreview;
