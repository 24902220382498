import ArachnaApi from './requests/arachna-api'
import {AxiosResponse} from "axios";
import DynamicObject from "../models/dynamic-object";
import {AddPostFilterResponse} from "../interceptors";
import LoginRequestInterceptor from "../interceptors/platform-request.interceptor";
import {checkOnBehalfLogInFlow, isIFrame} from "../helpers/functions";
import Cookies from "js-cookie";

export default (config?: DynamicObject): Promise<AxiosResponse> => {
    const isBehalfLogIn=Cookies.get('EmployeeToken') && isIFrame() && Cookies.get('audience') == 'infinity';
    const request = ArachnaApi.getInstance().request
    request.interceptors.response.use(AddPostFilterResponse().success,  AddPostFilterResponse().error)
    request.interceptors.request.use(LoginRequestInterceptor)

    return request.get(process.env.REACT_APP_ARACHNA_API_URL + `/vertical/forms/v1/add-post/${isBehalfLogIn?'on-behalf':'normal'}/1`)
}