import React, {useContext, useImperativeHandle} from "react";
import './VideoSection.css'
import {Props, RefDataType} from './interfaces';
import LanguageContext from "../../../../storage/LanguageContext";
import Video from "../../Tools/Video/Video";
import {useValidation} from "../../../../hooks";
import FlowReferences from "../../../../flow-references";
import pqs_warning_icon from "../../../../assets/images/pqs_warning_icon.png";

const VideoSection = React.forwardRef<RefDataType, Props>((props, ref) => {
    const {config} = props
    const langCtx = useContext(LanguageContext)
    const {ValidationMethods, error} = useValidation(ref as any)
    useImperativeHandle<any, any>(ref, () => {
        return {
            ...ValidationMethods
        }
    })

    FlowReferences.load(config.identifier, {
        validation: {
            ...ValidationMethods
        },
    })
    const titleStyle = {

    } as React.CSSProperties

    return (<div id={props.config.identifier}>
            {props.config.title && <div className={`mt-3 mb-3`} style={titleStyle}>{props.config.title?.locale} </div>
            }
             {props.config?.label?.locale && props.config?.label?.type == 'pqs'  && (
        <div style={{
            backgroundColor: '#fffaf4',
            border: '1px dotted #ffd392',
            height:'36px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '14px',
            paddingRight: '14px',
            marginBottom:'10px'
        }}>
                          <img style={{marginLeft:'5px', marginRight:'5px'}} src={pqs_warning_icon}/>

          <p> {props.config?.label?.locale}</p>
        </div>
      )}
            <Video config={config.config} identifier={config.identifier} error={error} setError={ValidationMethods.set} maxLength={1}/>
           
        </div>
    )
})
export default VideoSection