import axios, { AxiosResponse } from "axios";
import { getDataStorage } from "../../../../helpers/storage.helper";
import { generateAuthToken } from "../../../../helpers/auth.helper";
import i18n from "../../../../i18n";

export default async function checkNafathStatus({
  id,
}: {
  id: number;
}): Promise<AxiosResponse> {
  const storage = getDataStorage();
  return axios.post(
    `${process.env.REACT_APP_ARACHNA_API_URL}/v2.1/nafath/check-status`,
    {
      id,
    },
    {
      headers: {
        source: storage.source,
        country: storage.country,
        [`enforce-language`]: i18n.language,
        Authorization: "Bearer " + (await generateAuthToken()),
      },
    }
  );
}
