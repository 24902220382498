import {Props, RefDataType} from './interfaces';
import styles from './ImageLabel.module.css'
import React, {useContext} from "react";
import {mustache} from "../../../../helpers/functions";
import LanguageContext from "../../../../storage/LanguageContext";


const ImageLabel = React.forwardRef<RefDataType, Props>((props, ref) => {

    const langCtx = useContext(LanguageContext)
    const rightAlignment = props.config?.align.toLowerCase() == 'right' && langCtx.language == 'en' && 'end'

    const imagePathData = {
        "platform": "ios",
        "size": "1x"
    };

    const containerWidth = {
        width:`${props?.config?.width}%`
    } as React.CSSProperties
    const imageRatio = {
            aspectRatio: props?.config?.ratio,
            width:`${props?.config?.width}%`
        } as React.CSSProperties


    
  
    return (
        <div style={{width:'100%'}}>
            <div
                className={`${styles.row}  justify-content-${props?.config?.align.toLowerCase() == 'right' && langCtx.language == 'en' ? rightAlignment : props?.config?.align.toLowerCase()}`}>
                <img style={imageRatio} className={`${styles.image}`}
                     src={mustache(props.config.imagePath, imagePathData)}/>
            </div>
        </div>
    )

})

export default ImageLabel