import React, {useEffect, useState} from "react";
import DynamicObject from "../models/dynamic-object";

const DEFAULT_LOADING = false
const DEFAULT_BTN_LOADING = false
const DEFAULT_MODAL = false
const DEFAULT_INTERNET_STATE=true
const DEFAULT_IS_MOBIL=false
let AFTER_LOADING_METHODS = []

export {AFTER_LOADING_METHODS}

const UIContext = React.createContext({
    isLoading: DEFAULT_LOADING,
    btnLoading:DEFAULT_BTN_LOADING,
    showPredictedPriceModal:DEFAULT_MODAL,
    isOnline:DEFAULT_INTERNET_STATE,
    isMobile:DEFAULT_IS_MOBIL,
    errors: [{}],
    //actions
    setLoading: (value: boolean) => {
    },
    setBtnLoading: (value: boolean) => {
    },
    setOnline: (value: boolean) => {
    },
    setModal: (value: boolean) => {
    },
    setErrors: (errors: DynamicObject[]) => {
    },
    setMobile: (value: boolean) => {
    }
})

export const UiContextProvider = (props: { children: any }) => {

    //states
    const [isOnline, setIsOnline] = useState<boolean>(DEFAULT_INTERNET_STATE);
    const [isLoading, setIsLoading] = useState<boolean>(DEFAULT_LOADING);
    const [btnLoading, setBtnLoading] = useState<boolean>(DEFAULT_BTN_LOADING);
    const [showPredictedPriceModal, setshowPredictedPriceModal] = useState<boolean>(DEFAULT_MODAL);
    const [isMobile, setIsMobile] = useState<boolean>(DEFAULT_IS_MOBIL);
    const [errors, setErrors] = useState<DynamicObject[]>([]);

    const setLoadingHandler = (value: boolean) => {
        setIsLoading(value)
    }
    const setBtnLoadingHandler = (value: boolean) => {
        setBtnLoading(value)
    }
    const setModalHandler = (value: boolean) => {
        setshowPredictedPriceModal(value)
    }

    const setErrorsHandler = (errors: DynamicObject[]) => {
        setErrors(errors)
    }
    const setIsOnlineHandler= (value: boolean) => {
        setIsOnline(value)
    }
    const setIsMobileHandler= (value: boolean) => {
        setIsMobile(value)
    }

    useEffect(() => {
        if (isLoading)
            return
        console.log(AFTER_LOADING_METHODS, 'AFTER')
        AFTER_LOADING_METHODS.map(callback => callback())
        AFTER_LOADING_METHODS = []
    }, [isLoading])

    return (
        <UIContext.Provider
            value={{
                errors,
                isLoading,
                showPredictedPriceModal,
                isOnline,
                isMobile,
                btnLoading,
                setLoading: setLoadingHandler,
                setBtnLoading: setBtnLoadingHandler,
                setModal: setModalHandler,
                setErrors: setErrorsHandler,
                setOnline:setIsOnlineHandler,
                setMobile:setIsMobileHandler
            }}>
            {props.children}
        </UIContext.Provider>
    );
}

export default UIContext