import create from 'zustand';

// Define the store interface
interface ModalStore {
  showPredictedPriceModal: boolean;
  modalData: Record<string, any> | null;
  payLoad:Record<string, any> | null;
  initRequest : boolean;
  stopScroll: boolean;
  setInitRequest : () => void;
  abortInitRequest : () => void;
  toggleHide: () => void;
  toggleShow: () => void;
  updateStopScroll: (value: boolean) => void;
  updateModalData: (data: Record<string, any>) => void;
  updatePayLoad: (data: Record<string, any>) => void;
}

// Create the store with Zustand
const useModalStore = create<ModalStore>((set) => ({
  showPredictedPriceModal: false,
  modalData: null,
  payLoad:null,
  initRequest : null,
  stopScroll: false,
  updateStopScroll: (value: boolean) => set({stopScroll: value}),
  setInitRequest : () => set({ showPredictedPriceModal: true }),
  abortInitRequest : () => set({ showPredictedPriceModal: true }),

  toggleHide: () => set({ showPredictedPriceModal: false }),
  toggleShow: () => set({ showPredictedPriceModal: true }),
  updateModalData: (data: Record<string, any>) => {
    set({ modalData: data, showPredictedPriceModal: true });
    // localStorage.setItem('ModalData', JSON.stringify(data)); // Store in localStorage
  },
  updatePayLoad: (data: Record<string, any>) => {
    set({ payLoad: data });
  },
}));

export default useModalStore;
