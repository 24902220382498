import { useTranslation } from "react-i18next";
import styles from "./listingCard.module.css";
import { useEffect, useState } from "react";
import getListingViewDataLight from "../apis/listing-view-light.api";

export interface Listing {
  listing_id?: number;
  title?: string;
  country_id?: number;
  member_id?: number;
  phone?: string;
  post_type?: string;
  first_image_uri?: string;
  hide_contact_info?: boolean;
}

export default function ListingCard() {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const listingId = urlParams.get("post_id") ?? "";
  const [listingData, setListingData] = useState<Listing>();

  useEffect(() => {
    getListingViewDataLight({ listingId })
      .then((res) => {
        setListingData(res.data.listing);
      })
      .catch((error) => {
        console.log("Errrrrr", error);
      });
  }, []);

  return (
    <div className={`${styles.listing_container}`}>
      <div className={`${styles.section_container}`}>
        <div className={`${styles.listing_title} mb-3`}>{t("yourListing")}</div>
        <div className={`${styles.grid_container}`}>
          <div className={`${styles.image_label_row}`}>
            <img
              className={`${styles.image_label_image}`}
              src={listingData?.first_image_uri}
              alt="ListingImage"
            />
          </div>
          <div className={`${styles.text_container} d-flex flex-column`}>
            <div className={`${styles.text_label}`}>{listingData?.title}</div>
            <div className={`${styles.text_label_postId} mt-auto pb-3`}>
              {t("listingId")} {listingData?.listing_id}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
