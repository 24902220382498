import { Button, Modal } from "react-bootstrap";
import useModalStore from "../../../../storage/ModalContext";
import "./PredictedPriceModal.css";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const PredictedPriceModal = ({api=null}) => {

  const { showPredictedPriceModal, toggleHide,updateModalData,payLoad, updatePayLoad, updateStopScroll } = useModalStore()
  const {t} = useTranslation();

  const handleClose=()=>{
    updateModalData(null);
    toggleHide();    
  }

  useEffect(()=>{
    updateStopScroll(false)
  },[])

  const handleYes = () => {
    const priceField = document.getElementById("price_field");
    if(priceField){
      priceField.scrollIntoView();
    }
    updateModalData(null);

    toggleHide();

  };
  const handleNo = async () => {
    toggleHide();
    const updatedPayLoad = {
      ...payLoad,
      skip_prediction_price: true,
      draftId: sessionStorage.getItem("draftId"),
    };
  
    updatePayLoad(updatedPayLoad);
  
    if (api) {
      try {
        await api.request({ config: { data: updatedPayLoad } });
      } catch (error) {
        console.error("API request failed:", error);
      }
  };
}

  return (
    <div>
      <Modal className="predictedPriceModal" show={showPredictedPriceModal} onHide={handleClose} centered>
        <Modal.Header className="modal-header">
        <button
          type="button"
          className="custom-close-btn"
          onClick={handleClose}
          aria-label="Close"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width="16"
            height="16"
            fill="black"
          >
            <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z" />
          </svg>
        </button>
          <Modal.Title className="modal-title">{t('modalTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
        {t('modalDescription1')}
        <br />
        {t('modalDescription2')}
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <Button className="yes-button" onClick={handleYes}>
          {t('yes')}
          </Button>
          <Button className="no-button" onClick={handleNo}>
          {t('no')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PredictedPriceModal;
