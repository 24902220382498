import * as flow from "../components/Flow/SelectFieldTools";
import PostContext from "../storage/PostContext";
import React, {ReactNode, useContext, useEffect} from "react";
import DynamicObject from "../models/dynamic-object";
import UIContext from "../storage/UIContext";

interface Props {
    config: DynamicObject;
    selectFieldConfig: DynamicObject,
    tempFormData: DynamicObject,
    value?:any,
}

interface Config {
    [key: string]: any
}

interface RefType {
    getState: () => DynamicObject
}
const flowRefs: DynamicObject = {}

const MapperSelectFieldFlow = (props: Props): JSX.Element => {
    /*CONSTANT*/
    const {config} = props
    const postCtx = useContext(PostContext)
    const uiCtx = useContext(UIContext)
    const data: DynamicObject = {}

    /*MAPPER AND RECURSION TO GET ALL SUB COMPONENTS*/
    const Mapper = (config: DynamicObject) => {
        const typeName = config.type.capitalize();
        const identifier = config.identifier
        const attributes: DynamicObject = {}
        const Component = flow[typeName as keyof typeof flow]

        flowRefs[identifier] = React.createRef<RefType>()


        if (!Component) {
            throw new Error(`Component ${typeName} doesn't Exists`)
            return <></>
        }
        attributes.config = props.config
        attributes.tempFormData = props.tempFormData
        attributes.flowRefs = flowRefs
        attributes.fromSelectField = true
        attributes.selectFieldConfig=props.selectFieldConfig
        attributes.values = props.value

        return <Component {...attributes as any}>
                {config.childs && config.childs.map((child: DynamicObject) => {
                        return Mapper(child)
                    })}
        </Component>
    }

    return Mapper(config)
}

export default MapperSelectFieldFlow