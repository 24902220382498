import ArachnaApi from "./requests/arachna-api";
import { AxiosInstance, AxiosResponse } from "axios";
import DynamicObject from "../models/dynamic-object";
import { AddPostFilterResponse } from "../interceptors";

export default function previousApi(
  config?: DynamicObject
): Promise<AxiosResponse> {
  const request = ArachnaApi.getInstance().request as AxiosInstance;
  request.interceptors.response.use(
    AddPostFilterResponse().success,
    AddPostFilterResponse().error
  );
  const params = {
    draftId: config?.data.draftId,
  };
  if(config?.type == "delete") delete params.draftId
  return request.get(
    config?.type === "delete"
      ? `${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/deleteReason/deeplink/${config?.post_id || sessionStorage.getItem("post_id")}`
      : `${process.env.REACT_APP_ARACHNA_API_URL}/vertical/forms/v1/${config?.data.flow_type}/normal/${config?.data.currentStep}/${config?.data.workflowId}`,
    {
      params: {
        ...params,
      },
    }
  );
}
