import { useTranslation } from "react-i18next";
import styles from "./ContactUs.module.css";
import { getDataStorage } from "../../../../helpers/storage.helper";

export default function ContactUs() {
  const { t } = useTranslation();
  const storage = getDataStorage()
  
  return (
    <div className={`${styles.section_container} mt-3`}>
      <div>
        <div className={`${styles.section_flexContainer}`}>
          <div className={`mb-1 d-flex flex_column justify_content_center`}>
            <span
              className={`${styles.bold} ${styles.font_size_20} ${styles.color_black} ${styles.margin_5}`}
            >
              {t("doYouNeedHelp")}
            </span>
          </div>
        </div>
      </div>
      <div className={`${styles.section_flexContainer}`}>
        <div
          className={`mb-1 ${styles.d_flex} ${styles.flex_column} ${styles.justify_content_center}`}
        >
          <span
            className={`${styles.bold} ${styles.font_size_14} ${styles.color_gray}`}
          >
            {t("contactUsNow")}
          </span>
        </div>
      </div>
      <button
        className={`${styles.contactUsBtn} d-flex p-2 justify-content-center `}
        onClick={(e) => {
          e.preventDefault()
          window.location.href = `${process.env.REACT_APP_JO_SOOQ_URL.replace("XX.", `${storage.country}.`).replace(
            "xx.",
            `${storage.country}.`
          )}/${storage.lang}/${storage.lang === "ar" ? "مساعدة/اتصل-بنا" : "support/contact-us"}`
        }}
      >
        <img
          className={`${styles.button_icon}`}
          src="https://opensooqui-new.s3.amazonaws.com/api/apiV/web/add_listings/icons/call_icon.png"
          alt="button_icon_Contact Us"
        />
        <div className="ms-2">{t("contactUs")}</div>
      </button>
    </div>
  );
}
