import RefType from "../../models/ref-type";
import DynamicObject from "../../models/dynamic-object";
import {ForwardedRef, useImperativeHandle, useState} from "react";
import {Validation} from "../use-data-payload/interfaces";


interface RefDataType extends RefType<DynamicObject> {
}


const DEFAULT_ERROR = ''

const useValidation = (ref: ForwardedRef<RefDataType>) => {
    /* ERRORS STATE*/
    const [error, setError] = useState<string>(DEFAULT_ERROR)

    const ValidationMethods: Validation = {
        set: (error: string) => {
            setError(error)
        },
        clear: () => {
            setError(DEFAULT_ERROR)
        }

    }

    return {
        error,
        ValidationMethods
    }

}
export default useValidation;