import "react-toastify/dist/ReactToastify.css";
import {toast, ToastOptions} from "react-toastify";
import styles from "./snackAlert.module.css";

const TOAST_MESSAGE_TIMEOUT = 4000;

interface IProps {
    text?: string;
    description?: any;
    open?: boolean;
    locale?: string;
    lang?: string;
    autoClose?: number;
    onClose?: () => void;
    toastType: "error" | "success" | "info" | "warning";
    title?: string;
}

const Content = (props: any) => {

    return (
        <>
            {props.title ?
                <div className="ms-3" dir={(props.locale == 'ar') ? 'rtl' : 'ltr'}>
                    <div className={`d-flex ${styles.flexSpaceBetween} fw-bold fs-1`}>{props.title}</div>
                    {props.description && (Array.isArray(props.description) ? props.description.map((desc: any, _index: number) =>
                            <li className="mt-1 fs-3" key={_index}>{desc}</li>) :
                        <p className="mt-1 fs-3">{props.description}</p>)}
                </div>
                : <div className="ms-3" dir={props.locale == "ar" ? "rtl" : "ltr"}>
                    <ul>
                        {props.description &&
                            (Array.isArray(props.description) ? (
                                props.description.map((desc: any, _index: number) => (
                                    <li className="mt-1 fs-3" key={_index}>
                                        {desc}
                                    </li>
                                ))
                            ) : (
                                <p className="mt-1 fs-3">{props.description}</p>
                            ))}
                    </ul>
                </div>}
        </>
    );
};

export const toastify = (props: IProps) => {
    let {
        autoClose = TOAST_MESSAGE_TIMEOUT,
        title,
        description,
        locale,
        onClose = null,
        toastType,
    } = props;

    onClose = onClose || function () {
    };
    const alertConfig: ToastOptions = {
        theme: "dark",
        position: toast.POSITION.TOP_CENTER,
        autoClose,
        onClose,
        rtl: locale === "ar",
    };
    switch (toastType) {
        case "error":
            return toast.error(
                <Content title={title} description={description} locale={locale}/>,
                alertConfig
            );
        case "success":
            return toast.success(
                <Content  title={title} description={description} locale={locale}/>,
                alertConfig
            );
        default:
            return <></>
    }
};
