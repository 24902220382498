import {AxiosRequestConfig} from "axios";

import {generateToken} from "../helpers/auth.helper";
import Auth, {UserIdentity} from "opensooq-sso";
import {checkOnBehalfLogInFlow} from "../helpers/functions";
import Cookies from "js-cookie";
export default async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    // config.headers['Authorization'] = "Bearer " + await generateToken()
    let token = '';
    if(await checkOnBehalfLogInFlow()){
        token = Cookies.get('EmployeeToken');
    }else{
        token = await Auth.getInstance().getToken(true,true);
    }
    config.headers['Authorization'] = "Bearer " +  token

    return config
}