import React, {useContext, useEffect, useImperativeHandle, useState} from "react";
import styles from './MapMobile.module.css'
import {useTranslation} from "react-i18next";
import {Button as MuiButton} from "@mui/material";
import {GoogleMap} from "../../../../../utils/google-maps";
import PostContext from "../../../../../storage/PostContext";
import {Coordinates, Props, RefDataType} from "../interface";
import DynamicObject from "../../../../../models/dynamic-object";

interface CurrentProps extends Props {
    state,
    validation: DynamicObject,
}

const MapMobile = React.forwardRef<RefDataType, CurrentProps>((props, ref) => {

    const {config, state} = props


    /************************************
     *         TRANSLATION
     * *********************************/
    const {t} = useTranslation();


    /************************************
     *            CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)

    /************************************
     *            CONSTANTS
     * *********************************/
    const latLong: string = state.value ?? postCtx.data.form[config.identifier]

    const keys: string[] = ['lat', 'lng'];

    const obj: DynamicObject = {};

    latLong?.toString().split(',').forEach((value, index) => {
        obj[keys[index]] = Number(value);
    });


    const defaultCoordinates = state.value ? state.value : props.config.center


    let [configLat, configLng] = defaultCoordinates?.toString().split(",", 2)

    /*-------------------------------------
     *            FUNCTIONS
     * ----------------------------------*/

    /************************************
     *        ACTION HANDLERS
     * *********************************/
    const defaultState = {
        lat: parseFloat(configLat),
        lng: parseFloat(configLng)
    }

    const [coordinates, setCoordinates] = useState<Coordinates>(defaultState)


    const changeLatitudeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCoordinates((old: Coordinates) => {
            return {
                ...old,
                lat: parseFloat(e.target.value)
            }
        })
    }

    const changeLongitudeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCoordinates((old: Coordinates) => {
            return {
                ...old,
                lng: parseFloat(e.target.value)
            }
        })
    }


    useImperativeHandle<RefDataType, RefDataType>(ref, () => {
        return {
            getState: (): any => `${coordinates.lat},${coordinates.lng}`
        }
    })

    const addItemHandler = () => {
        postCtx.addToForm(config.identifier, `${coordinates.lat},${coordinates.lng}`)

    }
    const clearMap = () => {
        postCtx.addToForm(config.identifier, defaultCoordinates)

    }


    /*---------------------------------------------------------------*/

    /************************************
     *           MAP LOADER
     * *********************************/

    useEffect(() => {
        if (!props.config?.skip) {
            // if (coordinates.lat === defaultState.lat && coordinates.lng === defaultState.lng)
            //     return

            state.set(`${coordinates.lat},${coordinates.lng}`)
        }
        return () => {
        }
    }, [coordinates])


    useEffect(function () {
        const [lat, lng] = props.config.center.split(",").map((coordinate) => parseFloat(coordinate))

        if (!props.config?.skip) {
            // if (coordinates.lng === lng && coordinates.lat === lat) {
            //     return
            // }

            setCoordinates({
                lat,
                lng
            })

        }
        console.log("loaaad maaap")
        const googleMap = new GoogleMap("google-map")
        googleMap.loadMap({
            zoom: config.zoomLevel,
            center: {lat, lng}
        }).then(function () {
        }).catch(console.error)
    }, [props.config])


    useEffect(function () {
        const googleMap = new GoogleMap("google-map")
        googleMap.loadMap({
            zoom: config.zoomLevel,
        }).then(function () {
        })
    }, [])

    return (
        <div className={`${styles.container} mt-3`}>
            <div className={styles.map_container}>
                <div className={'google-map'} style={{width: "100%", height: "65vh", overflow: "unset"}}/>

                <input type="hidden" className={'lat'} onInput={changeLatitudeHandler} value={coordinates.lat}/>
                <input type="hidden" className={'lng'} onInput={changeLongitudeHandler} value={coordinates.lng}/>
            </div>

            {props.config?.skip && <div className="row">
                <div className={`col-12 ${styles.button_container}`} style={{
                    display: props.config.skip ? "flex" : "",
                    flexDirection: "row",
                    flexWrap: "nowrap"
                }}>
                    <div style={{width:"100%"}} className={`d-flex justify-content-center align-items-center w-100`}>
                        <MuiButton
                            fullWidth={true}
                            style={{
                                borderRadius: "0px",
                                color: "#fff",
                                backgroundColor: "#0c5cf9",
                                fontWeight: "bold",
                                fontSize: "18px",
                            }}
                            onClick={(e) => addItemHandler()}
                        >{t('save')}</MuiButton>
                        <MuiButton style={{
                            borderRadius: "0px",
                            color: "#fff",
                            backgroundColor: "#505050",
                            fontWeight: "bold",
                            fontSize: "18px",
                        }}
                                   fullWidth={true}

                                   onClick={(e) => clearMap()}
                        >{t('skip')}</MuiButton>
                    </div>
                </div>
            </div>}
        </div>
    )
})

export default MapMobile