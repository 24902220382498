import React, { useState, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Button } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

interface TooltipData {
  title: {
    color: string;
    locale: string;
    icon: string;
    align: "left" | "right" | "center" | "justify";
  };
  text: {
    locale: string;
    align: "left" | "right" | "center" | "justify";
    icon: string;
  };
}

interface PQSv4Props {
  locale: string;
  score: number;
  tooltip: TooltipData;
  isMobile: boolean;
  handlePqsScrollByIdentitifer: () => void;
}

const PQSv4: React.FC<PQSv4Props> = ({
  locale,
  score,
  tooltip,
  isMobile,
  handlePqsScrollByIdentitifer,
}) => {
  const [atTop, setAtTop] = useState(true);
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: "10px",
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#F2F2F7 !important",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: "10px",
      backgroundColor: "#67AD5C !important",
    },
  }));

  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#4C4096",
      color: "#FFFFFF",
      width: "285px",
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
      maxHeight: "166px",
      borderRadios: "5px",
      padding: "12px",
      marginTop: "0 !important",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#4C4096",
    },
    [`& .${tooltipClasses.popper}`]: {
      display: "flex",
      justifyContent: "flex-start",
    },
  }));

  useEffect(() => {
    const handleScroll = () => {
      setAtTop(window.scrollY === 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [y, setY] = useState(window.scrollY);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        setIsScrollingUp(true);
        setOpen(false);
      } else if (y < window.scrollY) {
        setIsScrollingUp(false);
        setOpen(false)
      }
      setY(window.scrollY);
    },
    [y]
  );
  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);


    return (
    <div
    onClick={isMobile ? handlePqsScrollByIdentitifer : undefined}
    style={{
                  background: "white",
        borderRadius: "15px",
        padding: "18px",
        border: isMobile
          ? "2px dotted #F2F2F7"
          : "1px solid rgb(209, 209, 209)",
        position: !atTop && isMobile ? "sticky" :  "static",
        top: !atTop && isMobile ? 0  : "auto",
        zIndex: 2,
        boxShadow: "rgba(190, 196, 209, 0.4) 0px 2px 16px;",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          padding: "3px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <span style={{ fontWeight: "700" }}>{score}%</span>{" "}
          <span
            style={{
              color: "#808083",
              textAlign: tooltip?.title?.align,
            }}
          >
            {locale}
          </span>
        </div>

        {!atTop && (isMobile && !isScrollingUp) && (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <CustomTooltip
                slotProps={{
                  popper: {
                    disablePortal: true,
                  },
                }}
                arrow
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        justifyItems: "center",
                      }}
                    >
                      <span style={{ fontWeight: "700", fontSize: "16px" }}>
                        {tooltip?.title?.locale}
                      </span>{" "}
                      <div
                        style={{
                          display: "flex",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTooltipClose();
                        }}
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.454561 1.32137L1.773 0.00292969L7.00002 5.22994L12.227 0.00292969L13.5455 1.32137L8.31846 6.54838L13.5455 11.7754L12.227 13.0938L7.00002 7.86683L1.773 13.0938L0.454561 11.7754L5.68157 6.54838L0.454561 1.32137Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    </div>
                    <p style={{ fontWeight: "400", fontSize: "14px" }}>
                      {tooltip?.text?.locale}
                    </p>
                  </>
                }
              >
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(!open);
                  }}
                >
                  <InfoOutlined sx={{ color: "black", fontSize: "25px" }} />
                </Button>
              </CustomTooltip>
            </div>
          </ClickAwayListener>
        )}
      </div>
      <BorderLinearProgress
        sx={{ margin: "5px" }}
        variant="determinate"
        value={score}
      />
      {(atTop || !isMobile || (isMobile && isScrollingUp)) && (
        <p style={{ textAlign: tooltip?.text?.align, color: "#242424" }}>
          {tooltip?.text?.locale}
        </p>
      )}
    </div>
  );
};

export default PQSv4;
