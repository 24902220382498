import React, {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Camera, Hint} from "../../../../assets/images";
import {StateDataType, VideoComponentProps} from "./Video.interfaces";
import styles from "../../../UI/MediaUpload/MediaUpload.module.css";
import DynamicObject from "../../../../models/dynamic-object";
import {LinearProgressLabel} from "../../../UI";
import EditorMenu from "./EditorMenu";
import {BsCloudUpload} from "react-icons/bs";
import 'video-react/dist/video-react.css'; // import css
import {FormHelperText} from "@mui/material";

import {Player, ControlBar, VolumeMenuButton, BigPlayButton, PlayToggle} from 'video-react';
import {getImgPath, getSizes} from "../../../../helpers/functions";
import LanguageContext from "../../../../storage/LanguageContext";
import './VideoStyle.css'
import VideoBox from "./VideoBox";
import {t} from "i18next";

interface VideoStyleProps {
    children?: React.ReactNode;
    state: StateDataType,
    addMedia: (files: FileList | null) => Promise<boolean>
    onDeleteMedia: (mediaId: string) => void,
    maxLength: number,
    error?: string,
}

let oldImageHash: string


const VideoBoxComponent = (props: VideoComponentProps) => {
    const {
        mediaKey,
        state,
        onClickMenuItemHandler,
        onClickBoxHandler,
        deleteImageHandler,
        onClickChangeMediaHandler
    } = props
    const medias = state.medias


    const boxClassName = () => {
        let className = `${styles.box}`

        if (mediaKey)
            className = `${className} ${styles.solid_border} ${styles.z_index}`

        if (mediaKey && !medias[mediaKey].isLoaded)
            className = `${className} in-progress`

        if (!mediaKey) {
            return `${className} cursor__pointer`
        }


        return `${className} ${styles.loaded}`
    }


    const containerProps: DynamicObject = {
        id: "bigContainer",
        className: boxClassName()
    }


    if (!mediaKey)
        containerProps.onClick = (e: any) => onClickMenuItemHandler(e, () => onClickBoxHandler(e))


    return <div className={`${styles.box_container}`}>
        <div className={styles.cover_image}>{t('video')}</div>
        <div {...containerProps} {...props}>
            <VideoBox video={state.medias[mediaKey]}/>
            {
                (mediaKey && !medias[mediaKey].isLoaded) && (
                    <div className={styles["progress-container"]}>

                        <div className={styles['content']}>
                            <LinearProgressLabel variant="buffer" value={medias[mediaKey].progress}
                                                 container={{className: styles.progress}}/>
                        </div>
                    </div>
                )
            }

            {mediaKey && medias[mediaKey].isLoaded &&
                <EditorMenu
                mediaKey={mediaKey}
                onClickMenuItemHandler={onClickMenuItemHandler}
                deleteImageHandler={deleteImageHandler}
                onClickChangeMediaHandler={onClickChangeMediaHandler}
                state={state}
                />
            }
        </div>
    </div>
}


const VideoStyle = (props: VideoStyleProps): JSX.Element => {
    const {
        maxLength,
        state,
        addMedia,
        onDeleteMedia,
        error,
    } = props

    const hashes = [...Object.keys(state.medias)]

    const langCtx = useContext(LanguageContext)
    const uploadFileRef = useRef<HTMLInputElement>(null)
    const updateFileRef = useRef<HTMLInputElement>(null)
    const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>();
    const open = Boolean(anchorEl);
    const [width, setWidth] = useState<number>(window.innerWidth);
    const {t} = useTranslation();
    const [isDragging, setIsDragging] = useState<boolean>(false)

    const dropRef = useRef<HTMLDivElement>(null)


    const onDrop = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setIsDragging(false)
        if (Object.values(state.medias).length <= 0)
            return

        addMedia(e.dataTransfer.files).then(r => {
        })
    }

    const handleDrag = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setIsDragging(true)
    }
    const handleDragIn = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setIsDragging(true)

    }
    const handleDragOut = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setIsDragging(false)
    }


    const isMobile = width <= 768;


    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }


    const [isBottom, setIsBottom] = React.useState(false);


    const onClickBoxHandler = (e: any) => {
        if (e.target.id == 'bigContainer' || e.target.id == 'midContainer') {
            uploadFileRef?.current?.click()
        } else if (e.target.id == 'icon' || e.target.id == 'basic-button') {
            setIsBottom(true);
        } else {
            setIsBottom(false);
        }
    }

    const onClickChangeMediaHandler = (hash: string) => {
        oldImageHash = hash
        updateFileRef?.current?.click()
    }


    const deleteImageHandler = (e: React.MouseEvent, mediaId: string) => {
        if (e.target == e.currentTarget) {
            e.stopPropagation()
        }

        onDeleteMedia(mediaId)
    }

    const onClickMenuItemHandler = (e: React.MouseEvent, handler: () => any) => {
        console.log('upload')

        if (e.target == e.currentTarget) {
            e.stopPropagation();
        }

        handler();
    }


    useEffect(() => {
        // window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            // window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        if (!dropRef.current)
            return
        dropRef.current.addEventListener('drop', onDrop)
        dropRef.current.addEventListener('dragenter', handleDragIn)
        dropRef.current.addEventListener('dragleave', handleDragOut)
        dropRef.current.addEventListener('dragover', handleDrag)

        return () => {
            dropRef.current?.removeEventListener('drop', onDrop)
            dropRef.current?.removeEventListener('dragenter', handleDragIn)
            dropRef.current?.removeEventListener('dragleave', handleDragOut)
            dropRef.current?.removeEventListener('dragover', handleDrag)
        };
    }, [dropRef])


    let numberMedia = 1

    let mediaKeys = Object.keys(state.medias)

    const boxes = []


    for (let i = 1; i <= numberMedia; i++) {
        const mediaKey = hashes.shift()

        boxes.push(
            <VideoBoxComponent mediaKey={mediaKey}
                               index={i}
                               key={i}
                               state={state}
                               onClickBoxHandler={onClickBoxHandler}
                               onClickChangeMediaHandler={onClickChangeMediaHandler}
                               deleteImageHandler={deleteImageHandler}
                               onClickMenuItemHandler={onClickMenuItemHandler}
            />
        )
    }

    return (
        <div className={`${styles.parent_container}`}>
            {/*<div className={`${styles.text_container}`}>*/}
            {/*    <div className={`col-12 d-flex ${styles.icon_list_container}`}>*/}
            {/*        <div style={{float: langCtx.language === 'en' ? 'left' : 'right'}}><img style={{width: "15px"}}*/}
            {/*                                                                                src={Hint}/></div>*/}
            {/*        <div>*/}
            {/*            <li>A <strong>30 seconds</strong> video can be added</li>*/}
            {/*            <li>For more views, explain the product quickly & clearly</li>*/}
            {/*            <li>A portrait video is best suited for people to view</li>*/}
            {/*            <li>Listings with videos get more engagement and calls</li>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <input type='file' accept='video/mp4,video/m4v,video/webm,video/avi' className={styles["upload-file"]}
                   ref={uploadFileRef} multiple={true}
                   onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                       addMedia(e.target.files).then(() => {
                           e.target.value = ''
                       })
                   }}
            />

            {error && <div className={`d-flex w-100`}>
                <FormHelperText style={{
                    margin: "5px 0px ",
                    marginBottom: "20px",
                    paddingTop: "2px",
                    color: "black",
                    fontWeight: "700",
                    borderRadius: "5px",
                    border: "1px dashed #F14F75",
                    fontSize: "12px",
                    padding: "4px 11px 0px 11px",
                    width: "100%",
                    background: "rgba(247, 213, 222, 0.35)",
                    textAlign: langCtx.language === 'ar' ? 'right' : 'left'
                }}>

                    {` ${error}`}</FormHelperText></div>}
            <div className={`${styles.container} ${styles.w100}`} ref={dropRef}>
                {
                    isDragging && <div className={styles.dragging__container}>
                        <div className={styles.dragging__box}>
                            <div className={styles.dragging__icon}>
                                <BsCloudUpload/>
                            </div>
                            <div className={styles.dragging__text}>Drop & Upload</div>
                        </div>
                    </div>
                }
                <div className={`row ${styles.custome_margin}`} style={{fontWeight: "bold"}}>
                    <div className={`col-12 d-flex flex-wrap ${styles.box_gap}`}
                         style={{justifyContent: 'start', alignItems: "start"}}>{boxes}</div>
                </div>

            </div>
        </div>
    )
}

export default VideoStyle