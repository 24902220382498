import {md5} from "./md5";
import {MediaResult} from "../storage/PostContext";

interface MediaResponse {
    content_md5?: string
    draft_id?: string
    id?: string
    identifier: string
    is_360?: number
    is_main?: number
    media_size?: number
    mime_type?: string
    original_size?: number
    record_insert_date?: string
    record_update_date?: string
    status?: number
    thumbnail_uri?: string
    uri: string
    weight?: number
}

let numberFiles = 0

export const generateFileHash = (file: File): string => {

    const hash = `${numberFiles.toString(32)}-${md5(file.name + file.lastModified + file.size + file.type)}`
    numberFiles++
    return hash
}

export const getFullPathMedia = (uri: string, size = "1024x0") => {
    return `${process.env.REACT_APP_CDN_PREFVIEW}/previews/${size}/${uri}.jpg`
}

export const getOriginalFile = (uri: string) => {
    return `${process.env.REACT_APP_CDN_PREFVIEW}/originals/media/${uri}`
}

export const MediaMapper = (medias: MediaResponse[]): MediaResult => {
    const result: MediaResult = {}
    let count = 0
    for (const index in medias) {
        const media = medias[index]

        const hash = `${count.toString(32)}-${md5(media.uri + (media.record_update_date as string) + media.media_size + media.mime_type)}`
        count++

        const url = media.mime_type !== "image/jpeg" ? getOriginalFile(media.uri) : getFullPathMedia(media.uri)
        let isMain = !!media.is_main

        if (!result[media.identifier]) {
            result[media.identifier] = {
                medias: {},
                main_media: ""
            }
        }

        result[media.identifier].medias[hash] = {
            id: media.id,
            url,
            uri: media.uri,
            isLoaded: true,
            isMain,
            progress: 100,
        }

        if (isMain) {
            result[media.identifier].main_media = hash
        }
    }

    return result
}