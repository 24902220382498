import {Props} from "./interface";
import React, {useMemo} from "react";
import styles from './DateField.module.css';
import {Select, MenuItem, SelectChangeEvent} from "@mui/material";
import {formatDate, toDateObject, fromDateObject} from './functions';
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";

const DateField = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    /*-------------------------------------
     *              HOOKS
     * ----------------------------------*/

    /************************************
     *   SET & GET VALIDATION AND STATES
     * *********************************/
    const {state, validation} = useDataPayload<string>({
        ref,
        config: props.config,
        defaultValue:""
    })


    /************************************
     *            CONSTANT
     * *********************************/
    const dateTimeMin = new Date()
    dateTimeMin.setDate(dateTimeMin.getDate() + props.config.minValue)
    const minYear = dateTimeMin.getFullYear()

    const dateTimeMax = new Date()
    dateTimeMax.setDate(dateTimeMax.getDate() + props.config.maxValue)
    const maxYear = dateTimeMax.getFullYear()

    const yearsComponents: Array<JSX.Element> = []
    const monthsComponents: Array<JSX.Element> = []
    const daysComponents: Array<JSX.Element> = []


    /*-------------------------------------
    *             FUNCTIONS
    * ----------------------------------*/

    /************************************
     *   SET CURRENT DATE IN DATE STATE
     *        AS INITIAL VALUE
     * *********************************/
    const getDate = () => {
        const dateTimeDefault = new Date()
        dateTimeDefault.setDate((new Date()).getDate() + props.config.defaultValue)
        return formatDate(dateTimeDefault)
    }

    /************************************
     *       SET CHOSEN DATE
     * *********************************/
    const addDateHandler = (e: SelectChangeEvent<number>, type: string) => {
        let dateAsObject = toDateObject(state.value)
        const newDate = {
            ...dateAsObject,
            [type]: Number(e.target.value)
        }
        state.set(fromDateObject(newDate))
    }


    const getDays = (year, month) => {
        return new Date(year, month, 0).getDate();
    };

    /*-------------------------------------
     *              STATES
     * ----------------------------------*/
        // state.set(getDate())

    /************************************
     *       SET MENU ITEMS
     * *********************************/
    for (let year = minYear; year <= maxYear; year++) {
        yearsComponents.push(<MenuItem value={year}>{year}</MenuItem>)
    }

    for (let month = 1; month <= 12; month++) {
        monthsComponents.push(<MenuItem disabled={month > new Date().getMonth() + 1 && toDateObject(state.value).year == new Date().getFullYear() } value={month}>{month}</MenuItem>)
    }

    for (let day = 1; day <= getDays(toDateObject(state.value).year, toDateObject(state.value).month); day++) {
        daysComponents.push(<MenuItem disabled={day > new Date().getDate()+1 && toDateObject(state.value).year == new Date().getFullYear() && toDateObject(state.value).month ==  new Date().getMonth()+1 } value={day}>{day}</MenuItem>)
    }


    /************************************
     *     INLINE COMPONENT STYLE
     * *********************************/
    const containerStyle = {
        color: props.config.text.color.normal ?? 'black',
        fontWeight: props.config.text?.style?.bold ? 'bold' : 'normal',
        fontSize: props.config.text?.style?.size + 'px'
    }


    return useMemo(() => {
        return (
            <div className={`row justify-content-center ${styles.row}`}>
                <div className="">{props.config.text?.locale}</div>
                <div className={`col-12 ${styles.col} date`} style={containerStyle}>
                    <Select
                        MenuProps={{ PaperProps: { sx: { maxHeight: "20vh" } } }}
                        value={toDateObject(state.value).year}
                        onChange={(e: SelectChangeEvent<number>) => addDateHandler(e, 'year')}>
                        {yearsComponents}
                    </Select>
                    <Select
                        MenuProps={{ PaperProps: { sx: { maxHeight: "20vh" } } }}
                        value={toDateObject(state.value).month}
                        onChange={(e: SelectChangeEvent<number>) => addDateHandler(e, 'month')}>
                        {monthsComponents}
                    </Select>
                    <Select
                        MenuProps={{ PaperProps: { sx: { maxHeight: "20vh" } } }}
                        value={toDateObject(state.value).day}
                        onChange={(e: SelectChangeEvent<number>) => addDateHandler(e, 'day')}>
                        {daysComponents}
                    </Select>
                </div>
                <ValidationError validation={validation}/>
            </div>
        )
    }, [state.value])

})

export default DateField