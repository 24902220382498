import { useHttp } from "./hooks";
import './helpers/index'
import { AxiosResponse } from "axios";
import { Navbar } from "./components/Layout";
import UIContext from "./storage/UIContext";
import PostContext from "./storage/PostContext";
import { ComponentLoader, DisconnectInternet } from "./components/UI";
import './Master.css';
import './Bootstrap.css'
import MapperFlow from "./components/MapperFlow";
import { Args } from "./hooks/use-http/interfaces";
import './helpers/index'
import LanguageContext from "./storage/LanguageContext";
import React, { useContext, useEffect, useMemo, useState } from 'react';
import DynamicObject from "./models/dynamic-object";
import { ContentContainer } from "./components/Containers/index";
import { arSA } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './i18n';
import { useNavigate } from "react-router-dom";
import EditPost from "./components/Flow/EditPost/EditPost";
import JobApply from "./components/Flow/JobApply/JobApply";
import {
    getFinalPayload,
    getOriginDomain,
    getUrlBasedOnCountry,
    isIFrame,
    parentCallBack,
    removeSessionByKey,
    setSessionByKey, updateQueryStringParameter
} from "./helpers/functions";
import { MediaMapper } from "./helpers/media.functions";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Draft from "./components/Flow/Draft/Draft";
import Activate from "./components/Flow/Activate/Activate";
import Republish from "./components/Flow/Republish/Republish";
import Mycv from "./components/Flow/Mycv/Mycv";
import Cookies from "js-cookie";

import {
    configInitApi,
    configNextApi,
    configJobApplyApi,
    configDraftApi,
    configActivateApi,
    configRepublishApi,
    configMyCvApi, configPreviousApi, configRoutingApi
} from './config/api.config'
import Previous from "./components/UI/Previous/Previous";

import {
    CLASSIC_NPS,
    DEFAULT_NPS,
    OK,
    PAGE_NOT_FOUND,
    PAYMENT_METHODS_ONLINE,
    REFRESH_PAGE,
    UNEXLUDED_COUNTRY_IN_PAYMENT_SURVEY
} from "./constants/Status";
import { IFrameEvents } from "./IFrameEvents";
import i18n from "./i18n";
import MediasCacher from "./models/medias-cacher";
import { getDataStorage } from "./helpers/storage.helper";
import example from './ex'
import styles from "./constants/styles";
import FlowReferences from "./flow-references";
import { logScreenLoad } from "./logging/helpers/commonLoggingFunctions";
import { useLogging } from "./logging/hooks/useLogging";
import Survey from "./components/Flow/Survey/Survey";
import Reviews from "./components/Flow/Reviews/Reviews";
import RequestFeature from "./components/Flow/RequestFeature/RequestFeature";
import PromoteFlow from "./components/Flow/PromoteFlow/PromoteFlow";
import { FUNNEL_EVENTS, trackEvent } from "./helpers/Gtm";
import DeleteReason from "./components/Flow/DeleteReason";
import Nafath from "./components/Flow/Nafath";

function App() {
    console.log('v.1.1.64')
    /************************************
     *              CONSTANT
     * *********************************/
    /*CONTEXT*/
    const postCtx = useContext(PostContext)
    const uiCtx = useContext(UIContext)
    const langCtx = useContext(LanguageContext)
    /*URL PARAMS*/
    const urlParams = new URLSearchParams(window.location.search)
    const flow = urlParams.get('flow') || "add"
    const cSource = urlParams.get('cSource') || ""
    const cMedium = urlParams.get('cMedium') || ""
    const cName = urlParams.get('cName') || ""

    const from = urlParams.get('from') ?? ''
    useLogging();
    /*NAVIGATE*/
    const navigate = useNavigate()
    /*THEME*/
    const theme = createTheme({ palette: { primary: { main: '#1976d2' }, }, }, arSA,);


    useMemo(() => {
        FlowReferences.resetReRenderFlow()
    }, [postCtx.data.step])

    /************************************
     *              STATES
     * *********************************/
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [width, setWidth] = useState<number>(window.innerWidth);
    const storage = getDataStorage()

    document.documentElement.style.setProperty('--container-width', styles.containerWidth);

    /*---------------------------------------------------------------
     *                       HTTP REQUEST
     * --------------------------------------------------------------*/

    /************************************
     *         INIT REQUEST API
     * *********************************/
    const initApi = useHttp<DynamicObject>(configInitApi)
    const onSuccessInitApi = (response: AxiosResponse) => {
        postCtx.updateStep({
            workflow_id: response.data.workflow_id,
            draft_id: response.data.draft.id,
            // step: example.step,
            step: response.data.step,
            start_time: Date.now(),
            previous: response.data.previous,
            //to ignore the post context when it's cv and the session is not job
            flow_type: postCtx.data.flow_type == 'cv' && sessionStorage.getItem('flow-type') != 'job' ? 'post' : postCtx.data.flow_type,
        })
        setSessionByKey('draftId', response.data.draft.id)
        if (sessionStorage.getItem("flow-type") !== "reviews") {
            removeSessionByKey('flow-type')
        }
        removeSessionByKey('loggingFlow')
        if (response.data.draft.id !== postCtx.data.draft_id) {
            MediasCacher.getInstance().clear()
        }
        navigate(`${response.data.step.uri}`)
        window.sessionStorage.setItem('referralUrl', response.data.step.uri.toLowerCase())

        // add init gtm
        trackEvent(FUNNEL_EVENTS.INIT);
    }

    /************************************
     *         ROUTING REQUEST API
     * *********************************/
    const routingApi = useHttp<DynamicObject>(configRoutingApi)
    const onSuccessRoutingApi = (response: AxiosResponse) => {

        if (response.data.draft.id !== postCtx.data.draft_id) {
            MediasCacher.getInstance().clear()
        }

        if (response.data.closeAddPost) {
            const storage = getDataStorage()
            let URL = Cookies.get('addPostRef')
            const domain = process.env.REACT_APP_JO_SOOQ_URL.replace('https://XX', '');
            document.cookie = `addPostRef=;expires=;max-age=0;domain=${domain};path=/`;
            if (!URL || (URL && URL == '')) {
                URL = getUrlBasedOnCountry(storage.country)
            } else if (URL && getOriginDomain() === process.env.REACT_APP_SHOBIDAK_DOMAIN) {
                URL = getUrlBasedOnCountry(storage.country)
            }
            setTimeout(() => {
                window.location.replace(URL);
            }, 200);
            return;
        }
        if (sessionStorage.getItem("flow-type") !== "reviews") {
            removeSessionByKey('flow-type')
        }        // window.sessionStorage.removetem('flow-type')

        const finalPayload = getFinalPayload(response.data.draft.payload)

        const data = { redirect: { url: "www.google.com" } }

        postCtx.updateData({
            form: {
                ...finalPayload,
            },
            step: {
                ...response.data.step
            },
            draft_id: response.data.draft.id,
            workflow_id: response.data.workflow_id,
            media: MediaMapper(response.data.media),
            previous: response.data.previous,
            formType: response?.data?.review_identifier ?? '',
            //to handle survey flow on refresh and back
            flow_type: response.data?.step?.uri && response.data.step.uri.includes('survey') ? 'survey' : response.data?.step?.uri && response.data.step.uri.includes('review') ? 'reviews' : 'post'
        })
        setSessionByKey('draftId', response.data.draft.id)
        const mediaResult = MediaMapper(response.data.media)
        MediasCacher.getInstance().concat(mediaResult)
        uiCtx.setLoading(false)
    }


    /************************************
     *         NEXT REQUEST API
     * *********************************/
    const nextApi = useHttp<DynamicObject>(configNextApi)
    const request = nextApi.request
    nextApi.request = function (args?: Args) {
        //to check if it's on behalf log in
        const isBehalfLogIn = Cookies.get('EmployeeToken') && isIFrame() && Cookies.get('audience') == 'infinity';

        const data: DynamicObject = {
            draftId: postCtx.data.draft_id,
            time_spent_ms: Date.now() - postCtx.data.start_time,
            workflowId: postCtx.data.workflow_id,
            currentStep: postCtx?.data?.step?.identifier,
            member_id: isBehalfLogIn ? storage?.userInfo?.id : '',
        }

        for (const key in postCtx.data.currentStepData) {
            data[key as keyof typeof data] = postCtx.data.form[key as keyof typeof postCtx.data.form]
        }


        request({
            ...args,
            config: {
                //to ignore the post context when it's cv and the session is not job when user refresh or back in job
                flow_type: postCtx.data.flow_type == 'cv' && sessionStorage.getItem('flow-type') != 'job' ? 'post' : postCtx.data.flow_type,
                step: postCtx?.data?.step,
                ...args?.config,
                data: {
                    ...data,
                    ...args?.config?.data,
                },
                language: langCtx.language
            },
            callbacks: {
                ...args?.callbacks,
                before: () => {
                    uiCtx.setLoading(true)
                }
            }
        })
    }


    /************************************
     *         JOB APPLY REQUEST API
     * *********************************/
    const jobApplyHttp = useHttp<DynamicObject>(configJobApplyApi)

    const requestTakeActionJobApply = jobApplyHttp.request

    jobApplyHttp.request = (args) => {
        const success = (response: AxiosResponse) => {
            parentCallBack('without_confirm_dialog')
            const finalPayload = getFinalPayload(response.data.draft.payload)
            postCtx.updateData({
                form: {
                    ...postCtx.data.form,
                    ...finalPayload,
                },
                step: {
                    ...response.data.step
                },
                draft_id: response.data.draft.id,
                workflow_id: response.data.workflow_id,
                media: MediaMapper(response.data.media),
            })
        }

        requestTakeActionJobApply({
            ...args,
            config: {
                post_id: parseInt(urlParams.get('post_id')),
            },
            callbacks: {
                ...args?.callbacks,
                success: success,
            }
        })
    }


    /************************************
     *         DRAFT REQUEST API
     * *********************************/
    const draftHttp = useHttp<DynamicObject>(configDraftApi)


    const requestTakeActionDraft = draftHttp.request

    draftHttp.request = (args) => {

        const success = (response: AxiosResponse) => {
            const finalPayload = getFinalPayload(response.data.draft.payload)
            setSessionByKey('draftId', response.data.draft.id)
            postCtx.updateData({
                form: {
                    ...postCtx.data.form,
                    ...finalPayload,
                },
                step: {
                    ...response.data.step
                },
                draft_id: response.data.draft.id,
                workflow_id: response.data.workflow_id,
                media: MediaMapper(response.data.media),
                previous: response.data.previous

            })
            if (response.data.draft.id !== postCtx.data.draft_id) {
                MediasCacher.getInstance().clear()
            }

            const mediaResult = MediaMapper(response.data.media)
            MediasCacher.getInstance().concat(mediaResult)
        }

        requestTakeActionDraft({
            ...args,
            config: {
                draft_id: urlParams.get('draft_id'),
            },
            callbacks: {
                ...args?.callbacks,
                success: success,
            }
        })
    }


    /************************************
     *         ACTIVATE REQUEST API
     * *********************************/
    const activateHttp = useHttp<DynamicObject>(configActivateApi)

    const requestTakeActionActivate = activateHttp.request

    activateHttp.request = (args) => {

        const success = (response: AxiosResponse) => {
            const finalPayload = getFinalPayload(response.data.draft.payload)
            setSessionByKey('draftId', response.data.draft.id)
            postCtx.updateData({
                form: {
                    ...postCtx.data.form,
                    ...finalPayload,
                },
                step: {
                    ...response.data.step
                },
                draft_id: response.data.draft.id,
                workflow_id: response.data.workflow_id,
                media: MediaMapper(response.data.media),
                post_id: parseInt(urlParams.get('post_id')),
                isOverLimit: response.data?.post?.isOverLimit
            })
            parentCallBack(REFRESH_PAGE)

        }

        requestTakeActionActivate({
            ...args,
            config: {
                post_id: parseInt(urlParams.get('post_id')),
            },
            callbacks: {
                ...args?.callbacks,
                success: success,
            }
        })
    }


    /************************************
     *         REPUBLISH REQUEST API
     * *********************************/
    const republishHttp = useHttp<DynamicObject>(configRepublishApi)

    const requestTakeActionRepublish = republishHttp.request

    republishHttp.request = (args) => {

        const success = (response: AxiosResponse) => {
            setSessionByKey('draftId', response.data.draft.id)
            const finalPayload = getFinalPayload(response.data.draft.payload)
            postCtx.updateData({
                form: {
                    ...postCtx.data.form,
                    ...finalPayload,
                },
                step: {
                    ...response.data.step
                },
                draft_id: response.data.draft.id,
                workflow_id: response.data.workflow_id,
                media: MediaMapper(response.data.media),
            })

            if (response.data.draft.id !== postCtx.data.draft_id) {
                MediasCacher.getInstance().clear()
            }

            const mediaResult = MediaMapper(response.data.media)
            MediasCacher.getInstance().concat(mediaResult)
            parentCallBack(REFRESH_PAGE)

        }

        //to check if it's on behalf log in
        const isBehalfLogIn = Cookies.get('EmployeeToken') && isIFrame() && Cookies.get('audience') == 'infinity';

        requestTakeActionRepublish({
            ...args,
            config: {
                post_id: parseInt(urlParams.get('post_id')),
                member_id: isBehalfLogIn ? storage?.userInfo?.id : '',
            },
            callbacks: {
                ...args?.callbacks,
                success: success,
            }
        })
    }


    /************************************
     *         MY CV  REQUEST API
     * *********************************/
    const myCvHttp = useHttp<DynamicObject>(configMyCvApi)

    const requestTakeActionMyCv = myCvHttp.request

    myCvHttp.request = (args) => {

        const success = (response: AxiosResponse) => {
            setSessionByKey('draftId', response.data.draft.id)
            const finalPayload = getFinalPayload(response.data.draft.payload)
            postCtx.updateData({
                form: {
                    ...postCtx.data.form,
                    ...finalPayload,
                },
                step: {
                    ...response.data.step
                },
                draft_id: response.data.draft.id,
                workflow_id: response.data.workflow_id,
                media: MediaMapper(response.data.media),
            })
        }

        requestTakeActionMyCv({
            ...args,
            config: {},
            callbacks: {
                ...args?.callbacks,
                success: success,
            }
        })
    }

    /************************************
     *       PREVIOUS REQUEST API
     * *********************************/
    const previousHttp = useHttp<DynamicObject>(configPreviousApi)

    const requestTakeActionPrevious = previousHttp.request

    previousHttp.request = function (args?: Args) {

        uiCtx.setLoading(true)
        const data: DynamicObject = {
            draftId: postCtx.data.draft_id,
            workflowId: postCtx.data.previous?.workflow_id,
            currentStep: postCtx?.data?.previous?.step_identifier,
            flow_type: postCtx.data.flow_type == 'cv' ? 'cv' : 'add-post'
        }

        const onSuccess = (response: AxiosResponse) => {

            setSessionByKey('draftId', response.data.draft.id)
            postCtx.updateData({
                workflow_id: response.data.workflow_id,
                draft_id: response.data.draft.id,
                step: response.data.step,
                start_time: Date.now(),
                previous: response.data.previous,
                media: MediaMapper(response.data.media),
            })
            if (response.data.draft.id !== postCtx.data.draft_id) {
                MediasCacher.getInstance().clear()
            }

            const mediaResult = MediaMapper(response.data.media)

            if (response.data.step?.identifier.includes('CV')) {
                postCtx.updateData({
                    form: {
                        ...data,
                        ...response.data.draft.payload
                    },
                    media: mediaResult
                })
            } else {
                const finalPayload = getFinalPayload(response.data.draft.payload)
                postCtx.updateData({
                    form: {
                        ...data,
                        ...finalPayload
                    },
                    media: mediaResult
                })
            }
            uiCtx.setLoading(false)
        }

        requestTakeActionPrevious({
            ...args,
            config: {
                ...args?.config,
                data: {
                    ...args?.config?.data,
                    ...data
                },
                lang: "en"
            },
            callbacks: {
                ...args?.callbacks,
                success: onSuccess
            },

        })
    }

    /*---------------------------------------------------------------*/

    /************************************
     *         EVENT LISTENER
     * *********************************/

    const handleOnlineStatusChange = () => {
        if (!uiCtx.isOnline)
            uiCtx.setOnline(true)
    }
    const handleOfflineStatusChange = () => {
        if (uiCtx.isOnline)
            setTimeout(() => {
                uiCtx.setOnline(false)
            }, 3000)
    }
    window.addEventListener('online', handleOnlineStatusChange);

    window.addEventListener('offline', handleOfflineStatusChange);


    /*---------------------------------------------------------------
    *                       EFFECTS
    * --------------------------------------------------------------*/

    useEffect(() => {
        return () => {
            window.removeEventListener('online', handleOnlineStatusChange);
            window.removeEventListener('offline', handleOfflineStatusChange);
        };
    }, [uiCtx.isOnline])

    useEffect(() => {
        document.body.style.backgroundColor = storage.isMobile ? "#fff" : "#F2F2F7";
        setSessionByKey('stepIdentifier', postCtx.data?.step?.identifier)
        if (cSource || cMedium || cName) {
            const utmData = { cSource, cMedium, cName }
            setSessionByKey('utmData', JSON.stringify(utmData))
        }
        if (postCtx.data?.step?.identifier && flow === "add") {
            const returnUrl = urlParams.get('return')
            if (returnUrl) {
                setSessionByKey('returnUrl', returnUrl)
            }

            urlParams.set('step', postCtx.data?.step?.identifier)
            const params = urlParams.toString() ? `?${urlParams.toString()}` : ''
            navigate(`${postCtx.data.step.uri}`)
            window.sessionStorage.setItem('referralUrl', postCtx.data.step.uri.toLowerCase())
            if (isIFrame())
                IFrameEvents.dispatch("push-history-state", { params })
        }

        if (postCtx.data?.step && flow != "add") {
            const uri = postCtx.data.step.uri
            const withoutConfirmationKeys = ['success', 'pending-payment', 'pending', 'failed']
            const params = new URLSearchParams(window.location.search);

            const paramsObj = Array.from(params.keys()).reduce(
                (acc, val) => ({ ...acc, [val]: params.get(val) }),
                {}
            );
            let newUrl = `${postCtx.data.step.uri}`
            if (!withoutConfirmationKeys.some(str => uri.includes(str)))
                for (const key in paramsObj) {
                    newUrl = updateQueryStringParameter(newUrl, key, paramsObj[key])
                }
            navigate(newUrl)
        }
    }, [postCtx.data?.step?.identifier])


    useEffect(() => {
        prepareRequest()
    }, [])

    const pop = function (event) {
        event.preventDefault()
        checkRouting()
    }

    useEffect(() => {
        window.addEventListener('popstate', pop)
        return () => window.removeEventListener("popstate", pop)
    }, [])

    useEffect(() => {
        if (width <= 768) {
            uiCtx.setMobile(true)
        } else {
            uiCtx.setMobile(false)
        }
    }, [width]);


    useEffect(() => {
        setIsLoggedIn(true);

        //to remove Employee token if it's not within an iframe
        if (!isIFrame() && Cookies.get('EmployeeToken')) {
            Cookies.remove('EmployeeToken');
            console.log("removed")
        }

        // window.addEventListener('resize', () => {
        //     setWidth(window.innerWidth);
        // });
        const returnUrl = urlParams.get('return') ?? getUrlBasedOnCountry(getDataStorage().country);
        window['returnUrl'] = returnUrl

        return () => {
            removeSessionByKey('flow-type')


            // window.removeEventListener('resize', () => {
            //     setWidth(window.innerWidth);
            // });
        }
    }, [])

    /*---------------------------------------------------------------
    *                       FUNCTIONS
    * --------------------------------------------------------------*/


    const checkRouting = () => {
        const draftId = window.sessionStorage.getItem("draftId")

        if (document.URL.includes('/init')) {
            initApi.request({
                config: {
                    language: langCtx.language
                },
                callbacks: {
                    success: onSuccessInitApi
                },
            })
        } else {
            if (window.location.href.includes('promoteFlow') || (sessionStorage.getItem("flow-type") && sessionStorage.getItem("flow-type") === "delete") || sessionStorage.getItem("loggingFlow") === "nafath") {
                window.location.href = `${Cookies.get('addPostRef') ?? getUrlBasedOnCountry(storage.country)}`
                return <></>;
            }
            if ((sessionStorage.getItem("flow-type") && sessionStorage.getItem("flow-type") === "reviews")) {
                console.log("did i ever render");
                window.location.href = `${getUrlBasedOnCountry(storage.country)?.replace(storage.country, "add")}?flow=reviews&formType=${sessionStorage.getItem("formType")}`
            }
            var pathName = window.location.pathname.split("?")[0]
            pathName = window.location.pathname.replace(process.env.REACT_APP_FOLDER_PATH, '')
            pathName = pathName.replace('/init', '')
            if (pathName != null && pathName != '' && pathName != undefined && pathName != '/') {
                routingApi.request({
                    config: {
                        uri: pathName,
                        draftId: draftId
                    },
                    callbacks: {
                        success: onSuccessRoutingApi,
                        before: () => {
                            uiCtx.setLoading(true)
                        },
                    }
                })
            } else {
                initApi.request({
                    config: {
                        language: langCtx.language
                    },
                    callbacks: {
                        success: onSuccessInitApi
                    },
                })

            }
        }
    }
    const prepareRequest = () => {
        setSessionByKey('loggingFlow', flow);
        logScreenLoad()
        const baseUrl = getUrlBasedOnCountry(storage.country)
        const url = `${baseUrl}`
        switch (flow) {
            case "nafath": {
                if (urlParams.get('post_id') == null) {
                    parentCallBack(PAGE_NOT_FOUND)
                    return window.top.location.href = url
                }
                break
            }
            case "add": {
                checkRouting()
                break
            }
            case "jobApply": {
                if (urlParams.get('post_id') == null) {
                    parentCallBack(PAGE_NOT_FOUND)
                    return window.top.location.href = url
                }
                jobApplyHttp.request()
                break
            }
            case "draft": {
                if (urlParams.get('draft_id') == null) {
                    parentCallBack(PAGE_NOT_FOUND)
                    return window.top.location.href = url
                }
                draftHttp.request()
                break
            }
            case "activate": {
                if (urlParams.get('post_id') == null) {
                    parentCallBack(PAGE_NOT_FOUND)
                    return window.top.location.href = url
                }
                activateHttp.request()
                break
            }
            case "republish": {
                if (urlParams.get('post_id') == null) {
                    parentCallBack(PAGE_NOT_FOUND)
                    return window.top.location.href = url
                }
                republishHttp.request()
                break
            }
            case "requestFeature": {
                if (urlParams.get('feature-type') == null) {
                    parentCallBack(PAGE_NOT_FOUND)
                    return window.top.location.href = url
                }
                break
            }
            case "my-cv": {
                myCvHttp.request()
                break
            }
            case "survey": {
                const expectedSurveyType = [DEFAULT_NPS, CLASSIC_NPS, PAYMENT_METHODS_ONLINE]
                const preventPaymentOnlineMethodSurvey = urlParams.get('survey-type') == PAYMENT_METHODS_ONLINE && storage.country != UNEXLUDED_COUNTRY_IN_PAYMENT_SURVEY
                if (urlParams.get('survey-type') == null || !expectedSurveyType.includes(urlParams.get('survey-type')) || preventPaymentOnlineMethodSurvey) {
                    parentCallBack(PAGE_NOT_FOUND)
                    return window.top.location.href = url
                }
                break
            }
            case "edit": {
                if (urlParams.get('post_id') == null) {
                    parentCallBack(PAGE_NOT_FOUND)
                    return window.top.location.href = url
                }
                break
            }
            case "promoteFlow": {
                if (urlParams.get('post_id') == null) {
                    parentCallBack(PAGE_NOT_FOUND)
                    return window.top.location.href = url
                }
                break
            }
            case "reviews": {
                if (urlParams.get('formType') == null) {
                    parentCallBack(PAGE_NOT_FOUND)
                    return window.top.location.href = url
                }
                break
            }
            case "delete": {
                if (urlParams.get('post_id') == null) {
                    parentCallBack(PAGE_NOT_FOUND)
                    return window.top.location.href = url
                }
                break
            }
            default: {
                return window.top.location.href = url
            }
        }
    }

    const onClickPreviousHandler = () => {
        previousHttp.request(
            {
                config: {
                    data: {
                        draftId: postCtx.data.draft_id,
                        workflowId: postCtx.data.previous?.workflow_id,
                        currentStep: postCtx?.data?.previous?.step_identifier,
                    },
                },
            }
        )
    }

    const PreviousComponent = () => {
        return <></>
        // return (from === 'my-opensooq' || from === 'opensooq') && postCtx?.data?.previous &&
        //     <Previous withTitle={true} onClickPreviousHandler={onClickPreviousHandler}/>
        return postCtx?.data?.previous &&
            <Previous withTitle={true} onClickPreviousHandler={onClickPreviousHandler} />
    }

    FlowReferences.resetFlow()


    const Content = (props: DynamicObject) => {
        setSessionByKey('loggingFlow', flow);
        return <>
            {uiCtx.isLoading && <ComponentLoader />}
            {postCtx.data?.step && !uiCtx.isLoading && flow === "add" &&
                <MapperFlow config={postCtx.data.step} nextApi={nextApi} Previous={PreviousComponent} />}
            {flow === "edit" && <EditPost />}
            {flow === "jobApply" && <JobApply />}
            {flow === "draft" && <Draft Previous={PreviousComponent} />}
            {flow === "activate" && <Activate />}
            {flow === "republish" && <Republish />}
            {flow === "my-cv" && <Mycv />}
            {flow === "survey" && <Survey />}
            {flow === "requestFeature" && <RequestFeature />}
            {flow === "promoteFlow" && <PromoteFlow />}
            {flow === "reviews" && <Reviews />}
            {flow === "delete" && <DeleteReason />}
            {flow === "nafath" && <Nafath />}
        </>

    }
    // Sentry.init({
    //     dsn: process.env.REACT_APP_DSN,
    //     integrations: [new BrowserTracing()],
    //     tracesSampleRate: 1.0,
    // });
    useEffect(() => {
        if (isIFrame() && flow !== "requestFeature") {
            const originalPushState = window.history.pushState;
            const originalReplaceState = window.history.replaceState;
            window.history.pushState = function (...args) {
                console.log('Blocked pushState inside iframe:', args);
            };
            // Override `replaceState` similarly
            window.history.replaceState = function (...args) {
                console.log('Blocked replaceState inside iframe:', args);
                // Do nothing or add custom behavior if needed
            };
            // Prevent back navigation in iframe
            window.onpopstate = (event) => {
                console.log('Blocked back navigation inside iframe');
                event.preventDefault(); // Prevent navigation
            };
            return () => {
                // Restore the original methods and clear popstate handler
                window.history.pushState = originalPushState;
                window.history.replaceState = originalReplaceState;
                window.onpopstate = null;
            };
        }
        // Default behavior for non-iframe cases
        const pop = (event) => {
            event.preventDefault();
            window.history.pushState(null, document.title, window.location.href);
        };
        window.history.pushState(null, document.title, window.location.href);
        window.onpopstate = pop;
        return () => {
            window.onpopstate = null;
        };
    }, []);



    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                {isLoggedIn && uiCtx.isOnline && <>
                    <Navbar />
                    <ContentContainer>
                        <PreviousComponent />
                        <Content />
                    </ContentContainer></>}
                {!uiCtx.isOnline && <DisconnectInternet></DisconnectInternet>}
            </div>
        </ThemeProvider>
    );
}

export default App;

