import React, {useContext} from "react";
import {Props} from './interfaces';
import styles from './ListRadioOptions.module.css'
import DynamicObject from "../../../../models/dynamic-object";
import {FormControlLabel, Radio, RadioGroup, Typography} from "@mui/material";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import PostContext from "../../../../storage/PostContext";
import {getSizes} from "../../../../helpers/functions";

const ListRadioOptions = React.forwardRef<RefDataType<string>, Props>((props, ref) => {

    const {state, validation} = useDataPayload<number | string>({
        ref,
        config: props.config
    })
    const {config, nextApi} = props

    const postCtx = useContext(PostContext)


    {/*ACTION HANDLER*/
    }
    const addItemHandler = (id: number | string) => {
        state.set(id)
        if (!postCtx.data.step?.submit) {
            postCtx.addToForm(config.identifier, id)

            const httpConfig: DynamicObject = {
                config: {
                    data: {
                        post: {}
                    }
                }
            }
            httpConfig["config"]["data"][config.identifier] = id
            nextApi?.request(httpConfig)
        }

    }

    const items = config?.values?.map((item, i) => {
        return (
                <FormControlLabel
                    className={`${styles.item} cursor__pointer`}
                    control={<Radio/>}
                    onClick={(e: React.MouseEvent<HTMLElement>) => addItemHandler(item.id)}
                    label={
                        <Typography sx={{
                            marginTop: "5px",
                            fontWeight: config.cell.label.style.bold ? 'bold' : 'normal',
                            fontSize: getSizes(config.cell.label.style.size),
                            color: config.cell.label.color
                        }}>{item.label}</Typography>} value={item.id}/>
        )
    });


    return (
        <div>
            <div className={`${styles.body} rounded fw-bold`}>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    defaultValue={state.value}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        padding:"0px 2px"
                    }}>
                    {
                        items
                    }
                </RadioGroup>
            </div>
        </div>
    )
})
export default ListRadioOptions