export default class NotAutoSaveState {
    static fields = {}


    static get(identifier) {
        const value = this.fields.hasOwnProperty(identifier)
        if (value)
            delete this.fields[identifier];

        return value
    }

    static set(identifier) {
        this.fields[identifier] = true;
    }
}
