import React from "react";
import {IconButton, Menu, MenuItem} from "@mui/material";
import {StateDataType} from "./Video.interfaces";
import styles from '../../../UI/MediaUpload/EditorMenu.module.css'
import {useTranslation} from "react-i18next";
import {MoreHoriz} from "@mui/icons-material";
import {TfiTrash} from "react-icons/tfi";
import {MdOutlineModeEdit} from "react-icons/md";

interface EditorMenuProps {
    mediaKey: string,
    onClickMenuItemHandler: (e: React.MouseEvent, handler: () => any) => void,
    deleteImageHandler: (e: React.MouseEvent, mediaId: string) => void,
    onClickChangeMediaHandler: (hash: string) => void,
    state: StateDataType
}

interface AnchorState {
    [key: string]: HTMLElement
}


const EditorMenu = (props: EditorMenuProps) => {
    const {
        mediaKey,
        onClickMenuItemHandler,
        deleteImageHandler,
        onClickChangeMediaHandler,
        state
    } = props


    const {t} = useTranslation()
    const [anchorEl, setAnchorEl] = React.useState<null | AnchorState>(null);

    const open = Boolean(anchorEl && anchorEl[mediaKey]);

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        event.preventDefault()

        setAnchorEl({[`${mediaKey}`]: event.currentTarget});
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    return (
        <>
            <IconButton
                className={`${styles.container} menu-icon`}
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleOpen}
            >
                <MoreHoriz/>
            </IconButton>
            <Menu
                id="basic-menu"
                open={open}
                anchorEl={anchorEl && anchorEl[mediaKey]}
                MenuListProps={{'aria-labelledby': 'basic-button',}}
                onClick={handleClose}
            >
                <MenuItem
                    onClick={(e: React.MouseEvent) => onClickMenuItemHandler(e, () => deleteImageHandler(e, state.medias[mediaKey].id as string))}>
                    <div className={`${styles.icon_label}`}>
                        <div className={`${styles.icon_dimensions}`}>
                            <TfiTrash className={`${styles.icon_color}`} style={{height: "100%", width: "100%"}}/>
                        </div>
                        <div>{t('deleteVideo')}</div>
                    </div>
                </MenuItem>
            </Menu>
        </>
    )
}

export default EditorMenu