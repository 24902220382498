import { useContext } from "react";
import UIContext from "../../../storage/UIContext";
import { ComponentLoader } from "../../UI";
import { useNavigate } from "react-router-dom";
import PostContext from "../../../storage/PostContext";
import LanguageContext from "../../../storage/LanguageContext";
import DynamicObject from "../../../models/dynamic-object";
import { useHttp } from "../../../hooks";
import { AxiosError, AxiosResponse } from "axios";
import {
  getFinalPayload,
  parentCallBack,
  setSessionByKey,
} from "../../../helpers/functions";
import NextApi from "../../../api/next.api";
import MapperFlow from "../../MapperFlow";
import { Args } from "../../../hooks/use-http/interfaces";
import deletePost from "../../../api/delete-post.api";
import { PAGE_NOT_FOUND } from "../../../constants/Status";

const DeleteReason = (): JSX.Element => {
  const postContext = useContext(PostContext);
  const languageContext = useContext(LanguageContext);
  const uiContext = useContext(UIContext);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);

  const configInitDeletePostApi = {
    callback: deletePost,
    initialData: {},
    withLoader: true,
  };

  /************************************
             INIT REQUEST API
   ***********************************/
  const initApi = useHttp<DynamicObject>(configInitDeletePostApi);
  const onSuccessInitApi = (response: AxiosResponse) => {
    const finalPayload = getFinalPayload(response.data.draft.payload);
    postContext.updateData({
      form: {
        ...postContext.data.form,
        ...finalPayload,
      },
      step: {
        ...postContext.data.step,
        ...response.data.step,
      },
      draft_id: response.data.draft.id,
      workflow_id: response.data.workflow_id,
      post_id: response.data.draft.payload.post_id,
      flow_type: "delete",
    });
    setSessionByKey("draftId", response.data.draft.id);
    setSessionByKey("flow-type", "delete");
    setSessionByKey("post_id", response.data.draft.payload.post_id);
    navigate(`${response.data.step.uri}`);
  };

    initApi.request({
      config: {
        language: languageContext.language,
        post_id: urlParams.get("post_id"),
      },
      callbacks: {
        success: onSuccessInitApi,
      },
    });

  /************************************
             Next REQUEST API
   ***********************************/
  const configNextApi = {
    callback: NextApi,
    initialData: {},
    withLoader: true,
  };

  const nextApi = useHttp<DynamicObject>(configNextApi);
  const request2 = nextApi.request;

  function initializeData() {
    const data: DynamicObject = {
      draftId: postContext.data.draft_id,
      time_spent_ms: Date.now() - postContext.data.start_time,
      workflowId: postContext.data.workflow_id,
      currentStep: postContext?.data?.step?.identifier,
    };

    for (const key in postContext.data.currentStepData) {
      data[key as keyof typeof data] =
        postContext.data.form[key as keyof typeof postContext.data.form];
    }
    return data;
  }

  /*Prepare Request next api*/
  nextApi.request = function (args?: Args) {
    const data = initializeData();

    request2({
      ...args,
      config: {
        flow_type: postContext.data.flow_type,
        ...args?.config,
        data: {
          ...args?.config?.data,
          ...data,
        },
        language: languageContext.language,
      },
      callbacks: {
        ...args?.callbacks,
        before: () => {
          uiContext.setLoading(true);
        },
      },
    });
  };

  return (
    <div>
      {uiContext.isLoading && <ComponentLoader />}
      {postContext.data?.step && !uiContext.isLoading && (
        <MapperFlow config={postContext.data.step} nextApi={nextApi} />
      )}
    </div>
  );
};

export default DeleteReason;

