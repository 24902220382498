import React, {useContext, useEffect} from "react";
import styles from './TextLabel.module.css'
import {Props, RefDataType} from './interfaces';
import UIContext from "../../../../storage/UIContext";
import {getImgPath, getSizes} from "../../../../helpers/functions";
import LanguageContext from "../../../../storage/LanguageContext";
import {getDataStorage} from "../../../../helpers/storage.helper";

const TextLabel = React.forwardRef<RefDataType, Props>((props, ref) => {

    const langCtx = useContext(LanguageContext)
    const storage=getDataStorage()

    const rightAlignment = props.config.text?.align.toLowerCase() == 'right' && langCtx.language=='en' && props.config.text?.icon && 'end'

    const isMobileOrDesktopStyle = {
        border:props.config?.border && `1px ${props.config?.border?.type.toLowerCase()=='none' && props.config?.border?.color ? 'solid': props.config?.border?.type} ${props.config?.border?.color}`,
        borderRadius: props.config?.border?.round ? props.config?.border?.type.toLowerCase() == 'solid' ? "20px" : "5px":"0px",
    }

    let textStyle = {
        fontWeight: props.config.text?.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.text?.style?.size),
        color: props.config.text.color,
        textAlign: props.config.text?.align,
        float: props.config.text?.align,
        alignItems:"center",
        display:'flex'
    } as React.CSSProperties

    if(props.config?.text?.maxLines) {
        textStyle = {
            ...textStyle,
            textOverflow: "ellipsis",
            display: "-webkit-box",
            overflow: "hidden",
            lineHeight:  !isNaN(props.config?.text?.maxLines) ? `${0.7 * +props.config?.text?.maxLines}em` : "",
            WebkitLineClamp: props.config?.text?.maxLines,
            WebkitBoxOrient: "vertical",
        }
    }
    const containerStyle = {
        "--text-font-weight": props.config.text?.style?.bold ? "bold" : "normal",
        "--text-font-size": getSizes(props.config.text?.style?.size),
        "--text-font-color": props.config.text.color,
        width:props.config?.fullWidth?'100%':'75%',
        border:isMobileOrDesktopStyle.border,
        borderRadius: isMobileOrDesktopStyle.borderRadius,
        backgroundColor: props.config?.background && props.config?.background?.color,
        padding:props.config?.text?.parse_as?.toLowerCase()=='html'&& '10px'
    } as React.CSSProperties

    const Text = () => {
        if (props.config?.text?.parse_as?.toLowerCase() == 'html')
            return <div style={textStyle} dangerouslySetInnerHTML={{__html: props.config?.text?.locale}}/>;
        else
            return <div style={textStyle}>{props.config?.text?.locale}</div>
    }


    return (
        <div className={`${props.config.fullWidth ?containerStyle.width:`d-flex justify-content-${textStyle?.textAlign.toLowerCase() == 'right' && langCtx.language=='en' ? rightAlignment : textStyle?.textAlign}`}`}>
            <div
                style={containerStyle}
                className={`${styles.container}`}>
                <div className={`d-flex flex-column justify-content-center`}>
                    <div
                        className={`col d-flex justify-content-${props.config.fullWidth && textStyle?.textAlign.toLowerCase() == 'right' && langCtx.language=='en' ? rightAlignment : textStyle?.textAlign} ${styles.icon_list_container}`}>
                        {props.config.text.icon &&
                            <div style={{float: langCtx.language === 'en' ? 'left' : 'right',marginTop:props.config?.text?.parse_as?.toLowerCase()=='html'&& '2px'}}><img style={{width: "22px"}}
                                                                                                                                                                          src={getImgPath(props.config.text.icon)}/></div>}
                        {Text()}
                    </div>
                </div>
            </div>
        </div>
    )
})
export default TextLabel