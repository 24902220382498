import React, {useContext, useEffect, useState} from "react";
import DynamicObject from "../../../../models/dynamic-object";
import {UseHttpResponse} from "../../../../hooks/use-http/interfaces";
import Video from "../../Tools/Video/Video";
import {getSizes} from "../../../../helpers/functions";
import LanguageContext from "../../../../storage/LanguageContext";
import NextButton from "../../NextButton/NextButton";
import {useTranslation} from "react-i18next";
import {useDataPayload} from "../../../../hooks";
import {getDataStorage} from "../../../../helpers/storage.helper";
import styles from "../MediaStep/MediaStep.module.css";
import {FaRegCheckCircle} from "react-icons/fa";

interface VideoStepProps {
    config: DynamicObject;
    nextApi?: UseHttpResponse<DynamicObject>,
}


const VideoStep = (props: VideoStepProps): JSX.Element => {
    const {config, nextApi} = props

    const langCtx = useContext(LanguageContext)

    const storage = getDataStorage()

    const {t} = useTranslation();
    const containerWidth = document.getElementById('content-container')
    const [screenWidth, setScreenWidth] = useState(containerWidth?.clientWidth);
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(containerWidth?.clientWidth);
        };

        //scroll to of the page in normal step
        const body = document.querySelector('#root');
        body.scrollIntoView({
            behavior: 'smooth'
        })

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array to run the effect only once


    const titleStyle = {
        fontWeight: props.config.title.style?.bold ? "bold" : "normal",
        fontSize: getSizes(props.config.title.style?.size),
        color: props.config.title.color,

    } as React.CSSProperties

    return (<>
            <div className={'p-2'}>
                <div className={`${styles.videoHeaderContainer}`}>
                    <div className={styles.header}>
                        <div className={styles.headerTitle}>
                            {/*<h1>{config.title?.locale }</h1>*/}
                            <h1>{t('addReelTitle')}</h1>
                        </div>
                        <div className={`${styles.flexContainer}`}>
                            <div><FaRegCheckCircle
                                className={`${styles.verticalAlign}   ${styles.icon} ${styles.customIcon}`}/>
                            </div>
                            <div className={`ms-1`}>
                                <span
                                    className={styles.text}>{langCtx.language == 'ar' ?`${t('youCanAddReel')} ${config?.config?.reelsMaximumDuration} ${t('-seconds')}`:`A ${config?.config?.reelsMaximumDuration}${t('-seconds')} ${t('youCanAddReel')}`}</span>
                            </div>
                        </div>
                        <div className={`${styles.flexContainer}`}>
                            <div><FaRegCheckCircle
                                className={`${styles.verticalAlign} ${styles.icon} ${styles.customIcon}`}/>
                            </div>
                            <div className={`ms-1`}><span className={styles.text}>{t('increaseView')}</span></div>
                        </div>
                        <div className={`${styles.flexContainer}`}>
                            <div><FaRegCheckCircle
                                className={`${styles.verticalAlign} ${styles.icon} ${styles.customIcon}`}/>
                            </div>
                            <div className={`ms-1`}><span className={styles.text}>{t('betterQuality')}</span></div>
                        </div>
                    </div>
                    <Video config={config.config} identifier={config.fieldIdentifier} maxLength={1}/>
                </div>
                {/*{props.config.submit && <button className={`${styles.error_btn}`}>*/}
                {/*    <div className={`row`}>*/}
                {/*        <div className={`col-12`}>*/}
                {/*            <div style={{*/}
                {/*                display: "inline",*/}
                {/*                marginTop: "8px"*/}
                {/*            }}> Continue*/}
                {/*            </div>*/}
                {/*            <span className={`${langCtx.language == 'en' ? `${styles.float_right}` : `${styles.float_left}`}`}><div*/}
                {/*                className={`${styles.iconContainer}`}>{langCtx.language == 'en' ? <IoArrowForward/> :*/}
                {/*                <IoArrowBack/>} </div></span>*/}
                {/*        </div>*/}

                {/*    </div>*/}
                {/* */}
                {/*</button>*/}
                {/*    */}
                {/*}*/}
                <NextButton
                    style={{
                        bold: true,
                        size: "m",
                        color: "#fff",
                        backgroundColor: "#0C5CF9",
                        width: storage.isMobile ? `${screenWidth - 20}px` : "400px"
                    }}
                    isSticky={storage.isMobile}
                    nextApi={nextApi}
                >{t('next')}</NextButton>
            </div>

        </>
    )
}

export default VideoStep