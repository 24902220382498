import React, { useEffect, useState, useContext } from "react";
import { Props } from "./interface";
import { Checkbox as CheckboxMui } from "@mui/material";
import { FormControlLabel, FormGroup } from "@mui/material";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import useDataPayload, { RefDataType } from "../../../../hooks/use-data-payload/use-data-payload";
import PostContext from "../../../../storage/PostContext";

const Checkbox = React.forwardRef<RefDataType<string>, Props>((props, ref) => {
    const { state, validation } = useDataPayload<string>({
        ref,
        config: props.config,
        setConfig: props.setConfig,
        defaultValue: ""
    });

    const { config } = props;
    const postCtx = useContext(PostContext);

    // Use local state to track checkbox status
    const [checked, setChecked] = useState(() => {
        if (sessionStorage.getItem('loggingFlow') === 'edit') {
            return Boolean(parseInt(postCtx?.data?.form?.hidePrice)) || Boolean(parseInt(postCtx?.data?.form?.hideSalaryJobPoster));
        } else if (sessionStorage.getItem('loggingFlow') === 'add') {
            return Boolean(config.default);
        }
        return false; // Default value if none of the conditions match
    });
    // Handle checkbox change
    const addItemHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        setChecked(isChecked); // Update local checked state
        state.set(isChecked ? '1' : '0'); // Update state for useDataPayload
        validation.clear(); // Clear validation
    };

    return (
        <div>
            <FormGroup>
                <FormControlLabel
                    control={
                        <CheckboxMui
                            onChange={addItemHandler}
                            checked={checked} // Use local checked state
                        />
                    }
                    label={config.text.locale}
                    value={state.value === '1'}
                    style={{ justifyContent: config?.text?.align, width: "fit-content" }}
                />
            </FormGroup>
            <ValidationError validation={validation} />
        </div>
    );
});

export default Checkbox;
