import { create } from "zustand";

type State = {
  isPromoteFlow: boolean;
  enableBackButton: boolean;
  trigger: boolean;
  promoteFlowCloseText?: string;
  promoteFlowTitle?: string;
};

type Actions = {
  setIsPromoteFlow: (isPromoteFlow: boolean) => void;
  setEnableBackButton: (enableBackButton: boolean) => void;
  setTrigger: (trigger: boolean) => void;
  setPromoteFlowCloseText?: (promoteFlowCloseText: string) => void;
  setPromoteFlowTitle?: (promoteFlowTitle: string) => void;
};

const useVasStore = create<State & Actions>((set, get, state) => ({
  isPromoteFlow: false,
  enableBackButton: false,
  trigger: false,
  promoteFlowCloseText: "",
  setIsPromoteFlow: (isPromoteFlow) => set({ isPromoteFlow }),
  setEnableBackButton: (enableBackButton) => set({ enableBackButton }),
  setTrigger: (trigger) => set({ trigger }),
  setPromoteFlowTitle: (promoteFlowTitle) => set({ promoteFlowTitle }),
  setPromoteFlowCloseText: (promoteFlowCloseText) =>
    set({ promoteFlowCloseText }),
}));

export default useVasStore;
