import React, {useContext, useEffect, useImperativeHandle, useMemo, useState} from "react";
import styles from './MapDesktop.module.css'
import {useTranslation} from "react-i18next";
import {GoogleMap} from "../../../../../utils/google-maps";
import PostContext from "../../../../../storage/PostContext";
import {Coordinates, RefDataType, Props} from "./../interface";
import DynamicObject from "../../../../../models/dynamic-object";

interface CurrentProps extends Props {
    state: DynamicObject,
    validation: DynamicObject,
}

const MapDesktop = React.forwardRef<RefDataType, CurrentProps>((props, ref) => {

    const {config, state, validation} = props


    /************************************
     *         TRANSLATION
     * *********************************/
    const {t} = useTranslation();


    /************************************
     *            CONTEXT
     * *********************************/
    const postCtx = useContext(PostContext)

    /************************************
     *            CONSTANTS
     * *********************************/
    const latLong: string = state.value

    const keys: string[] = ['lat', 'lng'];

    const obj: DynamicObject = {};

    latLong?.toString().split(',').forEach((value, index) => {
        obj[keys[index]] = Number(value);
    });


    const defaultCoordinates = state.value ? state.value : props.config.center


    let [configLat, configLng] = defaultCoordinates?.toString().split(",", 2)


    /*-------------------------------------
     *            FUNCTIONS
     * ----------------------------------*/

    /************************************
     *        ACTION HANDLERS
     * *********************************/

    const defaultState = {
        lat: parseFloat(configLat),
        lng: parseFloat(configLng)
    }

    const [coordinates, setCoordinates] = useState<Coordinates>(defaultState)


    const changeLatitudeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCoordinates((old: Coordinates) => {
            return {
                ...old,
                lat: parseFloat(e.target.value)
            }
        })
    }

    const changeLongitudeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCoordinates((old: Coordinates) => {
            return {
                ...old,
                lng: parseFloat(e.target.value)
            }
        })
    }

    useEffect(() => {
        if (coordinates.lat === defaultState.lat && coordinates.lng === defaultState.lng)
            return
        
        state.set(`${coordinates.lat},${coordinates.lng}`)

        return () => {
        }
    }, [coordinates])



    useEffect(() => {
        const [lat, lng] = props.config.center.split(",").map((coordinate) => parseFloat(coordinate))
        // if (coordinates.lng === lng && coordinates.lat === lat)
        //     return

        setCoordinates({
            lat,
            lng
        })

        const googleMap = new GoogleMap("google-map")
        googleMap.loadMap({
            zoom: config.zoomLevel,
            center: {lat, lng}
        }).then(function () {
        }).catch(console.error)

    }, [props.config])

    useImperativeHandle<RefDataType, RefDataType>(ref, () => {
        return {
            getState: (): any => `${coordinates.lat},${coordinates.lng}`
        }
    })

    // const addItemHandler = () => {
    //     state.set(`${coordinates.lat},${coordinates.lng}`)
    //
    // }
    // const clearMap = () => {
    //     state.set(defaultCoordinates)
    //
    // }


    /*---------------------------------------------------------------*/

    /************************************
     *           MAP LOADER
     * *********************************/
    useEffect(function () {
        const googleMap = new GoogleMap("google-map")
        googleMap.loadMap({
            zoom: config.zoomLevel
        }).then(function () {
        })
    }, [])

    console.log(coordinates, 'load')
    return (
        <div className={`${styles.container} mt-3`}>
            <div className={styles.map_container}>
                <div className={'google-map'} style={{width: "100%", height: "65vh", overflow: "unset"}}/>

                <input type="hidden" className={'lat'} onInput={changeLatitudeHandler} value={coordinates.lat}/>
                <input type="hidden" className={'lng'} onInput={changeLongitudeHandler} value={coordinates.lng}/>
            </div>
        </div>
    )
})

export default MapDesktop