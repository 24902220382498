import {Props} from "./interface";
import PostContext, {flowRefs} from "../../../../storage/PostContext";
import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import useDataPayload, {RefDataType} from "../../../../hooks/use-data-payload/use-data-payload";
import SelectFieldMobile from "./SelectFieldMobile/SelectFieldMobile";
import SelectFieldDesktop from "./SelectFieldDesktop/SelectFieldDesktop";
import ValidationError from "../../../UI/ValidationError/ValidationError";
import {getDataStorage} from "../../../../helpers/storage.helper";

const SelectField = React.forwardRef<RefDataType<string>, Props>((props, ref) => {


    /************************************
     *           CONTEXT
     * *********************************/
    const storage = getDataStorage()


    const {validation, state} = useDataPayload<string>({
        ref,
        config: props.config,
        setConfig: props.setConfig,
        defaultValue: null
    })


    return (

        <>
            {storage.isMobile ? <SelectFieldMobile state={state} config={props.config}
                                                   setConfigData={props.setConfigData}
                                                   validation={validation}
                                                   setConfig={props.setConfig}
                                                   levels={props.levels}
                                                   selectFieldConfig={props.selectFieldConfig}
                                                   flowRefs={props.flowRefs}/> :
                <SelectFieldDesktop config={props.config}
                                    state={state}
                                    setConfigData={props.setConfigData}
                                    setConfig={props.setConfig}
                                    validation={validation}
                                    levels={props.levels}
                                    selectFieldConfig={props.selectFieldConfig}
                                    flowRefs={props.flowRefs}/>

            }
            <ValidationError validation={validation}/>
        </>


    )
})

export default SelectField